import { gql } from '@apollo/client';

export const REMOVE_CONTACT_LINK = gql`
	mutation($linkid: Int!) {
		removeContactLink(linkid: $linkid) {
			linkid
			site
			url
		}
	}
`;
