import { gql } from '@apollo/client';
import { Contact } from './contactFragment';
export const UPDATE_CONTACT = gql`
	${Contact}
	mutation($updateData: UpdateContactArgs!) {
		updateContact(updateData: $updateData) {
			...ContactParts
		}
	}
`;
