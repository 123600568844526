import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { useSpring, useSprings, animated } from 'react-spring';
import theme from 'styled-theming';
import Modal from '../common/modalContainer';
import FilterForm from './filterForm';
import {
	baseSettings,
	accentColor,
	primaryColor,
	primaryLighterColor,
	lightforegroundColor,
	modalLight,
	lightGray,
	foregroundColor,
	secondaryAccentColor
} from '../../styles/theme';
import { mediaQueries } from '../../styles/media';

const FilterColumnContainer = styled.div`
	align-items: flex-start;
	border-radius: 25px;
	/* border: 2px solid orange; */
	border: ${theme('mode', {
		dark: 'none',
		light: '2px solid #00334e'
	})};
	background: ${modalLight};
	box-shadow: 1px 1px 8px 5px ${primaryColor};
	display: flex;
	padding: ${baseSettings.small} 0;
	flex-direction: column;
	height: 55%;
	width: 35%;
	${mediaQueries('md')`
		overflow-y: auto;	
		overflow-x: hidden;
		width: 80%;
		max-height: 850px;
		padding-bottom: ${baseSettings.small};
	
	`};
`;

const CloseButton = styled(IoIosCloseCircleOutline)`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontXL};
	margin-right: ${baseSettings.medium};
	transition: color 0.33 ease-in;
	--webkit-transition: color 0.33 ease-in;
	&:hover{
		color: ${accentColor};
	}

`;

const FilterBodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: ${baseSettings.xs};
	margin-top: 0;
	width: 100%;
`;

const FilterRow = styled.div`
	align-items: center;
	display: flex;
	border-bottom: 1px solid ${lightGray};
	justify-content: space-between;
	width: 100%;
`;

const FilterHeader = styled.div`
	align-self: stretch;
	color: ${foregroundColor};
	margin: ${baseSettings.xs} ${baseSettings.xs};
	font-size: ${baseSettings.fontMedium};
	font-weight: 400;
	${mediaQueries('md')`
		margin-left: 22px
	`};
`;

const InstructionText = styled.h5`
	color: ${lightforegroundColor};
	font-size: ${baseSettings.fontSmall};

	font-weight: 400;
	${mediaQueries('md')`
		margin-left: 22px
		font-size: ${baseSettings.fontxs};
	`};
`;

const AnimatedFilterColumnContainer = animated(FilterColumnContainer);

function FilterModal({ isOpen, toggleModal }) {
	const props = useSpring({
		to: { transform: isOpen ? 'scale(1.2)' : 'scale(0)', opacity: isOpen ? 1 : 0 },
		config: { duration: 300, mass: 1, tension: 180, friction: 12 },
		reset: true,
		from: { opacity: 0, transform: 'scale(0)' }
	});

	return (
		<Modal isOpen={isOpen} toggleModal={toggleModal}>
			<AnimatedFilterColumnContainer style={props}>
				<FilterRow>
					<FilterHeader>Filters</FilterHeader>
					<CloseButton onClick={() => toggleModal()} />
				</FilterRow>
				<FilterBodyContainer>
					<InstructionText>Select one or more filters below</InstructionText>
					<FilterForm toggleModal={toggleModal} />
				</FilterBodyContainer>
			</AnimatedFilterColumnContainer>
		</Modal>
	);
}

FilterModal.propTypes = {
	isOpen: bool.isRequired,
	toggleModal: func.isRequired
};

export default FilterModal;
