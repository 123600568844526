import React from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select/creatable';
import propTypes from 'prop-types';
import { useTheme } from '../../styles/ThemeProvider';
import { baseSettings, foregroundColor, primaryColor } from '../../styles/theme';

const VerticalInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const HorizontalInputContainer = styled(VerticalInputContainer)`
    flex-direction: row;
`;
const InputHeader = styled.p`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
`;

const Select = styled(ReactSelect)`


width: ${(props) => props.width};

`;

function CreatableSelect({
	width = '250px',
	text = '',
	defaultValue = '',
	orintation = 'verticle',
	options = [ { label: 'loading', value: '' } ],
	noOptionsMessage = 'no options',
	loading = false,
	onChange,
	onInputChange,
	register = null,
	name = '',
	value = { value: '', label: '' }
}) {
	const Container = orintation === 'verticle' ? VerticalInputContainer : HorizontalInputContainer;
	const themeData = useTheme();
	const selectStyles = {
		control: (provided, state) => ({
			...provided,
			border: `1px solid ${state.selectProps.theme === 'dark' ? 'white' : '#00334e'}}`,
			backgroundColor: 'none'
		}),
		input: (provided, state) => ({
			...provided,
			color: themeData.theme === 'dark' ? 'white' : '#00334e',
			outline: 'none'
		}),
		singleValue: (provided, state) => ({
			...provided,
			fontSize: baseSettings.fontMedium,
			color: themeData.theme === 'dark' ? 'white' : '#00334e'
		}),
		menu: (provided, state) => ({
			...provided,
			color: themeData.theme === 'dark' ? 'white' : '#00334e',
			background: themeData.theme === 'dark' ? '#1E2025' : '#ee6f57'
		}),
		option: (provided, state) => ({
			...provided,
			color: 'white',
			backgroundColor: state.isSelected ? (themeData.theme === 'dark' ? '#03c4a1' : '#ee6f57') : 'transparent',
			fontSize: baseSettings.fontSmall,
			'&:hover': {
				background: themeData.theme === 'dark' ? '#FF5260' : '#00334e',
				color: 'white'
			}
		})
	};

	const NoOptionsMessage = () => <div style={{ color: 'white' }}>{noOptionsMessage}</div>;

	return (
		<Container>
			<InputHeader>{text}</InputHeader>
			<ReactSelect
				styles={selectStyles}
				width={width}
				theme={themeData.theme}
				clearable={false}
				onChange={onChange}
				onInputChange={onInputChange}
				options={options}
				noOptionsMessage={NoOptionsMessage}
				ref={register}
				name={name}
				value={value}
			/>
		</Container>
	);
}
CreatableSelect.propTypes = {
	options: propTypes.array,
	loading: propTypes.bool,
	onChange: propTypes.func.isRequired,
	noOptionsMessage: propTypes.string,
	register: propTypes.func,
	name: propTypes.string,
	onInputChange: propTypes.func.isRequired
};

export default CreatableSelect;
