import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setNCIProps } from '../../redux/actions/nci';
import { foregroundColor, baseSettings } from '../../styles/theme';
import CompanyLocationSelect from '../common/companyLocationSelect';
import { ReadOnlyField } from '../common/InputField';

const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const HeaderText = styled.h2`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 600;
`;

const Description = styled.p`
	color: ${foregroundColor};
	font-weight: 300;
	font-size: ${baseSettings.fontMedium};
	text-align: left;
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 2vh;
`;

const CompanyName = styled(Description)`
    font-weight: 500;
`;

function AddressPage({ company }) {
	const [ address, setAddress ] = useState(null);
	const dispatch = useDispatch();
	const { locid } = useSelector((state) => state.nci);

	useEffect(
		() => {
			if (address) {
				dispatch(setNCIProps({ locid: address.locid }));
			}
		},
		[ address ]
	);

	useEffect(
		() => {
			if (locid) {
				const address = company.locations.find((l) => l.locid === locid);
				setAddress(address);
			}
		},
		[ locid ]
	);

	return (
		<ColumnContainer>
			<HeaderText>Confirm Address</HeaderText>
			<Description>Please select the primary company location.</Description>
			<CompanyName>{company.companyname}</CompanyName>
			<CompanyLocationSelect compid={company.compid} location={address} setValue={setAddress} />
			{address && (
				<FormContainer>
					<ReadOnlyField value={(address.city && address.city.cityName) || ''} text="City" />
					<ReadOnlyField value={address.zip} text="Zip" />
					<ReadOnlyField value={address.state} text="State" />
				</FormContainer>
			)}
		</ColumnContainer>
	);
}

export default AddressPage;
