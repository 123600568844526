import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setNCIProps } from '../../redux/actions/nci';
import styled from 'styled-components';
import { mediaQueries } from '../../styles/media';
import { baseSettings, foregroundColor } from '../../styles/theme';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const Header = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontLarge};
	font-weight: 600;
	margin: 0;
	margin-bottom: ${baseSettings.medium};
`;

const NoteArea = styled.textarea`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	background: none;
	color: ${foregroundColor};
	font-size: ${baseSettings.small};
	font-weight: 300;
	height: 35vh;
	width: 35vw;
	${mediaQueries('md')`
		height: 55vh;
		width: 100%;
	`};
`;

function NotePage() {
	const { note } = useSelector((state) => state.nci);
	const dispatch = useDispatch();

	return (
		<Container>
			<Header>Any notes for customer service?</Header>
			<NoteArea value={note} onChange={(e) => dispatch(setNCIProps({ note: e.target.value }))} />
		</Container>
	);
}

export default NotePage;
