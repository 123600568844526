import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { MONTH_GOALS } from '../../api/metrics/dashboardMertics';

const baseDataStructure = [ { label: 'acutal', data: [ { x: 0, y: 0 } ] } ];

export function useMonthGoals() {
	const { data, loading, error } = useQuery(MONTH_GOALS);

	const formatSeries = (series) => {
		let formatedSeries = [];

		const extractSeriesLabel = (label) => ({
			label: label,
			data: series.filter((s) => s.series === label).map((value) => ({
				x: value.day,
				y: value.sales
			}))
		});

		formatedSeries.push(extractSeriesLabel('actual'));
		formatedSeries.push(extractSeriesLabel('goal'));
		formatedSeries.push(extractSeriesLabel('desired'));

		return formatedSeries;
	};

	const seriesData = useMemo(
		() => {
			if (!data || !data.monthGoals) {
				return baseDataStructure;
			}
			// format data
			return formatSeries(data.monthGoals);
		},
		[ data ]
	);

	return { error, loading, seriesData };
}
