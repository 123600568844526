import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { useTable } from "react-table";
import { useSelector } from "react-redux";
import { Virtuoso } from "react-virtuoso";
import {
	StyledTable,
	StyledThead,
	StyledTBody,
	StyledTr,
	StyledTh,
	StyledNavTd,
	StyledTd,
} from "../../styles/styledTables";
import { mediaQueries } from "../../styles/media";
import { useIsMobile } from "../../hooks/design/useIsMobile";
import { foregroundColor } from "../../styles/theme";
import NewContactModal from "./NewContactModal";
import MobileContact from "../contacts/MobileContact";

const ContactsContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 90%;
	min-width: 90vw;
	min-height: 150px;
	overflow-x: scroll;
	overflow-y: none;
`;

const Table = styled(StyledTable)`
	width: 75vw;
	${mediaQueries("md")`
		width: 100vw;

    
    `};
`;

const AddButton = styled(AiOutlinePlusSquare)`
	color: ${foregroundColor};
	font-size: 3.5rem;
	transition: scale 0.33s ease-out;
	--webkit-transition: scale 0.33s ease-out;
	&:hover {
		scale: 1.1;
		--webkit-animation: scale(1.1);
	}
`;

// Mobile Controls //

///////////////////

function CompanyContacts() {
	const isMobile = useIsMobile();
	const [addContactOpen, setAddContactOpen] = useState(false);
	const contacts = useSelector((state) => state.company.contacts);

	const data = useMemo(() => {
		if (!contacts || contacts.length < 1) {
			return [];
		} else {
			return [...contacts];
		}
	}, [contacts]);

	const columns = useMemo(
		() => [
			{
				Header: "contid",
				accessor: "contid",
			},
			{
				Header: "First",
				accessor: "firstName",
			},
			{
				Header: "Last",
				accessor: "lastName",
			},
			{
				Header: "Title",
				accessor: "title",
			},
			{
				Header: "Email",
				accessor: "email",
			},
			{
				Header: "Phone #",
				accessor: "phone",
			},
			{
				Header: "Location",
				accessor: "location.summary",
			},
		],
		[]
	);
	const tableInstance = useTable({ data, columns, initialState: { hiddenColumns: ["contid"] } });
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

	return (
		<ContactsContainer>
			<AddButton onClick={() => setAddContactOpen(true)} />
			<NewContactModal isOpen={addContactOpen} toggleModal={() => setAddContactOpen(!addContactOpen)} />
			{isMobile ? (
				<Virtuoso
					style={{ height: "450px" }}
					data={data}
					itemContent={(_, contact) => (
						<MobileContact
							contid={contact.contid}
							firstName={contact.firstName}
							lastName={contact.lastName}
							title={contact.title}
						/>
					)}
				/>
			) : (
				<Table {...getTableProps}>
					<StyledThead>
						{
							// Loop over the header rows
							headerGroups.map((headerGroup) => (
								// Apply the header row props
								<tr {...headerGroup.getHeaderGroupProps()}>
									{
										// Loop over the headers in each row
										headerGroup.headers.map((column) => (
											// Apply the header cell props
											<StyledTh {...column.getHeaderProps()}>
												{
													// Render the header
													column.render("Header")
												}
											</StyledTh>
										))
									}
								</tr>
							))
						}
					</StyledThead>
					{/* Apply the table body props */}
					<StyledTBody {...getTableBodyProps()}>
						{
							// Loop over the table rows
							rows.map((row) => {
								// Prepare the row for display
								prepareRow(row);
								return (
									// Apply the row props
									<StyledTr {...row.getRowProps()}>
										{
											// Loop over the rows cells
											row.cells.map((cell) => {
												// Apply the cell props
												if (cell.column.Header === "First") {
													return (
														<td {...cell.getCellProps()}>
															<StyledNavTd to={`/contacts/${row.allCells[0].value}`}>{cell.render("Cell")}</StyledNavTd>
														</td>
													);
												} else {
													return (
														<StyledTd {...cell.getCellProps()}>
															{
																// Render the cell contents
																cell.render("Cell")
															}
														</StyledTd>
													);
												}
											})
										}
									</StyledTr>
								);
							})
						}
					</StyledTBody>
				</Table>
			)}
		</ContactsContainer>
	);
}

export default CompanyContacts;
