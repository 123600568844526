export const setActivityProps = (props = {}) => ({
	type: "new-activity/set",
	payload: props,
});

export const addActivityContact = ({ contid, name, company = "" }) => ({
	type: "new-activity/add-contact",
	payload: { contid, name, company },
});

export const removeActivityContact = ({ contid }) => ({
	type: "new-activity/remove-contact",
	payload: contid,
});

export const callContact = ({ contid, name, company = "" }) => ({
	type: "new-activity/call-contact",
	payload: {
		contid,
		name,
		company,
	},
});

export const clearActivity = () => ({
	type: "new-activity/clear",
});
