import { gql } from '@apollo/client';

export const REMOVE_INDUSTY_ASSIGNMENT = gql`
	mutation($compid: Int!, $industryid: Int!) {
		removeIndustyAssignment(compid: $compid, industryid: $industryid) {
			industryid
			industryName
			naics
		}
	}
`;
