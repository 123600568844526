import { createContext, useState, useEffect } from "react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";

import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../graph-config";

const attemptSilentLogin = async (msalApplication, scopes) => {
	try {
		const account = await msalApplication.getActiveAccount();
		const request = {
			scopes: [...scopes],
			account,
			forceRefresh: false,
		};

		const result = await msalApplication.acquireTokenRedirect(request);

		const options = {
			account: result.account, // the AccountInfo instance to acquire the token for.
			interactionType: InteractionType.Popup,
			scopes: [...scopes],
		};

		const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalApplication, options);

		let authClient = Client.initWithMiddleware({
			authProvider: authProvider,
		});

		return new GraphService(authClient);
	} catch (e) {
		return null;
	}
};

class GraphService {
	_client = null;

	constructor(client) {
		this._client = client;
	}

	static async initilizeGraphService() {
		const msalConfig = {
			auth: {
				clientId: process.env.REACT_APP_GRAPH_CLIENT_ID,
				redirectUri: process.env.REACT_APP_GRAPH_REDIRECT_URL,
				authority: `https://login.microsoft.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
			},
		};
		const graphScopes = [
			"Calendars.Read",
			"Calendars.ReadWrite",
			"Email",
			"Mail.Read",
			"Mail.ReadWrite",
			"Mail.Send",
			"User.Read",
		];

		const msalApplication = new PublicClientApplication(msalConfig);

		const silentLoginResponse = await attemptSilentLogin(msalApplication, graphScopes);
		if (silentLoginResponse) {
			return silentLoginResponse;
		}

		// refresh failed, proceed with normal login steps

		const loginRequest = {
			scopes: [...graphScopes],
		};

		let response = await msalApplication.loginPopup(loginRequest);
		const options = {
			account: response.account, // the AccountInfo instance to acquire the token for.
			interactionType: InteractionType.Popup,
			scopes: [...graphScopes],
		};

		const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalApplication, options);

		let authClient = Client.initWithMiddleware({
			authProvider: authProvider,
		});

		return new GraphService(authClient);
	}

	getProfile() {
		return this._client.api("/me").get();
	}

	api(path) {
		return this._client.api(path);
	}
}

const ThemeContext = createContext({
	graphService: new GraphService(),
	isReady: false,
});

// export const useGraph = () => useContext(ThemeContext);

export const useGraph = () => {
	const { instance, accounts } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const [profile, setProfile] = useState(null);

	useEffect(() => {
		if (!isAuthenticated) {
			instance.loginPopup(loginRequest).catch((e) => console.log("Error during Graph login: " + e.message));
			return;
		}
		instance.acquireTokenSilent({ account: accounts[0] }).catch((e) => {
			console.log("could not acquire token " + e.message);
			instance.loginPopup(loginRequest).catch((e) => console.log("Error during Graph login: " + e.message));
		});
	}, [isAuthenticated]);

	useEffect(() => {
		if (accounts) {
			setProfile(accounts[0]);
		}
	}, [accounts]);

	const callMSGraphGet = async (request) => {
		const accessToken = await instance.acquireTokenSilent({ account: accounts[0] });
		const headers = new Headers();
		const bearer = `Bearer ${accessToken.accessToken}`;

		headers.append("Authorization", bearer);

		const options = {
			method: "GET",
			headers: headers,
		};
		try {
			const response = await fetch(request, options);
			return response.json();
		} catch (e) {
			console.log("Error processing request " + request);
		}
	};

	const callMSGraphPost = async (request, bodyObject) => {
		const accessToken = await instance.acquireTokenSilent({ account: accounts[0] });
		const headers = new Headers();
		const bearer = `Bearer ${accessToken.accessToken}`;

		headers.append("Content-Type", "application/json");
		headers.append("Authorization", bearer);

		const options = {
			method: "POST",
			headers: headers,
			body: JSON.stringify(bodyObject),
		};
		try {
			const response = await fetch(request, options);
			return response.json();
		} catch (e) {
			console.log("Error processing request " + request);
		}
	};

	return { instance, isAuthenticated, profile, callMSGraphGet, callMSGraphPost };
};

export function GraphProvider({ children }) {
	const [graphService, setGraphService] = useState(null);
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		const loginGraph = async () => {
			try {
				const service = await GraphService.initilizeGraphService();
				setGraphService(service);
			} catch (e) {
				console.log("error during graph login -" + e.message);
			}
		};

		loginGraph();
	}, []);

	useEffect(() => {
		if (graphService) {
			setIsReady(true);
		}
	}, [graphService]);

	return <ThemeContext.Provider value={{ graphService, isReady }}>{children}</ThemeContext.Provider>;
}

export default GraphProvider;
