import { gql } from "@apollo/client";

export const GET_COMPANY = gql`
	query ($compid: Int!) {
		company(compid: $compid) {
			compid
			aescustid
			companyname
			website
			industry {
				industryid
				naics
				industryName
			}
			contacts {
				contid
				firstName
				lastName
				title
				email
				cellphone
				phone
				fax
				department
				birthday
				location {
					summary
				}
			}
			locations {
				locid
				address1
				phone
				fax
				city {
					cityid
					cityName
				}
				state
				zip
				county {
					countid
					countyName
				}
				notes {
					cnoteid
					createdDate
					note
					type {
						typeid
						type
					}
				}
			}
		}
	}
`;
