import React, { useEffect } from "react";
import { useDispatch, useSelector  } from "react-redux"
import styled from 'styled-components';
import propTypes from 'prop-types';
import { useRowSelect, useTable, useRowState, usePagination, useSortBy } from 'react-table';
import {
	StyledTable,
	StyledThead,
	StyledTBody,
	StyledTr,
	StyledTh,
	StyledNavTd,
	StyledTd,
	StyledLink
} from '../../styles/styledTables';
import { mediaQueries } from '../../styles/media';
import { secondaryAccentColor, baseSettings, foregroundColor, accentColor } from '../../styles/theme';
import { setPageAction } from "../../redux/actions/page";
const Table = styled(StyledTable)`
	width: ${(props) => (props.width ? props.width : '75vw')};
	${mediaQueries('md')`
		width: 100%;
    `};

`;

const TableColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

const PagnationButton = styled.button`
	border: none;
	background: ${secondaryAccentColor};
	cursor: pointer;
	color: white;
	font-size: ${baseSettings.fontMedium};
	margin: ${baseSettings.xs} 4px 0 0;
	transition: hover 0.33s ease-out;
	--webkit-transition: hover 0.33s ease-out;

	&:hover {
		background: ${accentColor};
	}
`;

const PagnationRow = styled.div`
	align-items: center;
	display: flex;
`;

const PageText = styled.span`
	margin-left: ${baseSettings.small};
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
`;

const GenericTable = ({
	data = [],
	columns,
	hiddenColumns = [],
	width = null,
	onSelect = null,

	pagnationSize = 15,

	navColumn = {
		route: ''
	},
	linkColumn = {
		route: ''
	}
}) => {
	const tableInstance = useTable(
		{ data, columns, initialState: { hiddenColumns: hiddenColumns, pageSize: pagnationSize } },
		useSortBy,
		usePagination,
		useRowSelect,
		useRowState
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		setRowState,
		gotoPage,
		nextPage,
		previousPage,
		canPreviousPage,
		pageCount,
		pageOptions,
		canNextPage,
		state: { pageIndex }
	} = tableInstance;


	const { page: pageState } = useSelector(state => state.table)
  const dispatch = useDispatch()

	useEffect(() => {
		gotoPage(pageState)
	}, [])


	useEffect(() => {
		if(pageIndex !== 0) {
			dispatch(setPageAction(pageIndex))
		}
	}, [pageIndex])


	const onRowClick = (rowIndex) => {
		if (onSelect) {
			page.forEach((row) => {
				if (row.id !== rowIndex) {
					// will dis-select all others
					setRowState(row.id, { selected: false });
				}
			});
			setRowState(rowIndex, { selected: true });
			onSelect(page[rowIndex].values);
		}
	};

	return (
		<TableColumn>
			<Table {...getTableProps} width={width}>
				<StyledThead>
					{// Loop over the header rows
					headerGroups.map((headerGroup) => (
						// Apply the header row props
						<tr {...headerGroup.getHeaderGroupProps()}>
							{// Loop over the headers in each row
							headerGroup.headers.map((column) => (
								// Apply the header cell props
								<StyledTh {...column.getHeaderProps(column.getSortByToggleProps)}>
									{// Render the header
									column.render('Header')}
									<span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
								</StyledTh>
							))}
						</tr>
					))}
				</StyledThead>
				{/* Apply the table body props */}
				<StyledTBody {...getTableBodyProps()}>
					{// Loop over the table rows
					page.map((row) => {
						// Prepare the row for display

						prepareRow(row);
						return (
							// Apply the row props
							<StyledTr {...row.getRowProps()} state={row.state} onClick={() => onRowClick(row.id)}>
								{// Loop over the rows cells
								row.cells.map((cell) => {
									// Apply the cell props
									// assuming the first is omitted
									if(navColumn.route && cell.column.Header === columns[1].Header) {
										return (
										<td {...cell.getCellProps()}>
											<StyledNavTd to={`${navColumn.route}/${row.allCells[0].value}`}>
												{cell.render('Cell')}
											</StyledNavTd>
										</td>
										)
									}
									// regular link that will not use the react router
									if(linkColumn.route && cell.column.Header === columns[0].Header) {
										return (<td {...cell.getCellProps()}>
											<StyledLink href={`${linkColumn.route}/${row.allCells[0].value}`} target="_blank">
												{cell.render('Cell')}
											</StyledLink>
										</td>)
									}

								 	return (
										<StyledTd {...cell.getCellProps()}>
											{// Render the cell contents
											cell.render('Cell')}
										</StyledTd>
									);
								})}
							</StyledTr>
						);
					})}
				</StyledTBody>
			</Table>
			{data.length > pagnationSize && (
				<PagnationRow>
					<PagnationButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						{'<<'}
					</PagnationButton>
					<PagnationButton onClick={() => previousPage()} disabled={!canPreviousPage}>
						{'<'}
					</PagnationButton>
					<PagnationButton onClick={() => nextPage()} disabled={!canNextPage}>
						{'>'}
					</PagnationButton>
					<PagnationButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						{'>>'}
					</PagnationButton>
					<PageText>
						Page {pageIndex + 1} of {pageOptions.length}
					</PageText>
				</PagnationRow>
			)}
		</TableColumn>
	);
};

GenericTable.propTypes = {
	onSelect: propTypes.func,
	pagnationSize: propTypes.number,
	hiddenColumns: propTypes.arrayOf(propTypes.string),
	navColumn: propTypes.object,
	linkColumn: propTypes.object
};

export default GenericTable;
