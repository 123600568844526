import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useReactToPrint  } from "react-to-print"
import { BiPrinter } from "react-icons/bi"
import { toggleGeolocation } from "../../redux/actions/radar";
import {
	baseSettings,
	foregroundColor,
	primaryLighterColor,
	primaryColor,
	lightforegroundColor, accentColor
} from "../../styles/theme";
import { mediaQueries } from "../../styles/media";
import Select from "../../components/common/SelectField";
import SizedBox from "../common/SizedBox";
import { useIsMobile } from "../../hooks/design/useIsMobile";

const Container = styled.div`
	background: ${primaryColor};
	cursor: default;
	border-radius: 0 0 5px 0;
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	width: ${(props) => (props.collapsed ? "auto" : "450px")};

	${mediaQueries("md")`
        width: 100%;
    `};
`;

const DisplayDiv = styled.div`
	display: ${(props) => (props.collapsed ? "none" : "flex")};
	flex-direction: column;
	height: "100%";
  z-index: 5;
	transition: all 0.25s ease;
`;

const Header = styled.h4`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
`;

const PrintIcon = styled(BiPrinter)`
	color: ${accentColor};
	cursor: pointer;
	height: 40px;
	width: 40px;
	margin-right: 15px;
	
	&:hover {
		transform: scale(1.2);
    transition: all 0.25s ease;
	}

`

const ToolTip = styled.h4`
	color: ${lightforegroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 300;
	margin: 4px 0;
`;

const Row = styled.div`
	align-items: center;
	display: flex;
`;

const Hr = styled.hr`
	display: flex;
	height: 1px;
	background: ${foregroundColor};
	padding: 0;
	margin: 4px 0;
	opacity: 0.4;
	width: 100%;
`;

const RadiusContainer = styled.div`
	display: flex;
	width: 50%;
	z-index: 5;
`;

const ToggleRow = styled.div`
	margin-top: ${baseSettings.xs};
	display: flex;
	justify-content: right;
`;

const SpaceBetweenRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const ToggleButton = styled.div`
	border: 1px solid ${foregroundColor};
	background: ${primaryLighterColor};
	border-radius: 5px;
	cursor: pointer;
	height: 35px;
	padding: ${baseSettings.xs};
	position: relative;
	transform-origin: 1px;
	width: 33px;

	div {
		background: white;
		display: inline-block;
		position: absolute;
		height: 0.25em;
		transition: all 0.2s ease;
		-webkit-transition: all 0.2s ease;
		width: 20px;

		&:first-of-type {
			left: 0;
			transform: ${(props) => (!props.collapsed ? "scale(.9) rotate(-45deg)" : "scale(.9) rotate(45deg)")};
		}

		&:last-of-type {
			right: 0;
			transform: ${(props) => (!props.collapsed ? "scale(.9) rotate(45deg)" : "scale(.9) rotate(-45deg)")};
		}
	}
`;

const CheckBox = styled.input`
	margin-right: ${baseSettings.xs};
`;

const radiusOptions = [
	{
		label: "5 Miles",
		value: 5,
	},
	{
		label: "10 Miles",
		value: 10,
	},
	{
		label: "20 Miles",
		value: 20,
	},
	{
		label: "50 Miles",
		value: 50,
	},
	{
		label: "100 Miles",
		value: 100,
	},
	{
		label: "500 Miles",
		value: 500,
	},
	{
		label: "1000 Miles",
		value: 1000,
	},
];

function MapControl({ controlState, setControlState, children, mapReference }) {
	console.log(mapReference.current)
	const handlePrint = useReactToPrint({
		content: () => mapReference.current
	})
	const [collapsed, setCollapsed] = useState(false);
	const dispatch = useDispatch();
	const isMobile = useIsMobile();

	const onToggleGeoLocation = (e) => {
		setControlState({ ...controlState, type: e.target.checked ? "user" : "address" });
		dispatch(toggleGeolocation());
	};
	return (
		<Container>
			<DisplayDiv collapsed={collapsed}>
				<SpaceBetweenRow>
					<Header>Location Controls</Header>
					{isMobile && (
						<ToggleButton
							collapsed={collapsed}
							onClick={() => setCollapsed(!collapsed)}
							style={{ rotate: "-90deg", marginTop: "10px" }}
						>
							<div />
							<div />
						</ToggleButton>
					)}
				</SpaceBetweenRow>
				<RadiusContainer>
					<Select
						options={radiusOptions}
						text="Search Radius"
						value={{ label: `${controlState.radius} Miles`, value: controlState.radius }}
						onChange={({ value }) => setControlState({ ...controlState, radius: value })}
					/>
				</RadiusContainer>

				<Hr />
				<Row>
					<CheckBox type="checkbox" checked={controlState.geolocate} onClick={onToggleGeoLocation} />
					<ToolTip>My Location</ToolTip>
				</Row>
				<Row>
					<Hr />
					<SizedBox right={baseSettings.xs} />
					<ToolTip>Or</ToolTip>
					<SizedBox right={baseSettings.xs} />
					<Hr />
				</Row>
				{children}
			</DisplayDiv>
			<ToggleRow>
				{(!isMobile || collapsed) && (
					<ToggleButton collapsed={collapsed} onClick={() => setCollapsed(!collapsed)}>
						<div />
						<div />
					</ToggleButton>
				)}
			</ToggleRow>
		</Container>
	);
}

export default MapControl;
