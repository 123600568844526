const defaultState = {
	compid: 0,
	locid: 0,
	contid: 0,
	naics: null,
	typeid: null,
	note: "",
	potential: 5000,
};

export function nciReducer(state = defaultState, action) {
	switch (action.type) {
		case "nci/set": {
			return {
				...state,
				...action.payload,
			};
		}
		case "nci/initial": {
			return {
				...defaultState,
				compid: action.payload,
			};
		}
		case "nci/clear": {
			return defaultState;
		}

		default: {
			return state;
		}
	}
}
