import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useContactActivities } from "../../../hooks/activities/useContactActivities";
import SelectedActivity from "./SelectedActivity";
import ActivitiesList from "./ActivitiesList";
import React from "react";
import { foregroundColor } from "../../../styles/theme";
import { addActivityContact, clearActivity } from "../../../redux/actions/newActivity";

const ActivityContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100%;
`;

const ActivityToolBar = styled.div`
	display: flex;
`;

const ActivityDetail = styled.div`
	display: flex;
`;

const AddButton = styled(AiOutlinePlusSquare)`
	color: ${foregroundColor};
	font-size: 3.5rem;
	transition: scale 0.33s ease-out;
	--webkit-transition: scale 0.33s ease-out;
	&:hover {
		scale: 1.1;
		--webkit-animation: scale(1.1);
	}
`;

function ActivityTab() {
	const [selectedActivity, setSelectedActivity] = React.useState(null);
	const { contid = 0, company = null, firstName = "", lastName = "" } = useSelector((state) => state.contact);
	const dispatch = useDispatch();
	const history = useHistory();
	const { activities, getActivities, error, loading } = useContactActivities({ contid });

	const onSelectActivity = ({ actid }) => {
		if (!isNaN(actid) && activities?.some((a) => a.actid === actid)) {
			const activity = activities.find((a) => a.actid === actid);
			setSelectedActivity(activity ?? null);
		}
	};

	const onNewActivity = () => {
		const payload = { contid, name: `${firstName} ${lastName}`, company: company?.companyname };
		dispatch(clearActivity());
		dispatch(addActivityContact(payload));
		history.push(`/schedule-activity/page/1`);
	};

	return (
		<ActivityContainer>
			<ActivityToolBar>
				<AddButton onClick={onNewActivity} />
			</ActivityToolBar>
			<ActivityDetail>
				<ActivitiesList activities={activities} onSelect={onSelectActivity} />
				{selectedActivity && <SelectedActivity activity={selectedActivity} />}
			</ActivityDetail>
		</ActivityContainer>
	);
}

export default ActivityTab;
