export const setNCIProps = (props = {}) => ({
	type: 'nci/set',
	payload: props
});

export const setInitialCompany = ({ compid }) => ({
	type: 'nci/initial',
	payload: compid
});

export const clearNCI = () => ({
	type: 'nci/clear'
});
