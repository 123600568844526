import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_COUNTIES } from '../../api/counties/getCounties';

export function useCounties() {
	const { loading, error, data, refetch } = useQuery(GET_COUNTIES, { pollInterval: 1000 });
	const [ counties, setCounties ] = useState([]);

	useEffect(
		() => {
			if (data && data.counties) {
				setCounties(data.counties);
			}
		},
		[ data ]
	);

	return { loading, error, counties, refetch };
}
