import { gql } from '@apollo/client';

export const GET_RECENT_REPAIRS = gql`
	query($custId: Int!) {
		aesCompany(custId: $custId) {
			recentRepairs {
				serviceId
				mfgPart
				checkInDate
				item
				serialNumber
				quote {
					quote
				}
			}
		}
	}
`;
