import React, { useMemo } from "react";
import styled from "styled-components";
import { useContactSearch } from "../../hooks/contacts/useContactSearch";
import Select from "../common/SelectField";

function ContactSelect({ onSelectContact, compid = null, selectedContactId = null, lazyload = true }) {
	const { loading, error, contacts, searchContacts } = useContactSearch({ compid, lazyload });

	const options = useMemo(
		() => contacts.map((contact) => ({ value: contact.contid, label: `${contact.firstName} ${contact.lastName}` })),
		[contacts]
	);

	const onInputChange = (value) => {
		if (value) {
			searchContacts({ firstName: value });
		}
	};

	const onChange = ({ value }) => {
		const contact = contacts.find((c) => c.contid === value);
		if (contact) {
			onSelectContact({
				contid: contact.contid,
				name: `${contact.firstName} ${contact.lastName}`,
				company: contact.company.companyname,
			});
		}
	};

	const selectedContact = options?.find((c) => c.value === selectedContactId) ?? {
		value: null,
		label: "",
	};

	return (
		<Select
			options={options}
			onInputChange={onInputChange}
			loading={loading}
			onChange={onChange}
			noOptionsMessage={"No contacts"}
			value={selectedContact}
		/>
	);
}

export default ContactSelect;
