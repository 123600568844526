import { gql } from '@apollo/client';

export const CUSTOMER_METRICS = gql`
	query {
		customerMetrics {
			id_
			customerName
			lastShipped
			wipQuote
			wappQuote
			year1Quote
			year2Quote
			year3Quote
			year4Quote
			year5Quote
		}
	}
`;
