import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { useMutation } from '@apollo/client';
import { CubeGrid } from 'better-react-spinkit';
import { FaRegUser } from 'react-icons/fa';
import { RiLockPasswordLine } from 'react-icons/ri';
import logo from '../../assets/images/aeslogo.png';
import { LOGIN } from '../../api/user/login';
import { setUserToken } from '../../api/user/tokenProvider';
import { mediaQueries } from '../../styles/media';
import { useTheme } from '../../styles/ThemeProvider';
import IconInput from '../common/IconInput';
import SizedBox from '../common/SizedBox';
import ThemeButton from '../common/ThemeButton';
import {
	primaryBackgroundGradiantTheme,
	primaryLighterTheme,
	baseSettings,
	foregroundColor,
	accentColor,
	primaryColorDarker
} from '../../styles/theme';

const FlexContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100vh;
	width: 100vw;
`;

const LoginBoxContainer = styled.div`
	align-items: center;
	border-radius: 10px;
	box-shadow: 0.5px 0.5px 10px 1px #121211;
	display: flex;
	flex-direction: column;
	${primaryLighterTheme};
	height: 50%;
	width: 30%;
	${mediaQueries('xl')`
		width: 80%;
	`};

	${mediaQueries('lg')`
		width: 80%;
	`};

	${mediaQueries('md')`
		height: 90%;
		width: 90%;
	`};
`;

const HeaderContainer = styled.div`
	border-radius: 10px;
	background: ${theme('mode', {
		light: primaryColorDarker,
		dark: accentColor
	})};
	display: flex;
	min-height: 12;
	width: 100%;
`;

const LogoContainerText = styled.h3`
	color: white;
	margin-left: ${baseSettings.small};
	font-weight: 600;
`;

const BodyContainer = styled.form`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 0 50px;
	width: 100%;
	${mediaQueries('md')`
		padding: 0 5px;
	`};
`;

const LogoContainer = styled.div`
	display: flex;
	margin: ${baseSettings.small} ${baseSettings.xs};
`;

const AESLogo = styled.img`height: 36px;`;

const SubHeader = styled.p`
	letter-spacing: .1rem;
	margin: ${baseSettings.medium} ${baseSettings.large};
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
	${mediaQueries('md')`
	text-align: 'center';
	font-size: ${baseSettings.fontxs};
	width: 90%;
	`};
`;

const UserIcon = styled(FaRegUser)`
	font-size: ${baseSettings.fontXL}
	`;

const LockIcon = styled(RiLockPasswordLine)`
	font-size: ${baseSettings.fontXL}
	`;

const ErrorMessage = styled.p`
	color: red;
	font-size: ${baseSettings.fontSmall};
`;

const LoginPageContainer = styled.div`
	display: flex;
	${primaryBackgroundGradiantTheme};
	height: 100vh;
	width: 100vw;
`;

function LoginPage() {
	const [ credentials, setCreds ] = useState({ username: '', password: '' });
	const [ login, { loading, error, data } ] = useMutation(LOGIN);

	const themeData = useTheme();

	useEffect(
		() => {
			if (data && data.login.token) {
				setUserToken(data.login.token);
			}
		},
		[ data ]
	);

	const onChangeUsername = (e) => {
		setCreds({
			...credentials,
			username: e.target.value
		});
	};

	const onChangePassword = (e) => {
		setCreds({
			...credentials,
			password: e.target.value
		});
	};

	const startLogin = (e) => {
		e.preventDefault();
		const { username, password } = credentials;
		login({ variables: { username, password } });
	};

	return (
		<LoginPageContainer>
			<FlexContainer>
				<LoginBoxContainer>
					<HeaderContainer>
						<LogoContainer>
							<AESLogo src={logo} />
							<LogoContainerText>Web Systems</LogoContainerText>
						</LogoContainer>
					</HeaderContainer>
					<SubHeader>
						Welcome to the AES Sales App! Please sign in below to have access to all of your sales
						information. You can also use this site on your phone either in your browser or as an App.
					</SubHeader>
					<BodyContainer onSubmit={startLogin}>
						<IconInput
							required
							reactIcon={UserIcon}
							placeholder={'Enter Username'}
							onChange={onChangeUsername}
							value={credentials.username}
						/>
						<SizedBox top={baseSettings.small} />
						<IconInput
							required
							reactIcon={LockIcon}
							fontSize={baseSettings.fontLarge}
							placeholder={'Password'}
							type={'password'}
							value={credentials.password}
							onChange={onChangePassword}
						/>
						<SizedBox top={baseSettings.xl} />
						{error && <ErrorMessage>{error.message}</ErrorMessage>}
						{loading ? (
							<CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />
						) : (
							<ThemeButton
								type={'submit'}
								variant={'secondary'}
								fontSize={baseSettings.fontLarge}
								fontWeight={600}
							>
								Login
							</ThemeButton>
						)}
					</BodyContainer>
				</LoginBoxContainer>
			</FlexContainer>
		</LoginPageContainer>
	);
}

export default LoginPage;
