import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MdOpenInBrowser } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import styled from 'styled-components';
import { mediaQueries } from '../../styles/media';
import { baseSettings, foregroundColor, accentColor, secondaryAccentColor } from '../../styles/theme';
import GenericTable from '../common/genericTable';
import NewLinkModal from './NewLinkModal';
import { useContactLink } from '../../hooks/contacts/useContactLink';

const ContactBody = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${baseSettings.xs};
	width: 100vw;
`;

const ToolRow = styled.div`
	display: flex;
	margin: ${baseSettings.small} 0;
	justify-content: space-between;
	${mediaQueries('md')`
        justify-content: flex-start;
    `};
`;

const ToolSubSection = styled.div`display: flex;`;

const BrowseButton = styled(MdOpenInBrowser)`
    color: ${foregroundColor};
    font-size: 3.5rem;
    transition: scale 0.33s ease-out;
    --webkit-transition: scale 0.33s ease-out;
    &:hover {
        scale: 1.1;
        --webkit-animation:scale(1.1);
    }
`;

const TrashButton = styled(IoMdTrash)`
    color: ${secondaryAccentColor};
    font-size: 3.5rem;
    margin-left: ${baseSettings.medium};
    transition: scale 0.33s ease-out;
    --webkit-transition: scale 0.33s ease-out;
    &:hover {
        scale: 1.1;
        --webkit-animation:scale(1.1);
    }
`;

const AddButton = styled(AiOutlinePlusSquare)`
    color: ${foregroundColor};
    font-size: 3.5rem;
    transition: scale 0.33s ease-out;
    --webkit-transition: scale 0.33s ease-out;
    &:hover {
        scale: 1.1;
        --webkit-animation:scale(1.1);
    }
`;

const BrowserLink = styled(Link)`text-decoration: none;`;

function ContactSocialLinks() {
	const { contid = 0, links = [] } = useSelector((state) => state.contact);
	const { error, loading, addNewLink, removeLink } = useContactLink({ contid });

	const columns = useMemo(
		() => [
			{
				Header: 'Link ID',
				accessor: 'linkid'
			},
			{
				Header: 'Site',
				accessor: 'site'
			},
			{
				Header: 'Url',
				accessor: 'url'
			}
		],
		[]
	);

	const [ selectedLink, setSelectedLink ] = useState(null);
	const [ newLinkOpen, setLinkOpen ] = useState(false);

	const add = (values) => {
		addNewLink(values);
	};

	const remove = () => {
		if (selectedLink) {
			removeLink({ linkid: selectedLink.linkid });
		}
	};

	return (
		<ContactBody>
			<ToolRow>
				<AddButton onClick={() => setLinkOpen(true)} />

				{selectedLink && (
					<ToolSubSection>
						<BrowserLink to={{ pathname: selectedLink.url }} target="_blank">
							<BrowseButton />
						</BrowserLink>
						<TrashButton onClick={remove} />
					</ToolSubSection>
				)}
			</ToolRow>
			<NewLinkModal isOpen={newLinkOpen} toggleModal={() => setLinkOpen(!newLinkOpen)} onSubmit={add} />
			<GenericTable
				columns={columns}
				data={links}
				hiddenColumns={[ 'linkid' ]}
				onSelect={(value) => setSelectedLink(value)}
			/>
		</ContactBody>
	);
}

export default ContactSocialLinks;
