import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PageContainer from '../components/common/PageContainer';

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const user = useSelector((state) => state.user);

	return (
		<Route
			{...rest}
			component={(props) =>
				user.userid ? (
					<div>
						<PageContainer>
							<Component {...props} />
						</PageContainer>
					</div>
				) : (
					<Redirect to="/" />
				)}
		/>
	);
};
