import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Chart } from 'react-charts';
import { accentColor, baseSettings, secondaryAccentColor, foregroundColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import { useTheme } from '../../styles/ThemeProvider';
import { CubeGrid } from 'better-react-spinkit';
import { useMonthlySales } from '../../hooks/metrics/useMonthlySales';

const ChartContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	height: 300px;
	width: 65%;
	${mediaQueries('md')`
		width: 100%;

	`};
`;

const ChartTitle = styled.h3`
	margin: 0;
	padding: ${baseSettings.xs};
	color: ${foregroundColor};
`;

const LegendContainer = styled.div`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	position: relative;
	top: 45%;
	left: 85px;
	margin: 0;
	width: 150px;
	z-index: 24;
`;

const LegendRow = styled.div`
	align-items: center;
	display: flex;
	padding: 0 ${baseSettings.small};
`;

const LegendSquare = styled.div`
	width: 20px;
	height: 15px;
	margin: 0 ${baseSettings.xs};
`;

const LegendText = styled.p`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
`;

const LoadingContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
`;

const LoadingCube = styled(CubeGrid)`
    background-color: 'purple'
`;

const SalesChart = styled(Chart)`
    top: 0px;
	flex: 0.75;
    width: auto;

`;

const MetricRow = styled.div`
	display: flex;
	flex: 0.25;
`;

const ChartColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

function SalesMonthlyGraph() {
	// per guidelines useMemo is needed

	const { loading, error, seriesData } = useMonthlySales();
	const themeData = useTheme();

	const data = useMemo(
		() =>
			seriesData || [
				{
					label: 'loading',
					data: [ { x: 1, y: 180000 } ]
				}
			],
		[ seriesData ]
	);

	const axes = useMemo(
		() => [ { primary: true, type: 'linear', position: 'bottom' }, { type: 'linear', position: 'left' } ],
		[]
	);

	const getSeriesStyle = (series) => {
		return {
			color: series.index % 2 === 0 ? accentColor({}) : secondaryAccentColor({})
		};
	};

	return (
		<ChartContainer>
			{!loading && <ChartTitle>Monthly Sales History</ChartTitle>}
			{loading || error ? (
				<LoadingContainer>
					<LoadingCube size={65} color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} />
				</LoadingContainer>
			) : (
				<SalesChart
					data={data}
					axes={axes}
					tooltip
					getSeriesStyle={getSeriesStyle}
					dark={themeData.theme === 'dark'}
				/>
			)}
		</ChartContainer>
	);
}

export default SalesMonthlyGraph;
