import React from 'react';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import styled from 'styled-components';
import { aesBlueColor, baseSettings, primaryLighterTheme, lightGray, accentColor } from '../../styles/theme';

const Input = styled.input`
	${primaryLighterTheme};
	border: none;
	width: 100%;
`;

const InputContainer = styled.div`
	${primaryLighterTheme};
	transition: border 0.10s ease-in;
	border: 1px solid ${lightGray};
	border-radius: 5px;
	padding: ${baseSettings.xs};
	display: flex;
	width: 100%;
	&:focus-within {
		border: 2px solid
			${theme('mode', {
				dark: aesBlueColor,
				light: accentColor
			})};
	}
`;

function IconInput({
	reactIcon,
	children,
	theme = primaryLighterTheme,
	fontSize = baseSettings.fontMedium,
	inputKey,
	...props
}) {
	const ReactIcon = styled(reactIcon)`

        margin-right: ${baseSettings.small};
		`;

	return (
		<InputContainer>
			<ReactIcon />
			<Input key={inputKey} {...props} style={{ fontSize: fontSize }}>
				{children}
			</Input>
		</InputContainer>
	);
}

IconInput.propTypes = {
	reactIcon: PropTypes.object.isRequired,
	fontSize: PropTypes.string
};

export default IconInput;
