import styled from 'styled-components';
import theme from 'styled-theming';
import { NavLink } from 'react-router-dom';
import {
	primaryColor,
	accentColor,
	baseSettings,
	primaryLighterColor,
	secondaryAccentColor,
	foregroundColor
} from './theme';
import { mediaQueries } from '../styles/media';

export const StyledTable = styled.table`
	background: ${primaryColor};
	border-collapse: collapse;
	border-radius: 5px;
	overflow: scroll;
	width: 100%;
	max-height: 85vh;
`;

export const StyledThead = styled.thead``;

export const StyledTh = styled.th`
	border-collapse: collapse;
	color: white;
	background: ${accentColor};
	font-size: ${baseSettings.fontLarge};
	letter-spacing: 1.2px;
	text-transform: uppercase;
	text-align: left;
	padding: ${baseSettings.xs} 0;
	padding-left: ${baseSettings.xs};
	${mediaQueries('md')`
        font-size: 1rem;
    
    `};
`;

export const StyledTd = styled.td`
	font-size: ${baseSettings.fontLarge};
	font-weight: 400;
	padding: ${baseSettings.xs} 0 ${baseSettings.xs} ${baseSettings.xs};
	${mediaQueries('md')`
        font-size: 1rem;
    
    `};
`;

export const StyledNavTd = styled(NavLink)`
	align-items: center;
	color: ${foregroundColor};
	display: flex;
	font-size: ${baseSettings.fontLarge};
	font-weight: 400;
	text-decoration: underline;
	padding: ${baseSettings.xs} 0 ${baseSettings.xs} ${baseSettings.xs};
	&:hover {
		color: ${theme('mode', {
			dark: foregroundColor,
			light: '#fff'
		})}
	}
	
`;

export const StyledLink = styled.a`
	align-items: center;
	color: ${foregroundColor};
	display: flex;
	font-size: ${baseSettings.fontLarge};
	font-weight: 400;
	text-decoration: underline;
	padding: ${baseSettings.xs} 0 ${baseSettings.xs} ${baseSettings.xs};
	&:hover {
		color: ${theme('mode', {
			dark: foregroundColor,
			light: '#fff'
		})}
	}
`

export const StyledTr = styled.tr`
	margin: ${baseSettings.large} 0;
	transition: all 0.10s ease-out;
	color: ${(props) =>
		props.state && props.state.selected
			? theme('mode', {
					dark: '#03c4a1',
					light: '#ee6f57'
				})
			: 'none'};
	border: ${theme('mode', {
		dark: 'None;',
		light: `1px solid #eee`
	})};

	&:nth-child(even) {
		background: ${primaryLighterColor};
	}

	&:hover {
		background: ${secondaryAccentColor};
		color: white;
	}
`;

export const StyledTBody = styled.tbody`height: 75%;`;
