import { gql } from '@apollo/client';

export const REMOVE_COMPANY_NOTE = gql`
	mutation($cnoteid: Int!) {
		removeCompanyNote(cnoteid: $cnoteid) {
			cnoteid
			createdDate
			type {
				typeid
				type
			}
			note
		}
	}
`;
