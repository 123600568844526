import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import InputField from '../common/InputField';
import SizedBox from '../common/SizedBox';
import IndustrySelect from './industrySelect';
import NAICSSelect from './nacisSelect';
import StateSelect from '../common/stateSelect';
import CitySelect from '../common/citySelect';
import CountySelect from '../common/countySelect';
import ThemeButton from '../common/ThemeButton';
import { baseSettings } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import { setFilter, clearFilter } from '../../redux/actions/companyFilter';
import { useIsMobile } from '../../hooks/design/useIsMobile';

const ContainerForm = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: 0 ${baseSettings.small} 0 0;
	width: 95%;
`;

const ColumnDiv = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin: 0 ${baseSettings.small};
	${mediaQueries('md')`
		font-size: ${baseSettings.fontxs};
	`};
`;

const FormRow = styled.div`
	display: flex;

	justify-content: space-between;
	${mediaQueries('md')`
		flex-direction: column;
	`};
`;

function FilterForm({ toggleModal }) {
	const filterState = useSelector((state) => state.companyFilters);
	const dispatch = useDispatch();
	const isMobile = useIsMobile();
	const { register, handleSubmit, errors, control } = useForm({
		defaultValues: {
			...filterState
		}
	});

	const applyFilter = (filters) => {
		dispatch(
			setFilter({
				...filters,
				city: filters.city ? filters.city.value : null,
				county: filters.county ? filters.county.value : null
			})
		);
		toggleModal();
	};

	const removeFilter = () => {
		dispatch(clearFilter());
		toggleModal();
	};

	return (
		<ContainerForm onSubmit={handleSubmit(applyFilter)}>
			<FormRow>
				<ColumnDiv>
					<InputField text={'Company Name:'} register={register} name={'companyname'} />
					<InputField
						text="AES Cust ID:"
						type="number"
						register={register({ pattern: /^\d+$/ })}
						name={'aescustid'}
					/>
					<IndustrySelect control={control} />
					<NAICSSelect control={control} />
				</ColumnDiv>
				<ColumnDiv>
					<StateSelect control={control} />
					<CitySelect control={control} />
					<InputField text={'Zip:'} register={register} name={'zip'} />
					<CountySelect control={control} />
				</ColumnDiv>
			</FormRow>
			<SizedBox top={'10px'} />
			<FormRow>
				<ThemeButton variant={'secondary'} onClick={removeFilter}>
					Clear Filter
				</ThemeButton>

				<ThemeButton type={'submit'}>Apply Filter</ThemeButton>
			</FormRow>
		</ContainerForm>
	);
}

FilterForm.propTypes = {
	toggleModal: propTypes.func.isRequired
};

export default FilterForm;
