import { gql } from '@apollo/client';

export const GET_ACTIVITY = gql`
	query($actid: Int!) {
		activity(actid: $actid) {
			actid
			type {
				typeid
				type
			}
			date
			duration
			note
			participants {
				contact {
					contid
					firstName
					lastName
					title
				}
			}
		}
	}
`;
