import React from 'react';
import { bool, func } from 'prop-types';
import styled from 'styled-components';
import { accentColor, primaryColor, baseSettings, foregroundColor } from '../../styles/theme';

const TopBar = styled.div`
	background: pink;
	position: absolute;
	top: 0;
	width: 100vw;
`;

const StyledBurger = styled.button`
	position: absolute;
	top: 3%;
	left: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: ${baseSettings.medium};
	height: ${baseSettings.medium};
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;

	&:focus {
		outline: none;
	}

	div {
		width: ${baseSettings.medium};
		height: 0.25rem;
		background: ${({ open }) => (open ? accentColor : foregroundColor)};
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;

		:first-child {
			transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? '0' : '1')};
			transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(x)')};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
		}
	}
`;

function BurgerMenu({ open, setOpen }) {
	return (
		<StyledBurger open={open} onClick={() => setOpen(!open)}>
			<div />
			<div />
			<div />
		</StyledBurger>
	);
}

BurgerMenu.propTypes = {
	open: bool.isRequired,
	setOpen: func.isRequired
};

export default BurgerMenu;
