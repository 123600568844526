import { act } from 'react-dom/test-utils';

export function companyReducer(state = {}, action) {
	switch (action.type) {
		case 'company/set': {
			state = { ...action.payload };
			return state;
		}

		case 'company/clear': {
			state = {};
			return state;
		}
		case 'company/update-location': {
			state.locations = [
				...state.locations.filter((loc) => loc.locid !== action.payload.locid),
				action.payload
			];
			return state;
		}
		case 'company/remove-location': {
			state.locations = [ ...state.locations.filter((loc) => loc.locid !== action.payload) ];
			return state;
		}
		case 'company/set-company-notes': {
			const location = state.locations.find(({ locid }) => locid === action.payload.locid);

			state.locations = [
				...state.locations.filter(({ locid }) => locid !== action.payload.locid),
				{ ...location, notes: action.payload.notes }
			];
			return state;
		}
		case 'company/set-industries': {
			if (!state.industry) {
				return state;
			}

			return {
				...state,
				industry: action.payload
			};
		}

		default: {
			return state;
		}
	}
}
