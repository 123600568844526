import dayjs from 'dayjs';
import styled from 'styled-components';
import { useHistory } from "react-router-dom"
import { baseSettings, foregroundColor, secondaryAccentColor } from '../../../styles/theme';
import InputField from "../../common/InputField"
import { primaryColor } from "../../../styles/theme"

const ActivityContainer = styled.div`
    display: flex;
    flex-direction: column;

`

const ActivtiyButton = styled.button`
    background-color: ${(props) => props.primary === 'primary' ? primaryColor : secondaryAccentColor};
    border: none;
    color: ${foregroundColor};
    padding: ${baseSettings.xs};
    margin-top: ${baseSettings.xs};
    font-size: ${baseSettings.fontMedium};


`

const NoteArea = styled.textarea`
    border: 1px solid ${foregroundColor};
    background: transparent;
    color: ${foregroundColor};
    font-size: ${baseSettings.fontMedium};
    border-radius: 5px;
    margin-top: ${baseSettings.fontSmall};
    max-width: 450px;
    min-height: 20ch;
`


function PrimaryActivity({activity}) {
    const history = useHistory();



    const onEditClick = () => {
        history.push(`/activities/${activity.actid}`)
    }


    return <ActivityContainer>
        <InputField text="Activity Date" defaultValue={activity.date? dayjs(activity.date).format('MM/DD/YY mm:hh A') : ''} disabled={true} />
        <InputField text="Type" defaultValue={activity.type.type} disabled={true} />
        <NoteArea readOnly>{activity.note}</NoteArea>
       <ActivtiyButton type="button" onClick={onEditClick}>Edit</ActivtiyButton>
    </ActivityContainer>
}

export default PrimaryActivity;