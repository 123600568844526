import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { SEARCH_CONTACTS } from '../../api/contacts/searchContacts';

export function useContactFilter() {
	const [ search, { data, error, loading } ] = useLazyQuery(SEARCH_CONTACTS, {
		nextFetchPolicy: 'cache-and-network'
	});
	const filter = useSelector((state) => state.contactFilters);
	const [ contacts, setContacts ] = useState([]);

	const searchContacts = (filter = {}) => {
		let params = {};
		Object.keys(filter).forEach((key) => {
			if (filter[key]) {
				params[key] = filter[key];
			}
		});

		const paramsObj = {...params, pagination: {limit: filter.limit}}

		search({ variables: paramsObj });
	};

	useEffect(
		() => {
			searchContacts(filter);
		},
		[ filter ]
	);

	useEffect(
		() => {
			if (data && data.searchContacts) {
				setContacts(data.searchContacts);
			}
		},
		[ data ]
	);

	return { error, loading, searchContacts, contacts };
}
