import React from 'react';
import styled from 'styled-components';
import { baseSettings, primaryLighterColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';

const PaddedBox = styled.div`
	margin: ${baseSettings.xl} ${baseSettings.large} 0 ${baseSettings.large};
	background-color: ${primaryLighterColor};
	width: 100%;
	height: 100%;
	${mediaQueries('md')`
		margin: 0;
		margin-top: ${baseSettings.xl};
        padding: 8rem 1rem 0 1rem;
	`};
	overflow-y: auto;
	padding-bottom: ${baseSettings.medium};
`;

function PageBox({ children }) {
	return <PaddedBox>{children}</PaddedBox>;
}

export default PageBox;
