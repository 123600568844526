import { useState, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { GET_REPAIR_METRICS } from '../../api/repairs/getRepairMetrics';

export function useRepairMetrics({ type }) {
	const [ rows, setRows ] = useState([]);
	const { loading, error, data } = useQuery(GET_REPAIR_METRICS, {
		variables: {
			type
		}
	});

	const columns = useMemo(
		() => {
			const columnArray = [
				{
					Header: 'Service ID',
					accessor: 'serviceId'
				},
				{
					Header: 'Part #',
					accessor: 'partNumber'
				},
				{
					Header: 'Desciption',
					accessor: 'item'
				},
				{
					Header: 'Location',
					accessor: 'location'
				},
				{
					Header: 'Quote',
					accessor: 'quote'
				},
				{
					Header: 'Cust ID',
					accessor: 'customerID'
				},
				{
					Header: 'Customer ID',
					accessor: 'customerName'
				}
			];

			if (type === 'InProgress' || type === 'Late') {
				columnArray.push({
					Header: 'Est Shp Date',
					accessor: (value) => (value.esd ? dayjs(value.esd).format('MM-DD-YY') : '')
				});
			}

			return columnArray;
		},
		[ type ]
	);

	useEffect(
		() => {
			if (data && data.repairMetrics) {
				setRows(data.repairMetrics);
			}
		},
		[ data ]
	);

	return { loading, columns, rows, error };
}
