import React from 'react';
import styled from 'styled-components';
import { baseSettings, accentColor } from '../../styles/theme';
import SizedBox from '../common/SizedBox';
import { useSelector } from 'react-redux';
import { clearUserToken } from '../../api/user/tokenProvider';

const ProfileContainer = styled.div`
	align-items: flex-end;
	display: flex;
	margin: 0 0 ${baseSettings.xs} ${baseSettings.xs};
`;

const RoundedContainer = styled.div`
	box-sizing: content-box;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${accentColor};
	border-radius: 20%;
	padding: ${baseSettings.xs};
	text-align: center;
	height: ${baseSettings.medium};
	width: ${baseSettings.medium};
`;

const InitialText = styled.div`
	color: white;
	letter-spacing: 1.1;
	font-weight: 800;
	font-size: ${baseSettings.fontLarge};
`;

const LogoutText = styled.div`
	color: ${accentColor};
	cursor: pointer;
	font-size: ${baseSettings.fontSmall};
	font-weight: 600;
`;

function Profile() {
	const { username } = useSelector((state) => state.user);

	// gets user's initials
	const initials = () => {
		if (username) {
			return username.split()[0][0] + username.split(' ')[1][0];
		}
		return '';
	};

	return (
		<ProfileContainer>
			<RoundedContainer>
				<InitialText>{initials()}</InitialText>
			</RoundedContainer>
			<SizedBox left={baseSettings.small} />
			<LogoutText onClick={() => clearUserToken()}>Logout</LogoutText>
		</ProfileContainer>
	);
}

export default Profile;
