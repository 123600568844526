import { gql } from '@apollo/client';

export const GET_COMPANY_DETAILS = gql`
	query($custId: Int!) {
		aesCompany(custId: $custId) {
			id
			customerName
			programManager
			salesPerson
			csRep
			industry
			lastShipped
			contactPerson
			contactTitle
			emailAddress
			phone
			fax
			custType
			mainLocation {
				shipToStreet
				shipToCity
				shipToZip
				shipToState
			}
			recentRepairs {
				serviceId
				itemType
				estShipDate
				checkInDate
				mfgPart
				item
				serialNumber
				location
				completedDate
				quote {
					quote
				}
			}
			inHouseRepairs {
				serviceId
				itemType
				estShipDate
				checkInDate
				mfgPart
				item
				serialNumber
				location
				completedDate
				quote {
					quote
				}
			}
			salesCompany {
				compid
				companyname
			}
		}
	}
`;
