import { css } from 'styled-components';
import theme from 'styled-theming';

export const baseSettings = {
	fontSmall: '1.4rem',
	fontMedium: '1.6rem',
	fontLarge: '1.8rem',
	fontXL: '2.4rem',
	fontHuge: '3.8rem',
	fontxs: '0.8rem',
	xs: '1.0rem',
	small: '2.0rem',
	medium: '3.0rem',
	large: '4.0rem',
	xl: '8.0rem'
};

export const aesBlueColor = theme('mode', {
	light: '#282561',
	dark: '#282561'
});
export const primaryColor = theme('mode', {
	light: '#f6f5f5',
	dark: '#1a1d20'
});

export const primaryColorDarker = theme('mode', {
	light: '#00334e',
	dark: '#0b0c0d'
});

export const foregroundColor = theme('mode', {
	light: '#00334e',
	dark: 'white'
});

export const lightforegroundColor = theme('mode', {
	light: '#00334e',
	dark: '#eee'
});

export const lightGray = theme('mode', {
	light: '#EEEEEE',
	dark: '#b0afaa'
});

export const primaryLighterColor = theme('mode', {
	light: '#FFFFFF',
	dark: '#1E2025'
});

export const accentColor = theme('mode', {
	light: '#ee6f57',
	dark: '#03c4a1'
});

export const secondaryAccentColor = theme('mode', {
	light: '#00334e',
	dark: '#FF5260'
});

export const modalLight = theme('mode', {
	light: 'white',
	dark: '#3b3b40'
});

export const primaryBackgroundGradiantTheme = theme('mode', {
	light: css`
		background: linear-gradient(65deg, #00334e 20%, #00334e, #ee6f57);
		color: #283149;
	`,
	dark: css`
		background: ${primaryColor};
		color: white;
	`
});

export const primaryBackgroundTheme = theme('mode', {
	light: css`
		background: #ebf3fa;
		color: #283149;
	`,
	dark: css`
		background: ${primaryColor};
		color: white;
	`
});

export const primaryTheme = theme('mode', {
	light: css`
		background: ${primaryColor};
		color: #283149;
	`,
	dark: css`
		background: ${primaryColor};
		color: white;
	`
});

export const primaryLighterTheme = theme('mode', {
	light: css`
		background: ${primaryLighterColor};
		color: black;
	`,
	dark: css`
		background: ${primaryLighterColor};
		color: white;
	`
});

export const primaryThemeDarker = theme('mode', {
	light: css`
		background: white;
		color: black;
	`,
	dark: css`
		background: #0b0c0d;
		color: white;
	`
});

export const secondaryTheme = theme('mode', {
	light: css`
		background: #00334e;
		color: white;
	`,
	dark: css`
		background: #f04a23;
		color: white;
	`
});
