import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { baseSettings, foregroundColor } from '../../styles/theme';
import { REMOVE_CONTACT } from '../../api/contacts/removeContact';
import { useContact } from '../../hooks/contacts/useContact';
import { mediaQueries } from '../../styles/media';
import ThemeButton from '../common/ThemeButton';
import RemoveConfirmationModal from '../common/CommonInputModal';
import NewContactModal from '../companies/NewContactModal';

const MoreColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	width: 75vw;
	${mediaQueries('md')`
	    width: 100%;

	`};

	div:nth-child(2) {
		border-top: none;
	}
`;

const MoreRow = styled.div`
	border: 1px solid ${foregroundColor};
	border-radius: 2px;
	display: flex;
	padding: ${baseSettings.small};
`;

function ContactMoreTab() {
	const [ remove, { loading: removeLoading, error: removeError, data: removeData } ] = useMutation(REMOVE_CONTACT);
	const { contid } = useSelector((state) => state.contact);
	const { duplicate } = useContact({ contid });
	const [ removeModalOpen, setRemoveModalOpen ] = useState(false);
	const [ newContactModalOpen, setNewContactModal ] = useState(false);
	const history = useHistory();

	const removeContact = (input = '') => {
		if (input.toLowerCase() === 'yes') {
			remove({
				variables: {
					contid
				}
			});
		}
	};

	useEffect(
		() => {
			if (removeData && removeData.removeContact) {
				history.push('/contacts');
			}
		},
		[ history, removeData ]
	);

	return (
		<MoreColumn>
			<MoreRow>
				{/* <NewContactModal isOpen={newContactModalOpen} toggleModal={} /> */}
				<ThemeButton
					scaleEffect={false}
					width="25%"
					onClick={() =>
						duplicate({
							contactId: contid
						})}
				>
					Dupe Contact?
				</ThemeButton>
			</MoreRow>
			<MoreRow>
				<RemoveConfirmationModal
					AcceptButtonText="Confirm"
					InputText="Type YES to remove contact"
					toggleOpen={() => setRemoveModalOpen(!removeModalOpen)}
					isOpen={removeModalOpen}
					OnAccept={removeContact}
					OnCancel={() => setRemoveModalOpen(false)}
				/>

				<ThemeButton
					variant="secondary"
					scaleEffect={false}
					onClick={() => setRemoveModalOpen(true)}
					width={'25%'}
				>
					💣 Remove Contact?
				</ThemeButton>
			</MoreRow>
		</MoreColumn>
	);
}

export default ContactMoreTab;
