import React from 'react';

/**
 * 
 * Useful sizing box to allow for margin between items
 */
function SizedBox({ top = '0', right = '0', bottom = '0', left = '0' }) {
	return (
		<div
			style={{
				margin: `${top} ${right} ${bottom} ${left}`
			}}
		/>
	);
}

export default SizedBox;
