import { gql } from '@apollo/client';

export const GET_REPAIR_METRICS = gql`
	query($type: RepairSType!) {
		repairMetrics(type: $type) {
			serviceId
			partNumber
			item
			location
			quote
			customerID
			customerName
			esd
		}
	}
`;
