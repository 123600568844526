import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CITIES } from '../../api/cities/getCities';

export function useCities() {
	const { loading, error, data } = useQuery(GET_CITIES, { pollInterval: 1000 });
	const [ cities, setCities ] = useState([]);

	useEffect(
		() => {
			if (data && data.cities) {
				setCities(data.cities);
			}
		},
		[ data ]
	);

	return { loading, error, cities };
}
