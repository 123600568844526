import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Chart } from 'react-charts';
import { CubeGrid } from 'better-react-spinkit';
import { useMediaQuery } from 'react-responsive';
import {
	accentColor,
	baseSettings,
	secondaryAccentColor,
	foregroundColor,
	lightforegroundColor
} from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import { useTheme } from '../../styles/ThemeProvider';
import { useMonthGoals } from '../../hooks/metrics/useMonthGoals';
import { useY2DGoals } from '../../hooks/metrics/useYTDGoals';

import MetrixText from '../common/MetricText';

const ChartContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	height: 300px;
	width: 100%;
	${mediaQueries('md')`
		height: 650px;
		width: 100%;

	`};
`;
const SalesChart = styled(Chart)`
    top: 0px;

    width: auto;

`;

const MetricRow = styled.div`
	display: flex;

	${mediaQueries('md')`
		flex-direction: column;

	`};
`;

const determinePercentColor = (value = '0%') => {
	const numericalText = value.replace('%', '');
	if (!isNaN(numericalText)) {
		return parseFloat(numericalText) >= 100 ? 'greater' : 'less';
	}
	return 'equal';
};

const Metrics = styled(MetrixText)`


`;

function SalesMonthGoalGraph() {
	const isMobile = useMediaQuery({ query: '(max-width: 30em)' });
	const { loading, error, seriesData } = useMonthGoals();
	const { loading: ytdLoading, metrics } = useY2DGoals();
	const { theme } = useTheme();

	const series = useMemo(
		() => ({
			type: isMobile ? 'bar' : 'line'
		}),
		[]
	);

	const axes = useMemo(
		() => [
			{ primary: true, type: 'ordinal', position: isMobile ? 'left' : 'bottom' },
			{ position: isMobile ? 'bottom' : 'left', type: 'linear', stacked: false, show: false }
		],
		[]
	);

	return (
		<ChartContainer>
			{loading ? (
				<CubeGrid size={65} color={theme === 'dark' ? 'white' : '#ee6f57'} />
			) : (
				<SalesChart data={seriesData} axes={axes} series={series} tooltip dark={theme === 'dark'} />
			)}
			<MetricRow>
				<Metrics
					loading={ytdLoading}
					numberDescription="Month Sales"
					numberValue={(metrics && metrics.monthActual) || 0}
					unit="currency"
				/>
				<Metrics
					loading={ytdLoading}
					numberDescription="Month Goal to Date"
					numberValue={(metrics && metrics.monthGoalTD) || 0}
					unit="currency"
				/>

				<Metrics
					loading={ytdLoading}
					numberDescription="Month Goal"
					numberValue={(metrics && metrics.monthGoal) || '0.00'}
				/>

				<Metrics
					loading={ytdLoading}
					numberDescription="Month vs Goal"
					numberValue={(metrics && metrics.monthGoalPercent) || '0.00%'}
					colorScheme={determinePercentColor((metrics && metrics.monthGoalPercent) || '0.00%')}
				/>

				<MetrixText
					loading={ytdLoading}
					numberDescription="Sales YTD"
					numberValue={(metrics && metrics.salesYTD) || 0}
					unit="currency"
				/>

				<MetrixText
					loading={ytdLoading}
					numberDescription="Goals YTD"
					numberValue={(metrics && metrics.goalYTD) || 0}
					unit="currency"
				/>

				<MetrixText
					loading={ytdLoading}
					numberDescription="Desired YTD"
					numberValue={(metrics && metrics.desiredYTD) || 0}
					unit="currency"
				/>

				<Metrics
					loading={ytdLoading}
					numberDescription="Sales vs Goal YTD"
					numberValue={(metrics && metrics.goalPercentToDate) || '0.00%'}
					colorScheme={determinePercentColor((metrics && metrics.goalPercentToDate) || '0.00%')}
				/>
			</MetricRow>
		</ChartContainer>
	);
}

export default SalesMonthGoalGraph;
