import { useState, useEffect } from "react";
import { useGraph } from "../../provider/GraphProvider";

export function useGraphProfile() {
	const { instance, isAuthenticated, profile } = useGraph();
	const [profileState, setProfileState] = useState(["uninitialized", null]);

	useEffect(() => {
		if (!isAuthenticated) {
			setProfileState(["loading", null]);
			return;
		}

		console.log("profile state");
		setProfileState(["loaded", profile]);
	}, [isAuthenticated, instance]);

	useEffect(() => {
		if (isAuthenticated && profile) {
			setProfileState(["loaded", profile]);
		}
	}, [profile]);

	return { profileState };
}
