import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import PageBox from '../common/PageBox';
import MultiPageComponent from '../common/MultiPageComponent';
import { mediaQueries } from '../../styles/media';
import CompanyPage from './CompanyPage';
import ContactPage from './ContactPage';
import NotePage from './NotePage';
import { secondaryAccentColor, baseSettings } from '../../styles/theme';
import { useAddActivity } from '../../hooks/activities/useAddActivity';
import { clearActivity } from '../../redux/actions/newActivity';

const OrderPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 90vh;
	width: 100vw;
	${mediaQueries('md')`
        height: 90vh;
        width: 100vw;
    
    `};
`;

const ErrorMessage = styled.p`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontMedium};
`;

function ScheduleActivityPage() {
	const { actId, loading, error, addActivity } = useAddActivity();
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams();

	const activityState = useSelector((state) => state.newActivity);
	const pages = [
		{
			validate: () => {
				return activityState.typeid && activityState.date && activityState.duration;
			},
			component: <CompanyPage />
		},
		{
			validate: () => activityState.participants.length > 0,
			component: <ContactPage />
		},
		{
			validate: () => true, // notes not required
			component: <NotePage />
		}
	];

	const submitActivity = () => {
		const formatttedActivity = {
			...activityState,
			date: activityState.date,
			participants: activityState.participants.map((part) => ({ contid: part.contid, name: part.name }))
		};
		addActivity(formatttedActivity);
	};

	if (actId) {
		//clear state and navigate!
		dispatch(clearActivity());
		history.push(`/activities/${actId}`);
	}

	return (
		<PageBox>
			<OrderPageContainer>
				{error && <ErrorMessage>{error.message}</ErrorMessage>}
				<MultiPageComponent pages={pages} handleSubmit={submitActivity} initialPage={params['0'] - 1 || 0} />
			</OrderPageContainer>
		</PageBox>
	);
}

export default ScheduleActivityPage;
