import { useState } from "react";
import { useGraph } from "../../provider/GraphProvider";
import dayjs from "dayjs";
import dayjsPlugin from "dayjs-plugin-utc";
/**
 * Responsible for adding/removing evens from a user's O365 calendar
 */

// https://docs.microsoft.com/en-us/graph/api/resources/event?view=graph-rest-1.0
const baseUrl = "https://graph.microsoft.com/v1.0/me/events";

/**
 * Adds an event to user outlook calendar
 * @returns status ['paused','success','error']
 */
export function useGraphEvent() {
	const { graphService, callMSGraphPost } = useGraph();
	const [status, setStatus] = useState("paused");

	dayjs.extend(dayjsPlugin);

	const formatBodyText = ({ body, participants }) => {
		return `Event with ${participants.map(({ name = "" }) => name)} regarding ${body}`;
	};

	const scheduleEvent = async ({ startDate, endDate, subject, body = "", participants = [] }) => {
		try {
			await callMSGraphPost(baseUrl, {
				subject: subject || body.substring(0, 25),
				body: {
					content: formatBodyText({ body, participants }),
					contentType: "text",
				},
				start: {
					dateTime: dayjs(startDate).utc().format(),
					timeZone: "UTC",
				},
				end: {
					dateTime: dayjs(startDate).utc().format(),
					timeZone: "UTC",
				},
			});

			setStatus("success");
		} catch (error) {
			setStatus("error");
		}
	};

	return { scheduleEvent, status };
}
