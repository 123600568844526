import React, { useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { VscNewFile } from 'react-icons/vsc';
import { useSelector } from 'react-redux';
import GenericTable from '../common/genericTable';
import NoteForm from '../common/noteForm';
import ThemeButton from '../common/ThemeButton';
import { mediaQueries } from '../../styles/media';
import { baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';

const MainNoteContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const NoteContainer = styled.div`
	display: flex;
	width: 100%;
	${mediaQueries('md')`
		flex-direction: column;
	`};
`;

const NoteColumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 25vw;
	${mediaQueries('md')`
		width: 100vw;
		margin-top: ${baseSettings.small};
	`};
`;

const NewNoteButton = styled(VscNewFile)`

	color: ${foregroundColor};
	margin-top: ${baseSettings.small};
	font-size: 4.5rem;
	&:hover {
		color: ${secondaryAccentColor};
	}
`;

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 450px;
	max-height: 450px;
	${mediaQueries('md')`
		height: 200px;
	`};
	overflow-y: auto;
`;

function CompanyNotes() {
	const { locid, notes } = useSelector((state) => state.location);

	const [ note, setNote ] = useState(null);

	useEffect(
		() => {
			setNote(null);
		},
		[ locid ]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'Note ID',
				accessor: 'cnoteid'
			},
			{
				Header: 'Type ID',
				accessor: 'type.typeid'
			},
			{
				Header: 'Note Type#',
				accessor: 'type.type'
			},
			{
				Header: 'Date',
				accessor: (value) => (value.createdDate ? dayjs(value.createdDate).format('MM-DD-YY') : null)
			},
			{
				Header: 'Note',
				accessor: 'note'
			}
		],
		[]
	);

	return (
		<MainNoteContainer>
			<NoteContainer>
				{/* will hold the assigned notes */}
				<NoteColumn>
					<NewNoteButton
						onClick={() => {
							setNote({ cnoteid: 0, note: '', typeid: null });
						}}
					/>
					{notes &&
					notes.length > 0 && (
						<TableContainer>
							<GenericTable
								columns={columns}
								data={notes}
								width={'100%'}
								onSelect={(e) =>
									setNote({
										cnoteid: e.cnoteid,
										typeid: e['type.typeid'],
										note: e.note
									})}
								hiddenColumns={[ 'cnoteid', 'type.typeid', 'note' ]}
							/>
						</TableContainer>
					)}
				</NoteColumn>
				{/* will hold the detail and commands */}
				<NoteColumn>{note && <NoteForm locid={locid} selectedNote={note} />}</NoteColumn>
			</NoteContainer>
		</MainNoteContainer>
	);
}

CompanyNotes.propTypes = {
	location: propTypes.object.isRequired
};

export default CompanyNotes;
