import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import propTypes from 'prop-types';
import Select from './SelectField';
import { GET_ACTIVITY_TYPES } from '../../api/activityTypes/activityTypes';

function ActivityTypeSelect({ selectionChanged, width = '100%', selectedValue = null }) {
	const { loading, error, data } = useQuery(GET_ACTIVITY_TYPES);

	const options = useMemo(
		() => {
			if (data && data.activityTypes) {
				return data.activityTypes.map(({ typeid, type }) => ({
					label: type,
					value: typeid
				}));
			}
			return [];
		},
		[ data ]
	);

	return (
		<Select
			text={'Activity Type'}
			onInputChange={() => {}}
			onChange={({ value }) => selectionChanged(value)}
			options={options}
			loading={loading}
			width={width}
			value={options.find((opt) => opt.value === selectedValue)}
			// value={{ label: `${option.label}`, value: `${option.value}` }}
			noOptionsMessage={'Type Not found'}
		/>
	);
}

ActivityTypeSelect.propTypes = {
	selectionChanged: propTypes.func.isRequired,
	width: propTypes.string,
	selectedValue: propTypes.number
};

export default ActivityTypeSelect;
