import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { CubeGrid } from "better-react-spinkit";
import { setNCIProps } from "../../redux/actions/nci";
import ContactSelect from "../contacts/ContactSelect";
import { GET_CONTACT } from "../../api/contacts/getContact";
import { useTheme } from "../../styles/ThemeProvider";
import { ReadOnlyField } from "../common/InputField";
import { foregroundColor, baseSettings } from "../../styles/theme";
import { useContact } from "../../hooks/contacts/useContact";

const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const HeaderText = styled.h2`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 600;
`;

const Description = styled.p`
	color: ${foregroundColor};
	font-weight: 300;
	font-size: ${baseSettings.fontMedium};
	text-align: left;
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 2vh;
`;

function ContactDetails({ contid }) {
	const { loading, error, data } = useQuery(GET_CONTACT, { variables: { contid } });
	const [contact, setContact] = useState(null);

	useEffect(() => {
		if (data && data.contact) {
			setContact(data.contact);
		}
	}, [data]);

	const themeData = useTheme();
	return (
		<div>
			{loading && <CubeGrid color={themeData.theme === "dark" ? "white" : "#ee6f57"} size={25} />}
			{contact && (
				<FormContainer>
					<ReadOnlyField text="First Name" value={contact.firstName} />
					<ReadOnlyField text="First Name" value={contact.lastName} />
					<ReadOnlyField text="Title" value={contact.title} />
					<ReadOnlyField text="Email" value={contact.email} />
					<ReadOnlyField text="Phone" value={contact.cellphone} />
				</FormContainer>
			)}
		</div>
	);
}

function ContactPage({ company }) {
	const { compid = 0, contid = 0 } = useSelector((state) => state.nci);
	const [contact, setContact] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (contact) {
			dispatch(setNCIProps({ contid: parseInt(contact.contid) }));
		}
	}, [contact]);

	useEffect(() => {
		if (contid) {
			const contact = company.contacts.find((c) => c.contid === contid);
			if (contact) {
				setContact(contact);
			}
		}
	}, [contid]);

	return (
		<ColumnContainer>
			<HeaderText>Please select the primary contact</HeaderText>
			<Description>
				This contact will be the default listing in the system.To add more contacts Please visit the company's contact
				page
			</Description>
			<FormContainer>
				<ContactSelect
					lazyload={false}
					compid={compid}
					selectedContactId={contid}
					onSelectContact={(value) => setContact(value)}
				/>
				{contact && <ContactDetails contid={contact.contid} />}
			</FormContainer>
		</ColumnContainer>
	);
}

export default ContactPage;
