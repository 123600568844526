import React from 'react';
import Select from '../common/SelectField';

export const durationOptions = [
	{ value: 5, label: '5 Minutes' },
	{ value: 15, label: '15 Minutes' },
	{ value: 30, label: '30 Minutes' },
	{ value: 60, label: '1 Hour' },
	{ value: 120, label: '2 Hours' },
	{ value: 200, label: '3 Hours' }
];

function DurationSelect({ onChange, duration }) {
	return (
		<Select
			defaultValue={durationOptions[2]}
			options={durationOptions}
			onChange={({ value }) => onChange(value)}
			text="Activity Duration"
			value={durationOptions.find(({ value }) => value === (duration || 5))}
		/>
	);
}

export default DurationSelect;
