import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { uSStates } from '../../api/static-data/states';
import Select from './SelectField';
import { useSelector } from "react-redux";

function StateSelect({ control = null }) {


	const onInputChange = (value) => {
		if (value) {
		}
	};

	const options = uSStates.map((state) => ({ label: state, value: state }));


	return (
		<Controller
			name={'state'}
			control={control}
			render={(props) => {
				// if not state is selected it will default to the user's working state
				const option = options.find(({ value }) => value === props.value) ?? {label: '', value: ''}


				return (
					<Select
						text={'State'}
						onInputChange={onInputChange }
						onChange={({ value }) => props.onChange(value)}
						options={options}
						value={{ label: `${option.label}`, value: `${option.value}` }}
						noOptionsMessage={'State not Found'}
					/>
				);
			}}
		/>
	);
}

StateSelect.propTypes = {
	control: propTypes.object.isRequired
};

export default StateSelect;
