export const clearLocation = () => ({
	type: 'location/clear'
});

export const setInitialLocation = (locations = []) => ({
	type: 'location/initial',
	payload: locations
});

export const setLocation = (location) => ({
	type: 'location/set',
	payload: location
});

export const setLocationNotes = (notes) => ({
	type: 'location/note-set',
	payload: notes
});
