import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { useForm } from 'react-hook-form';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import theme from 'styled-theming';
import Modal from '../common/modalContainer';
import {
	primaryColor,
	modalLight,
	baseSettings,
	foregroundColor,
	secondaryAccentColor,
	accentColor
} from '../../styles/theme';
import { clearFilter, setFilter } from '../../redux/actions/contactFilter';
import { mediaQueries } from '../../styles/media';
import InputField from '../common/InputField';
import SizedBox from '../common/SizedBox';
import StateSelect from '../common/stateSelect';
import ThemeButton from '../common/ThemeButton';
import CompanySelect from '../common/companySelect';

const FilterContainer = styled.div`
	align-items: center;
	border-radius: 25px;
	/* border: 2px solid orange; */
	border: ${theme('mode', {
		dark: 'none',
		light: '2px solid #00334e'
	})};
	background: ${modalLight};
	box-shadow: 1px 1px 8px 5px ${primaryColor};
	display: flex;
	flex-direction: column;
	height: 45%;
	width: 25%;
	${mediaQueries('md')`
		overflow: scroll;
		justify-content: space-between;
		height: 80%;
		width: 80%;	
	
	`};
`;

const HeaderRow = styled.div`
	align-items: center;
	border-bottom: 1px solid ${foregroundColor};
	display: flex;
	justify-content: space-between;
	padding: ${baseSettings.xs};
	height: 10%;
	width: 100%;
`;

const HeaderText = styled.h3`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
`;

const CloseButton = styled(IoIosCloseCircleOutline)`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontXL};
	transition: color 0.33 ease-in;
	--webkit-transition: color 0.33 ease-in;
	&:hover{
		color: ${accentColor};
	}

`;

const FilterForm = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: ${baseSettings.xs};
`;

const FilterRow = styled.div`
	display: flex;
	${mediaQueries('md')`
        flex-direction: column;	
	
	`};
`;

const ButtonRow = styled.div`
	display: flex;

	justify-content: space-between;
	${mediaQueries('md')`
		flex-direction: column;
	`};
`;

const Container = animated(FilterContainer);

// has to be done this way to allow default values to reset on modal open
const Form = ({ toggleOpen }) => {
	const filter = useSelector((state) => state.contactFilters);
	const dispatch = useDispatch();
	const { control, register, handleSubmit } = useForm({ defaultValues: { ...filter } });
	const onsubmit = (filters) => {
		dispatch(
			setFilter({
				...filters
			})
		);
		toggleOpen();
	};

	const removeFilter = () => {
		// clear filter dispatch then modal
		dispatch(clearFilter());
		toggleOpen();
	};
	return (
		<FilterForm onSubmit={handleSubmit(onsubmit)}>
			<FilterRow>
				<InputField name={'firstName'} register={register} text="First Name" width={'80%'} />
				<InputField name="lastName" register={register} text="Last Name" width={'80%'} />
			</FilterRow>
			<CompanySelect control={control} />
			<StateSelect control={control} />
			<SizedBox bottom={baseSettings.medium} />
			<ButtonRow>
				<ThemeButton variant={'secondary'} onClick={removeFilter}>
					Clear Filter
				</ThemeButton>

				<ThemeButton type={'submit'}>Apply Filter</ThemeButton>
			</ButtonRow>
		</FilterForm>
	);
};

function FilterModal({ isOpen, toggleOpen }) {
	const props = useSpring({
		to: { transform: isOpen ? 'scale(1.2)' : 'scale(0)', opacity: isOpen ? 1 : 0 },
		config: { duration: 300, mass: 1, tension: 180, friction: 12 },
		from: { opacity: 0, transform: 'scale(0)' }
	});

	return (
		<Modal isOpen={isOpen} toggleOpen={toggleOpen}>
			<Container style={props}>
				<HeaderRow>
					<HeaderText>Filter</HeaderText>
					<CloseButton onClick={() => toggleOpen()} />
				</HeaderRow>
				<Form toggleOpen={toggleOpen} />
			</Container>
		</Modal>
	);
}

export default FilterModal;
