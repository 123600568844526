import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { baseSettings, foregroundColor, lightforegroundColor, primaryColor } from '../../styles/theme';
import SizedBox from '../common/SizedBox';
import TabContainer from '../common/tabContainer';
import RepairTable from './RepairTable';
import SalesCompanyNav from './salesCompanyNav';
import { mediaQueries } from '../../styles/media';

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.small};
`;

const FormHeader = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontLarge};
	margin: 0;
`;

const FormSubHeading = styled.h4`
	margin: ${baseSettings.xs} 0 0 0;
	font-size: ${baseSettings.fontLarge};
	font-weight: 300;
	color: ${lightforegroundColor};
`;

const ContainerRow = styled.div`
	display: flex;
	width: 100%;
	${mediaQueries('md')`
        flex-direction: column;
    
    `};
`;

const TabControl = styled(TabContainer)`
    ${mediaQueries('md')`
       padding-top: ${baseSettings.small};
       
    `};
`;

const ColumnHeader = styled.h3`
	margin: 0;
	margin-bottom: 4.4rem;
	font-size: ${baseSettings.fontMedium};
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
`;

const DetailColumn = styled(Column)`
	max-height: 725px;
	overflow-y: auto;

`;

const DataContainer = styled.div`
	background-color: ${primaryColor};
	display: flex;
	flex-direction: column;
	padding: 6px ${baseSettings.small};
	width: 250px;
`;

const DataHeader = styled.div`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
`;

const DataText = styled(DataHeader)`
    color: ${lightforegroundColor};
    font-size: ${baseSettings.fontSmall};
    margin-top: ${baseSettings.xs};
    font-weight: 200;

`;

function CustomerDetailForm({ customer }) {
	return (
		<FormContainer>
			<FormHeader>{customer.customerName}</FormHeader>
			<FormSubHeading>{customer.id}</FormSubHeading>
			{customer.salesCompany && <SalesCompanyNav compid={customer.salesCompany.compid} />}
			<SizedBox bottom={baseSettings.large} />
			<ContainerRow>
				<DetailColumn>
					<ColumnHeader>Cust Info</ColumnHeader>
					<DataContainer>
						<DataHeader>Last Shipped Date</DataHeader>
						<DataText>
							{(customer.lastShipped && dayjs(customer.lastShipped).format('MM-DD-YY')) ||
								'No Items Shipped'}
						</DataText>
					</DataContainer>

					<DataContainer>
						<DataHeader>Account Manager</DataHeader>
						<DataText>{customer.programManager}</DataText>
					</DataContainer>

					<DataContainer>
						<DataHeader>Customer Service Rep.</DataHeader>
						<DataText>{customer.csRep}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>Salesperson</DataHeader>
						<DataText>{customer.salesPerson}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>Industry</DataHeader>
						<DataText>{customer.industry}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>Type</DataHeader>
						<DataText>{customer.custType}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>Contact Person</DataHeader>
						<DataText>{customer.contactPerson}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>Title</DataHeader>
						<DataText>{customer.contactTitle}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>Email</DataHeader>
						<DataText>{customer.emailAddress}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>Phone</DataHeader>
						<DataText>{customer.phone}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>Street</DataHeader>
						<DataText>{customer.mainLocation && customer.mainLocation.shipToStreet}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>City</DataHeader>
						<DataText>{customer.mainLocation && customer.mainLocation.shipToCity}</DataText>
					</DataContainer>
					<DataContainer>
						<DataHeader>State/Zip</DataHeader>
						<DataText>
							{' '}
							{customer.mainLocation && customer.mainLocation.shipToZip},{' '}
							{customer.mainLocation && customer.mainLocation.shipToState}
						</DataText>
					</DataContainer>
				</DetailColumn>
				<Column>
					<TabControl
						tabs={[
							{
								name: 'Recent Repairs',
								child: <RepairTable repairs={customer.recentRepairs} />
							},
							{
								name: 'In House',
								child: <RepairTable repairs={customer.inHouseRepairs} />
							}
						]}
					/>
				</Column>
			</ContainerRow>
		</FormContainer>
	);
}

export default CustomerDetailForm;
