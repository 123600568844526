import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import propTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSpring, animated } from 'react-spring';
import { baseSettings, foregroundColor, modalLight, primaryColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import ModalContainer from '../common/modalContainer';
import ThemeButton from '../common/ThemeButton';
import SizedBox from '../common/SizedBox';

const Container = styled.form`
	align-items: center;
	border-radius: 25px;
	/* border: 2px solid orange; */
	border: ${theme('mode', {
		dark: 'none',
		light: '2px solid #00334e'
	})};
	background: ${modalLight};
	box-shadow: 1px 1px 8px 5px ${primaryColor};
	display: flex;
	flex-direction: column;

	height: 30%;
	width: 30%;
	${mediaQueries('md')`
		overflow: scroll;
		height: 50%;
        justify-content: space-between;
		width: 80%;	
	
	`};
	padding: ${baseSettings.small};
`;

const Header = styled.h4`
	color: ${foregroundColor};
	font-size: ${baseSettings.small};
	font-weight: 400;
`;

const Input = styled.input`
	border-radius: 5px;
	border: none;
	border: 1px solid ${foregroundColor};
	color: ${foregroundColor};
	background: none;
	font-size: ${baseSettings.fontMedium};
	padding: 0.5rem;
	font-weight: 400;
	width: 100%;
`;

const ButtonRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	${mediaQueries('md')`
		flex-direction: column;
	
	`};
`;

const AnimatedContainer = animated(Container);

const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

function NewLinkModal({ isOpen = false, toggleModal, onSubmit }) {
	const props = useSpring({
		to: { transform: isOpen ? 'scale(1.2)' : 'scale(0)', opacity: isOpen ? 1 : 0 },
		config: { duration: 300, mass: 1, tension: 180, friction: 12 },
		from: { opacity: 0, transform: 'scale(0)' }
	});

	const { handleSubmit, register } = useForm();

	const submit = (values) => {
		onSubmit(values);
		toggleModal();
	};

	return (
		<ModalContainer isOpen={isOpen} toggleModal={toggleModal}>
			<AnimatedContainer style={props} onSubmit={handleSubmit(submit)}>
				<Header>New Link 🔗</Header>
				<Input name="site" ref={register({ required: true })} placeholder="Site Name" />
				<SizedBox bottom={baseSettings.small} />
				<Input name="url" ref={register({ required: true, pattern: urlRegex })} placeholder="URL" />
				<SizedBox bottom={baseSettings.small} />
				<ButtonRow>
					<ThemeButton variant="secondary" onClick={toggleModal}>
						Cancel
					</ThemeButton>
					<ThemeButton type="submit">Create</ThemeButton>
				</ButtonRow>
			</AnimatedContainer>
		</ModalContainer>
	);
}

NewLinkModal.propTypes = {
	onSubmit: propTypes.func.isRequired,
	isOpen: propTypes.bool,
	toggleOpen: propTypes.func.isRequired
};

export default NewLinkModal;
