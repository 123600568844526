import { gql } from '@apollo/client';

export const ADD_CONTACT_LINK = gql`
	mutation($contid: Int!, $url: String!, $site: String!) {
		addContactLink(contid: $contid, url: $url, site: $site) {
			linkid
			site
			url
		}
	}
`;
