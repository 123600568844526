import { gql } from '@apollo/client';

export const DASHBOAD_METRICS = gql`
	query {
		dashboardMetrics {
			inProgress
			itemsInWapp
			customerCount
			shipmentsThisMonth
			totalMonthSold
			totalApprovedInHouse
			totalInWapp
			monthStartDate
			monthEndDate
			countPastESD
			itemQuotePastESD
		}
	}
`;

export const MONTHLY_METRICS = gql`
	query {
		monthlySalesMetrics {
			seriesId
			jan
			feb
			march
			april
			may
			jun
			july
			aug
			sept
			oct
			nov
			dec
		}
	}
`;

export const MONTH_GOALS = gql`
	query {
		monthGoals {
			series
			day
			sales
		}
	}
`;

export const YTD_GOALS = gql`
	query {
		ytdMetrics {
			salesYTD
			goalYTD
			monthGoal
			desiredYTD
			goalPercentToDate
			monthActual
			monthGoalTD
			monthGoalPercent
		}
	}
`;
