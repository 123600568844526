import { gql } from '@apollo/client';

export const SEARCH_COMPANIES = gql`
	query(
		$companyname: String
		$phone: String
		$industry: Int
		$naics: Int
		$state: String
		$city: Int
		$county: Int
		$aescustid: Int
			$pagination: PaginationArgs
	) {
		searchCompanies(
			companyname: $companyname
			aescustid: $aescustid
			phone: $phone
			industry: $industry
			naics: $naics
			state: $state
			city: $city
			county: $county
				pagination: $pagination
		) {
			compid
			companyname
			aescustid
			salesperson
			contacts {
				contid
			}
			locations {
				locid
			}
		}
	}
`;
