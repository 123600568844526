import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import GenericTable from '../common/genericTable';
import { Virtuoso } from 'react-virtuoso';
import { useIsMobile } from '../../hooks/design/useIsMobile';
import { foregroundColor, baseSettings, secondaryAccentColor } from '../../styles/theme';
import { serviceIDLink } from "../../router/ExternalRouteUrls"

const ContactDiv = styled.div`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	margin: ${baseSettings.xs} 0;
	text-decoration: none;
	min-height: 15vh;
	text-decoration: none;
	width: 100%;
`;

const ContactDivHeader = styled.a`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 800;
	text-decoration: underline;
	margin-bottom: ${baseSettings.xs};
`;

const ContactDivText = styled.h3`
	font-weight: 300;
	margin: 0;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	opacity: 0.7;
`;

const ContactDivRow = styled.div`
	align-items: center;
	display: flex;
	margin-top: 2px;
`;

const AccentRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

const AccentColumn = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
`;

const RepairAccentLabel = styled.p`
	font-size: ${baseSettings.fontxs};
	color: ${foregroundColor};
	text-transform: uppercase;
`;
const RepairAccentData = styled.p`color: ${secondaryAccentColor};`;

function MobileRepair(props) {
	const {
		item = '',
		serviceId = '',
		mfgPart = '',
		quote = { quote: 'NQ' },
		location = '',
		estShipDate = null
	} = props;

	return (
		<ContactDiv>
			<ContactDivRow>
				<ContactDivHeader href={`${serviceIDLink}/${serviceId}`} target="_blank">{serviceId}</ContactDivHeader>
			</ContactDivRow>
			<ContactDivRow>
				<ContactDivText>
					{mfgPart} - {item}
				</ContactDivText>
			</ContactDivRow>
			<AccentRow>
				<AccentColumn>
					<RepairAccentLabel>Location</RepairAccentLabel>
					<RepairAccentData>{location}</RepairAccentData>
				</AccentColumn>
				<AccentColumn>
					<RepairAccentLabel>Quote</RepairAccentLabel>
					<RepairAccentData>{quote && quote.quote ? quote.quote : 'NQ'}</RepairAccentData>
				</AccentColumn>
				<AccentColumn>
					<RepairAccentLabel>ESD</RepairAccentLabel>
					<RepairAccentData>{estShipDate ? dayjs(estShipDate).format('MM-DD-YY') : 'N/A'}</RepairAccentData>
				</AccentColumn>
			</AccentRow>
		</ContactDiv>
	);
}

function RepairTable({ repairs }) {
	const columns = React.useMemo(
		() => [
			{
				Header: 'Service ID',
				accessor: 'serviceId'
			},
			{
				Header: 'Item Type',
				accessor: 'itemType'
			},
			{
				Header: 'Check in Date',
				accessor: (data) => (data && data.checkInDate ? dayjs(data.checkInDate).format('MM-DD-YY') : '')
			},
			{
				Header: 'MFG Part#',
				accessor: 'mfgPart'
			},
			{
				Header: 'Item Desc',
				accessor: 'item'
			},
			{
				Header: 'Serial Number',
				accessor: 'serialNumber'
			},
			{
				Header: 'Location',
				accessor: 'location'
			},
			{
				Header: 'Completed Date',
				accessor: (data) => (data && data.completedDate ? dayjs(data.completedDate).format('MM-DD-YY') : '')
			},
			{
				Header: 'Quote',
				accessor: 'quote.quote'
			}
		],
		[ repairs ]
	);
	const isMobile = useIsMobile();

	if (isMobile) {
		return (
			<Virtuoso
				style={{ height: '650px', width: '100%' }}
				data={repairs}
				itemContent={(_, repair) => (
					<MobileRepair
						item={repair.item}
						serviceId={repair.serviceId}
						mfgPart={repair.mfgPart}
						quote={repair.quote}
						location={repair.location}
						estShipDate={repair.estShipDate}
					/>
				)}
			/>
		);
	}

	return <GenericTable columns={columns} data={repairs} linkColumn={({route: serviceIDLink})} />;
}

export default RepairTable;
