import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useHistory, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { baseSettings, foregroundColor } from '../../styles/theme';
import { REMOVE_COMPANY } from '../../api/companies/removeCompany';
import { mediaQueries } from '../../styles/media';
import SizedBox from '../common/SizedBox';
import ThemeButton from '../common/ThemeButton';
import RemoveConfirmationModal from '../common/CommonInputModal';

const MoreColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	width: 45%;
	${mediaQueries('md')`
	width: 100%;

	`};
`;

const Column = styled.div`
	border: 1px solid ${foregroundColor};
	border-radius: 2px;
	display: flex;
	padding: ${baseSettings.small};
`;

const DescriptionText = styled.p`
	color: ${foregroundColor};
	flex: 0.5;
	font-size: ${baseSettings.fontSmall};
	margin: 0 0 ${baseSettings.xs} 0;
	text-align: center;
`;

const NavContainer = styled(NavLink)`
	text-decoration: none;
`;

function CompanyMoreTab() {
	const [ remove, { loading: removeLoading, error: removeError, data: removeData } ] = useMutation(REMOVE_COMPANY);
	const { compid, aescustid = null } = useSelector((state) => state.company);
	const [ removeModalOpen, setRemoveModalOpen ] = useState(false);
	const history = useHistory();

	const removeCompany = (input = '') => {
		if (input.toLowerCase() === 'yes') {
			remove({
				variables: {
					compid
				}
			});
		}
	};

	useEffect(
		() => {
			if (removeData && removeData.removeCompany) {
				history.push('/companies');
			}
		},
		[ history, removeData ]
	);

	return (
		<MoreColumn>
			<MoreColumn>
				<RemoveConfirmationModal
					AcceptButtonText="Confirm"
					InputText="Type YES to remove company"
					toggleOpen={() => setRemoveModalOpen(!removeModalOpen)}
					isOpen={removeModalOpen}
					OnAccept={removeCompany}
				/>

				{!aescustid && (
					<ThemeButton variant="secondary" scaleEffect={false} onClick={() => setRemoveModalOpen(true)}>
						💣 Remove Company?
					</ThemeButton>
				)}
			</MoreColumn>
			{!aescustid && (
				<MoreColumn>
					<DescriptionText>Click below to launch a new NCI form</DescriptionText>
					<NavContainer to={`/new-aes-customer/${compid}`}>
						<ThemeButton variant="primary" scaleEffect={false}>
							🎉 Convert to AES Customer
						</ThemeButton>
					</NavContainer>
				</MoreColumn>
			)}
		</MoreColumn>
	);
}

export default CompanyMoreTab;
