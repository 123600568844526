import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setIndustry } from "../../redux/actions/company";
import { FILTER_INDUSTRIES } from "../../api/industries/filterIndstries";
import { ASSIGN_INDUSTRY } from "../../api/industries/assignIndustry";
import { REMOVE_INDUSTY_ASSIGNMENT } from "../../api/industries/removeIndustryAssignment";

export function useIndustries({ prefill } = { prefill: false }) {
	const [searchIndustry, { data, loading: filterLoading, error }] = useLazyQuery(FILTER_INDUSTRIES);
	const [loading, setLoading] = useState(false);

	const [assignIndMutation, { data: assignData, loading: assignLoading, error: assignError }] =
		useMutation(ASSIGN_INDUSTRY);
	const [removeIndMutation, { data: removeData, loading: removeLoading, error: removeError }] =
		useMutation(REMOVE_INDUSTY_ASSIGNMENT);

	const [industires, setIndustries] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setLoading(filterLoading || assignLoading || removeLoading);
	}, [filterLoading, assignLoading, removeLoading]);

	useEffect(() => {
		if (prefill) {
			searchIndustry();
		}
	}, [prefill]);

	useEffect(() => {
		if (data && data.filterIndustries) {
			setIndustries(data.filterIndustries);
		}
	}, [data]);

	useEffect(() => {
		if (assignData && assignData.assignIndustry) {
			dispatch(setIndustry(assignData.assignIndustry));
		}
	}, [assignData, dispatch]);

	useEffect(() => {
		if (removeData && removeData.removeIndustyAssignment) {
			dispatch(setIndustry(removeData.removeIndustyAssignment));
		}
	}, [removeData, dispatch]);

	/**
	 * Will return industry list matching the NACIS number provided (like*)
	 * @param {6 digit id corresponding to the NACIS number} nacis
	 */
	const filterNaics = (naics) => {
		searchIndustry({
			variables: {
				naics: parseInt(naics),
			},
		});
	};

	/**
	 * Will reutrn industry list matching the typed name provided (*like*)
	 * @param {Industry Name} name
	 */
	const filterName = (name) =>
		searchIndustry({
			variables: {
				name,
			},
		});

	/**
	 * Will assign industry to the provided company and update the company state too.
	 * @param {compid} companyid
	 * @param {industryid} the id of the industry you're assigning. Dupes will be ignored
	 */
	const assignIndustry = ({ compid, industryid }) => {
		assignIndMutation({
			variables: {
				compid,
				industryid,
			},
		});
	};

	/**
	 * Will remove the industry assignement provided a valid compid, and industry id
	 * @param {compid} companyid
	 * @param {industryid} the id of the industry you're removing
	 */
	const removeIndustryAssignment = ({ compid, industryid }) => {
		removeIndMutation({
			variables: {
				compid,
				industryid,
			},
		});
	};

	return {
		industires,
		loading,
		error,
		filterName,
		filterNaics,
		assignIndustry,
		removeIndustryAssignment,
		removeError,
	};
}
