export const msalConfig = {
	auth: {
		clientId: process.env.REACT_APP_GRAPH_CLIENT_ID,
		redirectUri: process.env.REACT_APP_GRAPH_REDIRECT_URL,
		authority: `https://login.microsoft.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
};

export const loginRequest = {
	scopes: ["Calendars.Read", "Calendars.ReadWrite", "Email", "Mail.Read", "Mail.ReadWrite", "Mail.Send", "User.Read"],
};
