export const setCompany = (company) => {
	return {
		type: 'company/set',
		payload: company
	};
};

export const setIndustry = (industries) => ({
	type: 'company/set-industries',
	payload: industries
});

export const updateCompanyLocation = (location) => ({
	type: 'company/update-location',
	payload: location
});

export const removeCompanyLocation = (locid) => ({
	type: 'company/remove-location',
	payload: locid
});

export const setCompanyLocationNotes = ({ locid, notes }) => ({
	type: 'company/set-company-notes',
	payload: {
		locid,
		notes
	}
});

export const clearCompany = () => {
	return {
		type: 'company/clear'
	};
};
