import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { AiOutlinePlusSquare } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import { useContactNotes } from '../../hooks/contactNotes/useContactNotes';
import GenericTable from '../common/genericTable';
import ContactNoteForm from './ContactNoteForm';
import NewContactNoteModal from './NewContactNoteModal';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 75vw;
	${mediaQueries('md')`
        width: 90vw;
    `};
`;

const RowContainer = styled.div`
	display: flex;
	height: 75vh;
	${mediaQueries('md')`
        flex-direction: column;
    
    `};
`;

const TableContainer = styled.div`
	flex: .45;
	margin-right: ${baseSettings.small};
	${mediaQueries('md')`
        margin-bottom: ${baseSettings.small};
        flex: none;
        width: 80vw;
    `};
`;

const AddButton = styled(AiOutlinePlusSquare)`
    color: ${foregroundColor};
    font-size: 3.5rem;
    transition: scale 0.33s ease-out;
    --webkit-transition: scale 0.33s ease-out;
    &:hover {
        scale: 1.1;
        --webkit-animation:scale(1.1);
    }
`;

const ErrorMessage = styled.p`
	font-size: ${baseSettings.fontSmall};
	color: ${secondaryAccentColor};
`;

const NoNoteText = styled.p`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	text-align: center;
`;

const Form = styled(ContactNoteForm)`


`;

function ContactNotes() {
	const { contid, notes = [] } = useSelector((state) => state.contact);
	const { loading, error, addOrUpdateNote, removeNote } = useContactNotes({ contid });
	const [ selectedNote, setSelectedNote ] = useState(null);
	const [ newNoteOpen, setNewNoteOpen ] = useState(false);

	const columns = useMemo(
		() => [
			{
				Header: 'Note ID',
				accessor: 'cnoteid'
			},
			{
				Header: 'Type',
				accessor: 'type.type'
			},
			{
				Header: 'Date',
				accessor: (value) => (value.createdDate ? dayjs(value.createdDate).format('MM-DD-YY') : null)
			},
			{
				Header: 'typeid',
				accessor: 'type.typeid'
			},
			{
				Header: 'note',
				accessor: 'note'
			}
		],
		[ notes ]
	);

	const onAddNote = (values) => {
		addOrUpdateNote(values);
	};

	const onRemoveNote = ({ cnoteid = 0 }) => {
		removeNote({ cnoteid });
		setSelectedNote(null);
	};

	return (
		<Container>
			<RowContainer>
				<TableContainer>
					<AddButton onClick={() => setNewNoteOpen(true)} />
					{error && <ErrorMessage>{error.message}</ErrorMessage>}
					<NewContactNoteModal
						isOpen={newNoteOpen}
						toggleOpen={() => setNewNoteOpen(!newNoteOpen)}
						onSubmit={onAddNote}
					/>
					{notes.length === 0 && <NoNoteText>No notes exist for this contact.. 😭 Add some!</NoNoteText>}
					{notes.length !== 0 && (
						<GenericTable
							columns={columns}
							data={notes}
							width={'100%'}
							hiddenColumns={[ 'type.typeid', 'note', 'cnoteid' ]}
							onSelect={(values) => setSelectedNote(values)}
						/>
					)}
				</TableContainer>
				{selectedNote && <Form note={selectedNote} onSubmit={onAddNote} onDelete={onRemoveNote} />}
			</RowContainer>
		</Container>
	);
}

export default ContactNotes;
