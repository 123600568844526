import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import propTypes from 'prop-types';
import Select from './SelectField';
import { GET_COMPANY } from '../../api/companies/getCompany';



/**
 * Will handle controled input with a react hook form controller or state being passed in.
 * If state is used the control prop must be null and the location set to the object state
 * and setValue a method that will set the location state up the componenent tree
 * 
 */
function CompanyLocationSelect({ location, compid, control, accessor = 'locations', width = '100%', setValue }) {
	const { loading, error, data } = useQuery(GET_COMPANY, { variables: { compid }, fetchPolicy: 'no-cache' });

	const options = useMemo(
		() => {
			if (data && data.company && data.company.locations) {
				return data.company.locations.map(({ locid, address1 }) => ({
					label: address1,
					value: locid
				}));
			}
			return [];
		},
		[ data ]
	);

	const onInputChange = (value) => {
		if (value) {
		}
	};
	
	
	if (control) {
		return (
			<Controller
				name={accessor}
				control={control}
				render={(props) => {
					const option = options.find(({ value }) => value === props.value) || { value: '', label: '' };

					return (
						<Select
							text={'Location'}
							onInputChange={onInputChange}
							onChange={({ value }) => props.onChange(value)}
							options={options}
							loading={loading}
							width={width}
							value={{ label: `${option.label}`, value: `${option.value}` }}
							noOptionsMessage={'Location not found'}
						/>
					);
				}}
			/>
		);
	}

	return (
		<Select
			text={'Location'}
			onInputChange={onInputChange}
			onChange={({ value }) => setValue(data?.company?.locations?.find(({ locid }) => locid === value) ?? null)}
			options={options}
			loading={loading}
			width={width}
			value={options.find((option) => option.value === location?.locid || 0)}
			// value={{ label: `${option.label}`, value: `${option.value}` }}
			noOptionsMessage={'Location not found'}
		/>
	);
}

CompanyLocationSelect.prototype = {
	location: propTypes.object,
	compid: propTypes.number.isRequired,
	locid: propTypes.number,
	control: propTypes.object,
	setValue: propTypes.func,
	accessor: propTypes.string
};

export default CompanyLocationSelect;
