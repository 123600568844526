import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageBox from '../common/PageBox';
import ContactForm from './ContactForm';
import ContactSocialLinks from './ContactSocialMedia';
import ContactNotes from './ContactNotes';
import ContactMoreTab from './ContactsMoreTab';
import TabContainer from '../common/tabContainer';
import ActivityTab from './contact-activities/ActivityTab';
import { useContact } from '../../hooks/contacts/useContact';
import { baseSettings } from '../../styles/theme';

const BodyContainer = styled.div`
	display: flex;
	padding: ${baseSettings.small};
`;

function ContactDetail() {
	const { contid } = useParams();
	const history = useHistory();
	const { loading, error, contact, update } = useContact({ contid: parseInt(contid) });

	useEffect(
		() => {
			if (error && error.message === 'Error in Validation: Contact is not assigned to you') {
				// redirect back to previous page
				history.goBack();
			}
		},
		[ error ]
	);

	return (
		<PageBox>
			<BodyContainer>
				<TabContainer
					tabs={[
						{
							name: 'Details',
							child: (
								<ContactForm contact={contact} updateContact={update} loading={loading} error={error} />
							)
						},
						{
							name: 'Notes',
							child: <ContactNotes />
						},
						{
							name: 'Social Media',
							child: <ContactSocialLinks />
						},
						{
							name: 'Activities',
							child: <ActivityTab />
						},
						{
							name: 'More',
							child: <ContactMoreTab />
						}
					]}
				/>
			</BodyContainer>
		</PageBox>
	);
}

export default ContactDetail;
