import { gql } from '@apollo/client';

export const GET_NEARBYLOCATIONS = gql`
	query($startingLocation: CoordinateType!, $radius: Int) {
		nearbyLocations(startingLocation: $startingLocation, radius: $radius) {
			distance
			companyName
			compid
			location {
				locid
				address1
				city {
					cityid
					cityName
				}
				state
				zip
				longitude
				latitude
			}
		}
	}
`;
