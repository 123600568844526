import React from "react";
import "normalize.css"; // resets css
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./graph-config";
import { ApolloProvider } from "@apollo/client";
import configureStore from "./redux/configureStore";
import AppRouter from "./router/AppRouter";
import MyThemeProvider from "./styles/ThemeProvider";
import GraphProvider from "./provider/GraphProvider";
import { initUser } from "./api/user/tokenProvider";
import { client } from "./api/client";
import "./index.css";

export const store = configureStore();
const msalInstance = new PublicClientApplication(msalConfig);

const jsx = (
	<Provider store={store}>
		<ApolloProvider client={client}>
			<MyThemeProvider>
				<MsalProvider instance={msalInstance}>
					<AppRouter />
				</MsalProvider>
			</MyThemeProvider>
		</ApolloProvider>
	</Provider>
);

initUser();

ReactDOM.render(jsx, document.getElementById("root"));
