import React from 'react';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { CubeGrid } from 'better-react-spinkit';
import { Virtuoso } from 'react-virtuoso';
import styled from 'styled-components';
import { baseSettings, foregroundColor, lightforegroundColor, secondaryAccentColor } from '../../styles/theme';
import { useTheme } from '../../styles/ThemeProvider';
import { useIsMobile } from '../../hooks/design/useIsMobile';
import GenericTable from '../../components/common/genericTable';
import { GET_RECENT_REPAIRS } from '../../api/aesCompanies/getRecentRepairs';
import { serviceIDLink } from "../../router/ExternalRouteUrls"
const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	padding: ${baseSettings.xs};
	margin-bottom: ${baseSettings.xs};
	width: 100%;
`;
const HeaderRow = styled.div`
	display: flex;
	justify-content: space-between;
`;
const ServiceIDHeader = styled.a`
	font-size: ${baseSettings.fontMedium};
	color: ${foregroundColor};
	text-decoration: underline;
	margin-bottom: ${baseSettings.xs};	
`;
const DateHeader = styled.h5`
	margin: 0;
	color: ${secondaryAccentColor};
`;

const PNHeader = styled.h4`
	margin: 0;
	font-weight: 400;
`;
const ItemDescription = styled(PNHeader)`
    font-weight: 200;
    color: ${lightforegroundColor};
`;

const SerialNumber = styled.p`
	margin: 0;
	color: ${lightforegroundColor};
`;

const Quote = styled.p`
	font-size: ${baseSettings.fontMedium};
	font-weight: 600;
`;




const MobileRepairComponent = ({ serviceId, checkInDate, mfgPart, item, serialNumber, quote }) => {
	return (
		<ColumnContainer>
			<HeaderRow>
				<ServiceIDHeader href={`${serviceIDLink}/${serviceId}`} target="_blank">{serviceId}</ServiceIDHeader>
				<DateHeader>{checkInDate && dayjs(checkInDate).format('MM/DD/YY')}</DateHeader>
			</HeaderRow>
			<PNHeader>{mfgPart}</PNHeader>
			<ItemDescription>{item}</ItemDescription>
			<HeaderRow>
				<SerialNumber>SN: {serialNumber || 'None Listed'}</SerialNumber>
				<Quote>{quote && quote.quote ? `$${quote.quote}` : 'N/A'}</Quote>
			</HeaderRow>
		</ColumnContainer>
	);
};

function RecentRepairs() {
	const isMobile = useIsMobile();
	const themeData = useTheme();
	const { aescustid: custId = 0 } = useSelector((state) => state.company);
	const { data, loading } = useQuery(GET_RECENT_REPAIRS, {
		variables: {
			custId
		}
	});

	const repairs = React.useMemo(
		() => {
			if (data && data.aesCompany && data.aesCompany.recentRepairs) {
				return data.aesCompany.recentRepairs;
			}
			return [];
		},
		[ data ]
	);

	const columns = React.useMemo(
		() => [
			{
				Header: 'Service ID',
				accessor: 'serviceId'
			},
			{
				Header: 'Received Date',
				accessor: (data) => (data.checkInDate ? dayjs(data.checkInDate).format('MM/DD/YY') : '')
			},
			{
				Header: 'Part #',
				accessor: 'mfgPart'
			},
			{
				Header: 'Description',
				accessor: 'item'
			},
			{
				Header: 'Serial #',
				accessor: 'serialNumber'
			},
			{
				Header: 'Quote',
				accessor: 'quote.quote'
			}
		],
		[]
	);

	if (loading) {
		return (
			<div style={{ width: '100%', margin: '0 auto' }}>
				<CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />
			</div>
		);
	}

	if (isMobile) {
		return (
			<Virtuoso
				data={repairs}
				style={{ height: '600px', width: '100%' }}
				itemContent={(_, repair) => (
					<MobileRepairComponent
						style={{ height: '450px', width: '100%' }}
						checkInDate={repair.checkInDate}
						serviceId={repair.serviceId}
						mfgPart={repair.mfgPart}
						item={repair.item}
						serialNumber={repair.serialNumber}
						quote={repair.quote}
					/>
				)}
			/>
		);
	}


	return (
		<div>
			<GenericTable columns={columns} data={repairs} linkColumn={({route: serviceIDLink})} />
		</div>
	);
}

export default RecentRepairs;
