import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { ADD_OR_UPDATE_LOCATION } from '../../api/locations/updateOrAddLocation';
import { REMOVE_LOCATION } from '../../api/locations/removeLocation';
import { setLocation as setLocationAction } from '../../redux/actions/location';
import { updateCompanyLocation, removeCompanyLocation } from '../../redux/actions/company';

/**Will updated, delete, and add locations. All changes will be refelected to the application state*/
export function useLocation() {
	const [ updateOrAddLocation, { loading: updateLoading, error: updateError, data: updateData } ] = useMutation(
		ADD_OR_UPDATE_LOCATION
	);

	const [ remove, { loading: removeLoading, error: removeError, data: removeData } ] = useMutation(REMOVE_LOCATION);
	const stateLocation = useSelector((state) => state.location);
	const dispatch = useDispatch();
	const [ error, setError ] = useState(null);
	const [ changesSaved, setChangesSaved ] = useState(false);
	const [ location, setLocation ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	useEffect(
		() => {
			setLoading(updateLoading || removeLoading);
		},
		[ updateLoading, removeLoading ]
	);

	useEffect(() => {
		setLocation(stateLocation);
	}, []);

	useEffect(
		() => {
			if (updateData && updateData.saveLocation) {
				setError(null);
				setChangesSaved(true);
				setLocation(updateData.saveLocation);
				dispatch(updateCompanyLocation(updateData.saveLocation)); // update state
			}
		},
		[ updateData, dispatch ]
	);

	useEffect(
		() => {
			if (removeData && removeData.removeLocation) {
				setError(null);

				// update state to remove the delete location
				dispatch(removeCompanyLocation(removeData.removeLocation));
				setLocation(null);
				setChangesSaved(true);
			}
		},
		[ dispatch, removeData ]
	);

	useEffect(
		() => {
			setError(updateError || removeError);
		},
		[ updateError, removeError ]
	);

	useEffect(
		() => {
			if (changesSaved) {
				setChangesSaved(true);
				const interval = setInterval(() => {
					setChangesSaved(false);
				}, 5000);
				return () => clearInterval(interval);
			}
		},
		[ changesSaved ]
	);

	useEffect(
		() => {
			dispatch(setLocationAction(location));
		},
		[ location, dispatch ]
	);

	const updateLocation = (location) => {
		updateOrAddLocation({
			variables: {
				location
			}
		});
	};

	const removeLocation = (locid) => {
		remove({
			variables: {
				locid
			}
		});
	};

	const newLocation = () => {
		setLocation({
			locid: 0,
			address1: 'Enter Address',
			city: null,
			county: null,
			state: '',
			zip: '',
			phone: '',
			fax: ''
		});
	};

	return { loading, error, updateLocation, removeLocation, changesSaved, location, newLocation, setLocation };
}
