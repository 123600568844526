import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { foregroundColor, baseSettings } from "../../styles/theme";
import { NoControllerIndustrySelect } from "../companies/industrySelect";
import CompanyTypeSelect from "../companies/companyTypeSelect";
import { useEffect } from "react";
import { setNCIProps } from "../../redux/actions/nci";

const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const InputLabel = styled.label`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	margin-bottom: ${baseSettings.xs};
`;

const PotentialDiv = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${baseSettings.medium};
`;

const Input = styled.input`
	border-radius: 5px;
	border: none;
	border: 1px solid ${foregroundColor};
	color: ${foregroundColor};
	background: none;
	font-size: ${baseSettings.fontMedium};
	padding: 0.5rem;
	font-weight: 400;
	width: ${(props) => props.width};
`;

const HeaderText = styled.h2`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 600;
`;

const Description = styled.p`
	color: ${foregroundColor};
	font-weight: 300;
	font-size: ${baseSettings.fontMedium};
	text-align: left;
`;

const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	margin-top: 2vh;
`;

const potentialToNumber = (potential) => {
	if(!isNaN(potential)) {
		return Number(potential);
	}
	return 0;
}


function IndustryPage() {
	const dispatch = useDispatch();
	const { naics, typeid, potential } = useSelector((state) => state.nci);
	const [industryState, setIndustryState] = useState({
		naics: naics,
		typeid: typeid,
		potential,
	});

	useEffect(() => {
		setIndustryState({ naics, typeid });
	}, [naics, typeid]);

	useEffect(() => {
		dispatch(setNCIProps(industryState));
	}, [industryState]);

	return (
		<ColumnContainer>
			<HeaderText>Select the companies primary indulbstry</HeaderText>
			<Description>Type to filter</Description>
			<FormContainer>
				<NoControllerIndustrySelect
					value={industryState.naics}
					onChange={({ value }) => setIndustryState({ ...industryState, naics: value })}
				/>
				<Description>Please select the customer type</Description>
				<CompanyTypeSelect
					onChange={({ value }) => setIndustryState({ ...industryState, typeid: value })}
					value={industryState.typeid}
				/>
				<PotentialDiv>
					<InputLabel>Potential (Forecasted annual sales in USD)</InputLabel>
					<Input
						value={potential}
						onChange={(e) => setIndustryState({ ...industryState, potential: potentialToNumber(e.target.value) })}
						type={"number"}
						defaultValue={potential}
						name={"Potential (USD Annual Sales)"}
					/>
				</PotentialDiv>
			</FormContainer>
		</ColumnContainer>
	);
}

export default IndustryPage;
