import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { mediaQueries } from '../../styles/media';
import PageBox from '../common/PageBox';
import { baseSettings } from '../../styles/theme';
import { useGraph } from '../../provider/GraphProvider';
import TabContainer from '../common/tabContainer';
import SizedBox from '../common/SizedBox';
import SalesNumericMetrics from './salesNumericMetrics';
import SalesMonthlyGraph from './salesMonthlyGraph';
import SalesMonthGoalGraph from './salesMonthGoalGraph';
import MSProfileCard from '../common/MSProfileCard';

const GraphContainer = styled.div`margin-left: ${baseSettings.small};`;

const APIContainer = styled.div`
	display: flex;
	padding: ${baseSettings.small};
	width: 45vw;
	${mediaQueries('md')`
		width: 100%;
	
	`};
`;

const TestButton = styled.button``;

function DataboardPage() {
	const { username } = useSelector((state) => state.user);
	const graphData = useGraph();

	return (
		<PageBox>
			<APIContainer>
				<MSProfileCard />
			</APIContainer>
			<SalesNumericMetrics />
			<SizedBox bottom={baseSettings.small} />
			<GraphContainer>
				<TabContainer
					tabs={[
						{
							name: 'Current Month',
							child: <SalesMonthGoalGraph />
						},
						{
							name: 'Sales History',
							child: <SalesMonthlyGraph />
						}
					]}
				/>
			</GraphContainer>
		</PageBox>
	);
}

export default DataboardPage;
