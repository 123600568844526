import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import propTypes from 'prop-types';
import Select from './SelectField';
import { useCompanySearch } from '../../hooks/companies/useCompanySearch';

function CompanySelect({ company, setValue ,control, accessor = 'compid', width = '100%' }) {
	const { companies, error, loading, filterCompanies } = useCompanySearch();


	const options = useMemo(
		() =>
			companies.map(({ compid, companyname }) => ({
				label: companyname,
				value: compid
			})),
		[ companies ]
	);

	// useEffect(() => {
	// 	filterCompanies();
	// }, []);

	const onInputChange = (value) => {
		if (value) {
		}
	};


	if(control) {
		return (
			<Controller
				name={accessor}
				control={control}
				render={(props) => {
					const option = options.find(({ value }) => value === props.value) || { value: '', label: '' };
	
					return (
						<Select
							text={'Company'}
							onInputChange={onInputChange}
							onChange={({ value }) => props.onChange(value)}
							options={options}
							loading={loading}
							width={width}
							value={{ label: `${option.label}`, value: `${option.value}` }}
							noOptionsMessage={'Company not Found'}
						/>
					);
				}}
			/>
		);
	}

	return (
		<Select
			text={'Company'}
			onInputChange={onInputChange}
			onChange={({ value }) => setValue(companies.find(({ compid }) => compid === value))}
			options={options}
			loading={loading}
			width={width}
			value={options.find((option) => option.value === company?.compid || 0)}
			// value={{ label: `${option.label}`, value: `${option.value}` }}
			noOptionsMessage={'Company not found'}
		/>
	);

	
}

CompanySelect.prototype = {
	company: propTypes.object,
	setValue: propTypes.func,
	control: propTypes.object,
	accessor: propTypes.string
};

export default CompanySelect;
