import { gql } from '@apollo/client';

export const SEARCH_CONTACTS = gql`
	query($contid: Int, $firstName: String, $lastName: String, $state: String, $compid: Int, $pagination: PaginationArgs) {
		searchContacts(contid: $contid, firstName: $firstName, lastName: $lastName, state: $state, compid: $compid, pagination: $pagination) {
			contid
			firstName
			lastName
			location {
				state
				city {
					cityName
				}
			}
			email
			title
		}
	}
`;

export const SEARCH_CONTACTS_SLIM = gql`
	query($contid: Int, $firstName: String, $lastName: String, $state: String, $compid: Int) {
		searchContacts(contid: $contid, firstName: $firstName, lastName: $lastName, state: $state, compid: $compid) {
			contid
			firstName
			lastName
			company {
				companyname
			}
		}
	}
`;
