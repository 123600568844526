import React from 'react';
import styled from 'styled-components';
import { CubeGrid } from 'better-react-spinkit';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_COMPANY_DETAILS } from '../../api/aesCompanies/getCompanyDetails';
import PageBox from '../common/PageBox';
import { baseSettings, secondaryAccentColor } from '../../styles/theme';
import { useTheme } from '../../styles/ThemeProvider';
import CustomerDetailForm from './customerDetailForm';

const DetailsContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;
	margin-top: ${baseSettings.small};
`;

const LoadingGrind = styled(CubeGrid)`
    justify-self: center;
`;

const ErrorMessage = styled.h3`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.small};
	margin: 0;
`;

function AESCustomerDetailedPage() {
	const { custId } = useParams();
	const themeData = useTheme();
	const { loading, error, data } = useQuery(GET_COMPANY_DETAILS, {
		variables: {
			custId: parseInt(custId)
		}
	});

	if (loading) {
		return (
			<PageBox>
				<DetailsContainer>
					<LoadingGrind color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />
				</DetailsContainer>
			</PageBox>
		);
	}

	if (error) {
		return (
			<PageBox>
				<DetailsContainer>
					<ErrorMessage>{error.message}</ErrorMessage>
				</DetailsContainer>
			</PageBox>
		);
	}

	return (
		<PageBox>
			<CustomerDetailForm customer={data.aesCompany} />
		</PageBox>
	);
}

export default AESCustomerDetailedPage;
