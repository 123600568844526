export const setViewport = (viewPort) => ({
	type: "radar/set-viewport",
	payload: viewPort,
});

export const clearViewport = () => ({
	type: "radar/clear-viewport",
});

export const setLocation = (location) => ({
	type: "radar/set-location",
	payload: location,
});

export const clearLocation = () => ({
	type: "radar/clear-location",
});

export const setControlState = (controlState) => ({
	type: "radar/set-control-state",
	payload: controlState,
});

export const clearControlState = () => ({
	type: "radar/clear-control-state",
});

export const toggleGeolocation = () => ({
	type: "radar/toggle-geolocate",
});
