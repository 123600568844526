import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import theme from 'styled-theming';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { useForm } from 'react-hook-form';
import { mediaQueries } from '../../styles/media';
import { modalLight, primaryColor, baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';
import Modal from '../common/modalContainer';
import Input from '../common/InputField';
import ThemeButton from '../common/ThemeButton';
import SizedBox from '../common/SizedBox';
import CompanyLocationSelect from '../common/companyLocationSelect';
import { ADD_CONTACT } from '../../api/contacts/addContact';

const ModalContainer = styled.form`
	align-items: center;
	border-radius: 25px;
	/* border: 2px solid orange; */
	border: ${theme('mode', {
		dark: 'none',
		light: '2px solid #00334e'
	})};
	background: ${modalLight};
	box-shadow: 1px 1px 8px 5px ${primaryColor};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 60%;
	width: 25%;
	padding: ${baseSettings.small};
	${mediaQueries('md')`
    overflow: scroll;
    height: 80%;
    width: 80%;	

`};
`;

const Header = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontLarge};
	font-weight: 500;
	text-align: center;
`;

const Error = styled.p`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.xs};
`;

const Container = animated(ModalContainer);

function NewContactModal({ isOpen, toggleModal }) {
	const props = useSpring({
		to: { transform: isOpen ? 'scale(1.2)' : 'scale(0)', opacity: isOpen ? 1 : 0 },
		config: { duration: 300, mass: 1, tension: 180, friction: 12 },
		from: { opacity: 0, transform: 'scale(0)' }
	});

	const [ add, { loading, error, data } ] = useMutation(ADD_CONTACT);
	const history = useHistory();

	const { compid = 0 } = useSelector((state) => state.company);

	const [ location, setLocation ] = useState(null);

	const { handleSubmit, register } = useForm();

	const addContact = (values) => {
		add({
			variables: {
				contactArgs: {
					...values,
					compid,
					locid: location.locid || 0
				}
			}
		});
	};

	useEffect(
		() => {
			if (data && data.addContact) {
				history.push(`/contacts/${data.addContact.contid}`);
			}
		},
		[ data ]
	);

	return (
		<Modal isOpen={isOpen} toggleModal={toggleModal}>
			<Container styles={props} onSubmit={handleSubmit(addContact)}>
				<Header>New Contact 🧑</Header>
				<Input
					name="firstName"
					autofocus={true}
					register={register({ required: true })}
					text="First Name"
					width={'100%'}
				/>
				<Input name="lastName" register={register({ required: true })} text="Last Name" width={'100%'} />
				<Input
					name="email"
					register={register({
						pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
					})}
					text="Email"
					width={'100%'}
				/>
				<CompanyLocationSelect
					location={location}
					compid={compid}
					setValue={(value) => {
						setLocation(value);
					}}
				/>
				{error ? <Error>{error.message}</Error> : <SizedBox bottom={baseSettings.medium} />}
				<ThemeButton variant="secondary" scaleEffect={false} onClick={toggleModal}>
					Cancel
				</ThemeButton>
				<SizedBox bottom={baseSettings.xs} />
				<ThemeButton type="submit" fontWeight={'500'} scaleEffect={false} disabled={!location}>
					Add Contact
				</ThemeButton>
			</Container>
		</Modal>
	);
}

export default NewContactModal;
