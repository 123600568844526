import { gql } from '@apollo/client';

export const ASSIGN_INDUSTRY = gql`
	mutation($industryid: Int!, $compid: Int!) {
		assignIndustry(industryid: $industryid, compid: $compid) {
			industryid
			industryName
			naics
		}
	}
`;
