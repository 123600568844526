import React from 'react';
import { bool } from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import { GoGraph } from 'react-icons/go';
import { MdPeopleOutline } from 'react-icons/md';
import { BiMoon, BiBuilding, BiRadar } from 'react-icons/bi';
import { BsSun, BsClockHistory } from 'react-icons/bs';
import { useTheme } from '../../styles/ThemeProvider';
import MenuItem from './menuItem';
import Profile from './profile';
import SizedBox from '../common/SizedBox';
import { baseSettings, primaryColorDarker, primaryLighterColor, foregroundColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';

const StyledMenu = styled.nav`
	align-items: stretch;
	background: ${theme('mode', {
		light: '#145374',
		dark: primaryColorDarker
	})};
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-height: 100vh;
	padding-top: 5%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
	${mediaQueries('md')`
		padding-top: 15%;
		width: 100%;
    `};
	transition: transform 0.3s ease-in-out;
	z-index: 10;
`;

const HeaderDash = styled.div`
	background-color: ${primaryLighterColor};
	height: 2px;
	width: 100%;
	margin-bottom: ${baseSettings.small};
`;

const ToggleDiv = styled.div`
	display: flex;
	justify-content: start;
	padding: ${baseSettings.small} ${baseSettings.small};
	position: absolute;
	bottom: 0;
	width: 100%;
`;

const LightToggleButton = styled(BiMoon)`
	color: white;
	font-size: ${baseSettings.medium};
	transition: scale 0.33s ease-out;
	--webkit-transition: scale 0.33s ease-out;
	&:hover {
		scale: 1.2;
		--webkit-transform:scale(1.1);
	}

`;

const DarkToggleButton = styled(BsSun)`
	color: ${foregroundColor};
	font-size: ${baseSettings.medium};
	transition: scale 0.33s ease-out;
	-webkit-transition: scale 0.33s ease-out;
	&:hover {
		scale: 1.2;
		-webkit-transform:scale(1.1);
	}
`;

function Menu({ open }) {
	const theme = useTheme();

	return (
		<StyledMenu open={open}>
			<Profile />
			<HeaderDash />
			<MenuItem to="/dashboard" icon={GoGraph}>
				Dashboard
			</MenuItem>
			<MenuItem to="/companies" icon={BiBuilding}>
				Companies
			</MenuItem>
			<MenuItem to="/contacts" icon={MdPeopleOutline}>
				Contacts
			</MenuItem>
			<MenuItem to="/activities" icon={BsClockHistory}>
				Activities
			</MenuItem>
			<MenuItem to="/customer-radar" icon={BiRadar}>
				Radar
			</MenuItem>
			<ToggleDiv>
				{theme.theme === 'light' ? (
					<LightToggleButton onClick={() => theme.toggle()} />
				) : (
					<DarkToggleButton onClick={() => theme.toggle()} />
				)}
			</ToggleDiv>
		</StyledMenu>
	);
}

Menu.propTypes = {
	open: bool.isRequired
};

export default Menu;
