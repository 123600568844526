import React, { useMemo } from 'react';
import { CubeGrid } from 'better-react-spinkit';
import styled from 'styled-components';
import { useContactFilter } from '../../hooks/contacts/useContactFilter';
import { foregroundColor, baseSettings } from '../../styles/theme';
import ContactTable from '../common/genericTable';
import { Virtuoso } from 'react-virtuoso';
import MobileContact from '../contacts/MobileContact';
import { useTheme } from '../../styles/ThemeProvider';
import { useIsMobile } from '../../hooks/design/useIsMobile';

const NoContactsFoundMessage = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontLarge};
	text-align: center;
	font-weight: 500;
`;

// Mobile Setup //

const Component = (props) => {
	const isMobile = useIsMobile();
	return isMobile ? (
		<Virtuoso
			style={{ height: '650px' }}
			data={props.contacts}
			itemContent={(_, contact) => (
				<MobileContact
					contid={contact.contid}
					firstName={contact.firstName}
					lastName={contact.lastName}
					title={contact.title}
				/>
			)}
		/>
	) : (
		<ContactTable
			data={props.contacts}
			columns={props.columns}
			hiddenColumns={[ 'contid' ]}
			navColumn={{
				route: '/contacts'
			}}
		/>
	);
};

function ContactGrid() {
	const { contacts, error, loading, searchContacts } = useContactFilter();
	const themeData = useTheme();
	const columns = useMemo(
		() => [
			{
				Header: 'Cont ID',
				accessor: 'contid'
			},
			{
				Header: 'First Name',
				accessor: 'firstName'
			},
			{
				Header: 'Last Name',
				accessor: 'lastName'
			},
			{
				Header: 'Title',
				accessor: 'title'
			},
			{
				Header: 'City',
				accessor: 'location.city.cityName'
			},
			{
				Header: 'State',
				accessor: 'location.state'
			}
		],
		[]
	);

	if (loading) {
		return <CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />;
	}

	return contacts.length > 0 ? (
		<Component contacts={contacts} columns={columns} />
	) : (
		<NoContactsFoundMessage>No contacts found 😭</NoContactsFoundMessage>
	);
}

export default ContactGrid;
