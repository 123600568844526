import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_NEARBYLOCATIONS } from '../../api/geo/nearbyLocations';

export function useNearbyLocation() {
	const [ query, { loading, error, data } ] = useLazyQuery(GET_NEARBYLOCATIONS);
	const [ locations, setLocations ] = useState([]);

	useEffect(
		() => {
			if (data && data.nearbyLocations) {
				setLocations(data.nearbyLocations);
			} else {
				setLocations([]);
			}
		},
		[ data ]
	);

	/**
     * Will take a given long and lat coord and return all near customer locations given the radius from the user
     * @param {float} longitude
     * @param {float} latitude
     * @param {radius} int in miles to search in a radius
     */
	const getLocations = ({ longitude, latitude, radius = 50 }) => {
		if (!typeof longitude === 'number' || !typeof latitude === 'number') {
			throw Error('invalid coords passed');
		}

		query({
			variables: {
				startingLocation: {
					longitude,
					latitude
				},
				radius
			}
		});
	};

	return { loading, error, getLocations, locations };
}
