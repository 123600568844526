import { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { GET_CONTACT } from "../../api/contacts/getContact";
import { UPDATE_CONTACT } from "../../api/contacts/updateContact";
import { DUPLICATE_CONTACT } from "../../api/contacts/duplicateContact";
import { useDispatch, useSelector } from "react-redux";
import { setContact } from "../../redux/actions/contact";

export function useContact({ contid }) {
	const {
		loading: getLoading,
		error: getError,
		data: getData,
	} = useQuery(GET_CONTACT, {
		variables: { contid },
		fetchPolicy: "no-cache",
	});
	const [updateContact, { loading: updateLoading, error: updateError, data: updateData }] = useMutation(UPDATE_CONTACT);

	const [dupe, { loading: dupeLoading, error: dupeError, data: dupeData }] = useMutation(DUPLICATE_CONTACT);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const contact = useSelector((state) => state.contact);

	useEffect(() => setLoading(getLoading), [getLoading]);
	useEffect(() => setLoading(updateLoading), [updateLoading]);
	useEffect(() => setLoading(dupeLoading), [dupeLoading]);

	useEffect(() => setError(getError), [getError]);
	useEffect(() => setError(updateError), [updateError]);
	useEffect(() => setError(dupeError), [dupeError]);

	useEffect(() => {
		if (getData) {
			//dispatch
			dispatch(setContact(getData.contact || {}));
		}
	}, [getData]);

	useEffect(() => {
		if (updateData) {
			dispatch(setContact(updateData.updateContact || {}));
		}
	}, [updateData]);

	useEffect(() => {
		if (dupeData) {
			// navigate to new contact page
			history.push(`/contacts/${dupeData.duplicateContact.contid}`);
		}
	}, [dupeData]);

	/**
	 * Will update the contact
	 * Only include the fields you wish to update
	 */
	const update = ({ extension, ...rest }) => {
		let ext = null;
		if (typeof extension === "number") {
			ext = extension;
		}
		updateContact({
			variables: {
				updateData: { extension: ext, ...rest },
			},
		});
	};

	/**
	 * Will duplicate a contact provided their contact id to the same company and location.
	 * will redirect to that contact page following the update.
	 */
	const duplicate = () => {
		if (typeof contid !== "number") {
			throw new Error("invalid contactId param, must be number");
		}
		dupe({
			variables: {
				contactId: contid,
			},
		});
	};

	return { loading, error, contact, update, duplicate };
}
