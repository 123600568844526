


export const setPageAction = (pageNumber = 0) => ({
	type: 'table/set-page',
	payload: pageNumber
})

export const clearPageAction = () => ({
	type: 'table/reset'
})
