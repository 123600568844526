import { gql } from '@apollo/client';

export const GET_CITIES = gql`
	query {
		cities {
			cityid
			cityName
		}
	}
`;
