import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { MONTHLY_METRICS } from '../../api/metrics/dashboardMertics';

export function useMonthlySales() {
	const { loading, error, data } = useQuery(MONTHLY_METRICS);
	const [ metrics, setMetrics ] = useState(null);
	const [ seriesData, setSeriesData ] = useState(null);

	useEffect(
		() => {
			if (data && data.monthlySalesMetrics) {
				setMetrics(data.monthlySalesMetrics);
			}
		},
		[ data ]
	);

	useEffect(
		() => {
			if (metrics) {
				formatSeriesData(metrics);
			}
		},
		[ metrics ]
	);

	const mapAbrToMonth = (abbr) => {
		switch (abbr) {
			case 'jan':
				return '01';
			case 'feb':
				return '02';
			case 'march':
				return '03';
			case 'april':
				return '04';
			case 'may':
				return '05';
			case 'jun':
				return '06';
			case 'july':
				return '07';
			case 'aug':
				return '08';
			case 'sept':
				return '09';
			case 'oct':
				return '10';
			case 'nov':
				return '11';
			case 'dec':
				return '12';

			default:
				return '01';
		}
	};

	/**
     * Takes array of data [jan: 100, feb: 50] and outputs
     * {label: year, data: [{x: 01, y: 100}, {x: 02, y: 50}]
     * @param {*} metricies data 
     */
	const formatSeriesData = (data) => {
		const dataArray = [];

		data.forEach((yearRow) => {
			const yearSeries = [];
			Object.keys(yearRow).forEach((key) => {
				if (key !== 'seriesId' && key !== '__typename') {
					yearSeries.push({
						x: mapAbrToMonth(key),
						y: yearRow[key]
					});
				}
			});
			dataArray.push({
				label: yearRow.seriesId,
				data: yearSeries
			});
		});
		setSeriesData(dataArray);
	};

	return { loading, error, metrics, seriesData };
}
