/* eslint-disable import/no-anonymous-default-export */
import { createStore, combineReducers, compose } from "redux";
import { userReducer } from "./reducers/user";
import { companyReducer } from "./reducers/company";
import { companyFilterReducer } from "./reducers/companyFilter";
import { contactFilterReducer } from "./reducers/contactFilter";
import { contactReducer } from "./reducers/contactReducer";
import { locationReducer } from "./reducers/location";
import { newActivityReducer } from "./reducers/newActivityReducer";
import { nciReducer } from "./reducers/nciReducer";
import { radarReducer } from "./reducers/radar";
import { tableReducer } from "./reducers/tableReducer";

const composeEnhancers = (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default () => {
	const store = createStore(
		combineReducers({
			user: userReducer,
			company: companyReducer,
			location: locationReducer,
			companyFilters: companyFilterReducer,
			contactFilters: contactFilterReducer,
			contact: contactReducer,
			newActivity: newActivityReducer,
			radar: radarReducer,
			nci: nciReducer,
			table: tableReducer
		}),
		composeEnhancers()
	);

	return store;
};
