import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { BiFilter, BiMessageSquareAdd } from 'react-icons/bi';
import PageBox from '../common/PageBox';
import CompaneisGrid from './companiesGrid';
import FilterModal from './filterModal';
import NewCompanyModal from '../common/CommonInputModal';
import TabContainer from '../common/tabContainer';
import AesCompaniesGrid from '../companies/aesCompaniesGrid';
import { ADD_COMPANY } from '../../api/companies/addCompany';
import { baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';
import { isFilterApplied } from '../../redux/reducers/companyFilter';
import SizedBox from '../common/SizedBox';
import LimitDropdown from "./LmitDropdown";

const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
`;

const CompanyPageBody = styled(PageBox)`
	
`;

const ToolBarRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
`;

const ToolBarContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex-wrap: nowrap;
	padding: 0 0 ${baseSettings.xs} 0;
`;

const StyledFilterIcon = styled(BiFilter)`
    color: ${(props) => (props.filterApplied ? 'white' : secondaryAccentColor)};
	background: ${(props) => (props.filterApplied ? secondaryAccentColor : 'transparent')};
	border-radius: 5px;
    font-size: 5rem;
    transition: scale 0.33s ease-out;
    --webkit-transition: scale 0.33s ease-out;

    &:hover {
        scale: 1.2;
        --webkit-transform: scale(1.1);
    }
`;

const StyledAddCompanyIcon = styled(BiMessageSquareAdd)`
    color: ${foregroundColor};
    font-size: 5rem;
    transition: scale 0.33s ease-out;
    --webkit-transition: scale 0.33s ease-out;

    &:hover {
        scale: 1.2;
        --webkit-transform: scale(1.1);
    }
`;


const CompanyTabContainer = styled(TabContainer)`
	margin: 150px;
`;

const AddError = styled.p`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
	font-weight: 400;
	margin-right: ${baseSettings.large};
`;

function CompaniesPage() {
	const filterState = useSelector((state) => state.companyFilters);
	const [ filterOpen, setFilterOpen ] = useState(false);
	const [ newCompanyOpen, setNewCompanyOpen ] = useState(false);
	const [ addCompanyMutation, { loading: addLoading, data: addData, error: addError } ] = useMutation(ADD_COMPANY);
	const history = useHistory();

	useEffect(
		() => {
			if (addData && addData.addCompany) {
				history.push(`/companies/${addData.addCompany.compid}`);
			}
		},
		[ addData ]
	);

	const AddCompany = (companyName) => {
		addCompanyMutation({
			variables: {
				companyName
			}
		});
	};

	return (
		<CompanyPageBody>
			<SizedBox top={baseSettings.small} left={baseSettings.small} />
			<CompanyTabContainer
				width={'95%'}
				tabs={[
					{
						name: 'Your Companies',
						child: (
							<BodyContainer>
								<ToolBarRow>
									<LimitDropdown />
									<ToolBarContainer>
										{addError && <AddError>{addError.message}</AddError>}
										<StyledAddCompanyIcon onClick={() => setNewCompanyOpen(true)} />
										<SizedBox right={baseSettings.small} />
										<StyledFilterIcon
											onClick={() => setFilterOpen(!filterOpen)}
											filterApplied={isFilterApplied(filterState)}
										/>
									</ToolBarContainer>
								</ToolBarRow>
								<CompaneisGrid />
								<FilterModal isOpen={filterOpen} toggleModal={() => setFilterOpen(!filterOpen)} />
								<NewCompanyModal
									isOpen={newCompanyOpen}
									InputText={'Enter New Company Name'}
									OnAccept={AddCompany}
									OnCancel={() => null}
									AcceptButtonText={'Create'}
									toggleOpen={() => setNewCompanyOpen(!newCompanyOpen)}
								/>
							</BodyContainer>
						)
					},
					{
						name: 'System Companies',
						child: <AesCompaniesGrid />
					}
				]}
			/>
		</CompanyPageBody>
	);
}

export default CompaniesPage;
