import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { accentColor, baseSettings } from '../../styles/theme';

const Nav = styled(NavLink)`
    text-decoration: none;
    text-transform: uppercase;

`;

const Container = styled.div`display: flex;`;

const NotificationText = styled.h3`
	color: ${accentColor};
	margin: 0;
	padding: 0;
	margin-top: ${baseSettings.xs};
`;

/**
 * if converted will be a nav link to allow navigation to the associated sales company for the customer.
 * @param {number} compid : The ID of the sales company that this derived from
 */
function SalesCompanyNav({ compid }) {
	return (
		<Nav to={`/companies/${compid}`}>
			<Container>
				<NotificationText>- Company Info -</NotificationText>
			</Container>
		</Nav>
	);
}

SalesCompanyNav.props = {
	compid: PropTypes.number.isRequired
};

export default SalesCompanyNav;
