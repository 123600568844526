import React from 'react';
import { useLazyQuery } from "@apollo/client"
import { GetContactActivity } from "../../api/activities/getContactActivity"
import { useState } from 'react/cjs/react.production.min';


export const useContactActivities = ({contid}) => {
    const [get, {loading, error, data}] = useLazyQuery(GetContactActivity);
    const [activities, setActivities] = React.useState([])

    React.useEffect(() => {
        if(data?.contactActivities) {
            setActivities(data.contactActivities)
        }
    },[data])

    React.useEffect(() => {
        if(!isNaN(contid)) {
            getActivities({contid})
        }
    }, [contid])


    


    const getActivities = ({contid, gt = undefined, lt = undefined}) => {


        get({variables: {
            filter: {
                contid,
                gt,
                lt
            }
        }})
    }



    return {getActivities, loading, error, activities}



}

