import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { foregroundColor, baseSettings } from '../../styles/theme';

const ContactDiv = styled(NavLink)`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	margin: ${baseSettings.xs} 0;
	text-decoration: none;
	min-height: 10vh;
text-decoration: none;
`;

const ContactDivHeader = styled.h3`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.small};
	font-weight: 800;
`;

const ContactDivText = styled(ContactDivHeader)`
	font-weight: 300;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	opacity: 0.7;
`;

const ContactDivRow = styled.div`
	align-items: center;
	display: flex;
	margin-top: 2px;
`;

export default function MobileContact(props) {
	const { contid = 0, firstName = '', lastName = '', title = '' } = props;

	return (
		<ContactDiv to={`/contacts/${contid}`}>
			<ContactDivRow>
				<ContactDivHeader>
					{firstName} {lastName}
				</ContactDivHeader>
			</ContactDivRow>
			<ContactDivRow>
				<ContactDivText>{title}</ContactDivText>
			</ContactDivRow>
		</ContactDiv>
	);
}
