import React from 'react';
import styled from 'styled-components';
import { CubeGrid } from 'better-react-spinkit';
import { useParams } from 'react-router-dom';
import { useRepairMetrics } from '../../hooks/repairs/useRepairMetrics';
import PageContainer from '../common/PageContainer';
import PageBox from '../common/PageBox';
import GenericTable from '../common/genericTable';
import { foregroundColor, baseSettings } from '../../styles/theme';
import { useTheme } from '../../styles/ThemeProvider';
import { serviceIDLink } from "../../router/ExternalRouteUrls"

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const TableHeader = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontLarge};
	margin-left: ${baseSettings.small};
`;

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: ${baseSettings.medium};
	justify-content: center;
	width: 100%;
`;

function MetricList() {
	const { type } = useParams();
	const themeData = useTheme();
	const { loading, columns, rows } = useRepairMetrics({ type });

	const header = () => {
		switch (type) {
			case 'InProgress':
				return 'Repairs In Progress 🕐';
			case 'WAPP':
				return 'Orders Awaiting Approval 💲';
			case 'Late':
				return 'Late Items ⌚';
			default:
				return 'Month Shipped 📅';
		}
	};

	return (
		<PageContainer>
			<PageBox>
				<TableContainer>
					<TableHeader>{header()}</TableHeader>
					{loading ? (
						<LoadingContainer>
							<CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />
						</LoadingContainer>
					) : (
						<GenericTable columns={columns} data={rows} linkColumn={({route: serviceIDLink})} />
					)}
				</TableContainer>
			</PageBox>
		</PageContainer>
	);
}

export default MetricList;
