import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import PhoneInput from "react-phone-input-auto-format"
import { useDispatch } from "react-redux"
import InputField from '../common/InputField';
import { CubeGrid } from "better-react-spinkit"
import { IoMdCall } from "react-icons/io"
import { FcPhoneAndroid } from "react-icons/fc"
import { baseSettings, foregroundColor, secondaryAccentColor, accentColor } from '../../styles/theme';
import { useTheme } from '../../styles/ThemeProvider';
import { callContact } from "../../redux/actions/newActivity"
import { mediaQueries } from '../../styles/media';
import ThemeButton from '../common/ThemeButton';
import ChangeLocationModal from './ContactLocationModal';
const Form = styled.form`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
`;

const FormRow = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: row;
	${mediaQueries('md')`
		flex-direction: column;
	`};
`;

const AdaptiveBox = styled.div`
	margin-right: ${baseSettings.small};
	${mediaQueries('md')`
        margin-right: 0;
    `};
`;

const PhoneInputField = styled.input`
	border-radius: 5px;
	border: none;
	border: 1px solid ${foregroundColor};
	color: ${foregroundColor};
	background: none;
	padding: 0.5rem;
	font-weight: 400;
	font-size: ${baseSettings.fontMedium};
	
`;

const Error = styled.p`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
	text-align: center;
	font-weight: 300;
	margin: ${baseSettings.small};
`

const CompanyLink = styled(Link)`
	
`;

const CompanyBox = styled.div`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	margin-top: ${baseSettings.small};
	width: 100%;
`;

// Tool Bar //
const ToolBarRow = styled.div`
	display: flex;
	
`

const CallDiv = styled.a`
	align-items: center;
	border: 1px solid #03c4a1;
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	padding-right: ${baseSettings.xs};
	transition: scale 0.33s ease-out;
	text-decoration: none;
	--webkit-transition: scale 0.33s ease-out;
	&:hover {
		scale: 1.1;
		--webkit-transition: scale(1.1);
	}
`

const CallText = styled.p`
	color: ${props => props.variant === 'secondary' ? "#FD4E8D" : "#03c4a1"};
	font-size: ${baseSettings.fontMedium};
`

const CallIcon = styled(IoMdCall)`
	color: ${accentColor};
	font-size: ${baseSettings.fontHuge};
`

const MobileCallIcon =  styled(FcPhoneAndroid)`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontHuge};

`

const VerticalInputContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 250px;
`;



const InputHeader = styled.p`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
`;


const formatPhoneNumber = (phone) => phone.replace('(', '').replace(')','-').replace(/\s+/g, '')

function ContactForm({ contact, updateContact, error, loading }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const [ changeCompanyOpen, setChangeCompanyOpen ] = useState(false);
	const { control, register, handleSubmit, reset, formState } = useForm({ defaultValues: { ...contact } });
	const theme = useTheme();

	// has to be done with hook forms https://react-hook-form.com/api/#reset
	useEffect(
		() => {
			reset(contact);
		},
		[ contact, reset ]
	);

	const dirtyFieldsCount = () => Object.keys(formState.dirtyFields).length;

	const onSubmit = (values) => {
		let updateObj = {}
		Object.keys(formState.dirtyFields).forEach(key => {
			if(key === 'extension' && values[key]) {
				updateObj[key] = parseInt(values[key])
			}
			else {
				updateObj[key] = values[key];

			}
		})
		updateObj["contid"] = contact.contid;

		updateContact(updateObj);
	}

	// When clicked will set activity state and nav to call page
	const onCall = () => {
		dispatch(callContact({contid: contact.contid, name: `${contact.firstName  || ''} ${contact.lastName || ''}`, company: contact.company.companyname}))
		history.push(`/schedule-activity/page/3`)
	}

	return (
		loading  ? <CubeGrid color={theme.theme === 'dark' ? 'white' : '#ee6f57'} size={45} /> : 
		<Form onSubmit={handleSubmit(onSubmit)}>
			<FormRow>
			{contact.phone && <ToolBarRow>
				<CallDiv onClick={onCall} href={`tel:${formatPhoneNumber(contact.phone)}${contact.extension ? ';' + contact.extension : ''}`}>
				<CallIcon />
				<CallText >{contact.phone || ''}</CallText>
				</CallDiv>
			</ToolBarRow>}
			<AdaptiveBox />
			{contact.cellphone && <ToolBarRow>
				<CallDiv onClick={onCall} href={`tel:${formatPhoneNumber(contact.cellphone)}`}>
				<MobileCallIcon />
				<CallText variant="secondary">{contact.cellphone || ''}</CallText>
				</CallDiv>
			</ToolBarRow>}

			</FormRow>
			<ChangeLocationModal
				isOpen={changeCompanyOpen}
				toggleOpen={() => setChangeCompanyOpen(!changeCompanyOpen)}
			/>
			<FormRow>
				<InputField name="firstName" register={register} text="First" />
				<AdaptiveBox />
				<InputField name="lastName" register={register} text="Last" />
				<AdaptiveBox />
				<InputField name="title" register={register} text="Title" />
				<AdaptiveBox />
			</FormRow>
			<FormRow>
				<InputField name="email" register={register()} text="Email" />
				
				<AdaptiveBox />
				<InputField name="department" register={register} text="Department" />
			</FormRow>
			<FormRow>
			<Controller
						name="phone"
						control={control}
						render={(({value, onChange}) => 
						<VerticalInputContainer>
						<InputHeader>Phone</InputHeader>
		
						<PhoneInput
								style={{width: "100%"}}
							value={value || ''}
							onChange={onChange} 
							inputComponent={PhoneInputField}
						/>
						</VerticalInputContainer>
						)}

					/>
			
					<AdaptiveBox />
					<InputField name="extension" register={register({pattern: /^\d{0,7}/})} text="Ext."  />
					<AdaptiveBox />
					
				</FormRow>
				<FormRow>
				<Controller
						name="cellphone"
						control={control}
						render={(({value, onChange}) => 
						<VerticalInputContainer>
						<InputHeader>Cell Phone:</InputHeader>
		
						<PhoneInput 
						style={{width: "100%"}}
							value={value || ''}
							onChange={onChange} 
							inputComponent={PhoneInputField}
						/>
						</VerticalInputContainer>
						)}

					/>
					<AdaptiveBox />
					<Controller
						name="fax"
						control={control}
						render={(({value, onChange}) => 
						<VerticalInputContainer>
						<InputHeader>Fax</InputHeader>
		
						<PhoneInput 
							value={value || ''}
							onChange={onChange} 
							inputComponent={PhoneInputField}
						/>
						</VerticalInputContainer>
						)}

					/>
				</FormRow>
			<FormRow>
				{dirtyFieldsCount() > 0 && (
					<ThemeButton type={'submit'} style={{ height: '45px', margin: `${baseSettings.small} 0` }} scaleEffect={false}>
						💾 {dirtyFieldsCount()} Changes?
					</ThemeButton>
				)}
			{error && <Error>{error.message}</Error>}
			</FormRow>
			<CompanyBox>
				<FormRow>
					<CompanyLink to={`/companies/${contact?.company?.compid}`}>
						<InputField name="company.companyname" register={register} text="Company" disabled={true} />
					</CompanyLink>

					<AdaptiveBox />
					<ThemeButton
						variant={'secondary'}
						type="button"
						scaleEffect={false}
						onClick={() => setChangeCompanyOpen(true)}
					>
						Change Company? 💭
					</ThemeButton>
				</FormRow>
				<FormRow>
					{/* specific location info here (NOTE these are not editable as they're dependent on company location) */}
					<InputField name="location.address1" register={register} text="Address" disabled={true} />
					<AdaptiveBox />
					<InputField name="location.city.cityName" register={register} text="City" disabled={true} />
					<AdaptiveBox />
					<InputField name="location.zip" register={register} text="Zip" disabled={true} />
				</FormRow>
				<FormRow>
					<InputField name="location.county.countyName" register={register} text="County" disabled={true} />
					<AdaptiveBox />
					<InputField name="location.state" register={register} text="State" disabled={true} />
					<AdaptiveBox />
					<InputField name="location.phone" register={register} text="Comp. Phone" disabled={true} />
				</FormRow>
			
			</CompanyBox>
		</Form>
	);
}

export default ContactForm;
