import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useParams } from 'react-router-dom';
import { useCompany } from '../../hooks/companies/useCompany';
import PageBox from '../common/PageBox';
import styled from 'styled-components';
import { baseSettings, foregroundColor, secondaryAccentColor, modalLight } from '../../styles/theme';
import CustIDBage from './CustIdBadge';
import TabContainer from '../common/tabContainer';
import CompanyLocationForm from './companyLocationForm';
import CompanyContacts from './companyContacts';
import IndustryAssignement from './industryAssignment';
import CompanyMoreTab from './companyMoreTab';
import RecentRepairs from './recentRepairs';

const CompanyHeader = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-left: ${baseSettings.xs};
	width: 100vw;
`;

const CompanyName = styled.h3`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontXL};
	font-weight: 500;
	margin: 0;
`;

const ErrorMessage = styled.p`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
	font-weight: 400;
	margin-right: ${baseSettings.large};
`;

const CompanyNameLoader = () => (
	<ContentLoader viewBox="0 0 350 75" backgroundColor={modalLight} >
		<rect x="0" y="0" rx="0" ry="0" width="300" height="25" />
		<rect x="0" y="35" rx="0" ry="0" width="150" height="15" />
	</ContentLoader>
);

const tabs = [
	{
		name: 'Location(s)',
		child: <CompanyLocationForm />
	},
	{
		name: 'Contacts',
		child: <CompanyContacts />
	},
	{
		name: 'Industries',
		child: <IndustryAssignement />
	},
	{
		name: 'Recent Repairs',
		child: <RecentRepairs />
	},
	{
		name: 'More',
		child: <CompanyMoreTab />
	}
];

function CompanyDetailPage() {
	const { id } = useParams();

	const { loading, error, company } = useCompany({ compid: parseInt(id) });

	return (
		<PageBox>
			<CompanyHeader>
				{error && <ErrorMessage>{error.message}</ErrorMessage>}
				{loading && !company && <div>loading.....</div>}
				{company && <CompanyName>{company.companyname}</CompanyName>}
				{company && company.aescustid && <CustIDBage aescustid={company.aescustid} />}
				<TabContainer tabs={tabs} />
			</CompanyHeader>
		</PageBox>
	);
}

export default CompanyDetailPage;
