import { gql } from '@apollo/client';

export const Contact = gql`
	fragment ContactParts on SalesContact {
		contid
		firstName
		lastName
		title
		email
		phone
		cellphone
		fax
		extension
		department
		notes {
			cnoteid
			createdDate
			note
			type {
				typeid
				type
			}
		}
		links {
			linkid
			site
			url
		}
		company {
			compid
			aescustid
			companyname
		}
		location {
			locid
			address1
			city {
				cityid
				cityName
			}
			state
			zip
			phone
			county {
				countid
				countyName
			}
			country {
				countid
				countryName
			}
		}
	}
`;
