import React from 'react';
import styled from 'styled-components';
import { ImCancelCircle } from 'react-icons/im';
import { useSelector, useDispatch } from 'react-redux';
import theme from 'styled-theming';
import {
	baseSettings,
	foregroundColor,
	lightforegroundColor,
	modalLight,
	accentColor,
	primaryColorDarker,
	secondaryAccentColor
} from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import ContactSelect from '../contacts/ContactSelect';
import { addActivityContact, removeActivityContact } from '../../redux/actions/newActivity';

const ContactDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 75vw;
`;

const Header = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontXL};
	font-weight: 600;
`;

const ToolTip = styled.h4`
	color: ${lightforegroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 300;
`;

const OptionContainer = styled.div`
	width: 25%;
	${mediaQueries('md')`
        width: 100%;
        `};
`;

export const AttendeeContainer = styled.div`
	display: flex;
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	flex-direction: column;
	overflow-y: auto;
	height: 45%;
	width: 45%;
	${mediaQueries('md')`
        width: 100%;
        max-height: 35%;
    `};
`;

const CancelButton = styled(ImCancelCircle)`
    color: ${accentColor};
    cursor: pointer;
    font-size: 3.0em;
    ${mediaQueries('md')`
        font-size: 2.0em;
    `};
    &:hover {
		color: ${secondaryAccentColor};
	}
`;

const ContactCard = styled.div`
	color: ${foregroundColor};
	border-bottom: 1px solid ${modalLight};
	padding: ${baseSettings.xs};
	display: flex;
	margin-bottom: 2px;
	flex-direction: row;
	width: 100%;
	transition: all 0.33s ease;
	&:hover {
		background: ${primaryColorDarker};
		color: white;
		--webkit-transition: all 0.33s ease;
	}
`;

const CardTextColumn = styled.div`
	display: flex;
	flex-direction: column;
	flex: 3;
`;
const CardButtonContainer = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
`;

const CardName = styled.h3`
	font-size: ${baseSettings.small};
	font-weight: 500;
	margin: 0;
	margin-bottom: ${baseSettings.xs};
	${mediaQueries('md')`
        font-size: ${baseSettings.xs};
    `};
`;

const CardCompany = styled.h5`
	font-size: ${baseSettings.xs};
	font-weight: 300;
	margin: 0;
`;

export const AttendeeCard = ({ name, company, contid, onRemove }) => (
	<ContactCard>
		<CardTextColumn>
			<CardName>{name}</CardName>
			<CardCompany>{company}</CardCompany>
		</CardTextColumn>
		<CardButtonContainer>
			<CancelButton onClick={() => onRemove(contid)} />
		</CardButtonContainer>
	</ContactCard>
);

function ContactPage() {
	const dispatch = useDispatch();
	const { participants = [] } = useSelector((state) => state.newActivity);

	const removeContact = (contid) => {
		dispatch(removeActivityContact({ contid }));
	};

	return (
		<ContactDiv>
			<Header>Who is Attending?</Header>
			<ToolTip>Select their name in the dropdown to add to meeting</ToolTip>
			<OptionContainer>
				<ContactSelect onSelectContact={(value) => dispatch(addActivityContact(value))} />
			</OptionContainer>
			<ToolTip>Your Attendees</ToolTip>
			<AttendeeContainer>
				{participants.map((participant) => (
					<AttendeeCard key={participant.contid} {...participant} onRemove={removeContact} />
				))}
			</AttendeeContainer>
		</ContactDiv>
	);
}

export default ContactPage;
