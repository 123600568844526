import dayjs from "dayjs";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { baseSettings, foregroundColor } from "../../../styles/theme";

const ActivityContainer = styled.div`
	display: flex;
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	margin-bottom: ${baseSettings.xs};
	padding: ${baseSettings.xs};
`;

const ActivityColumn = styled.div`
	display: flex;
	flex-direction: column;
`;

const ActivityHeader = styled.div`
	font-size: ${({ props }) => props?.fontSize ?? baseSettings.fontSmall};
	font-weight: ${({ props }) => props?.fontWeight ?? 400}; ;
`;

function MobileActivity({ activity }) {
	const history = useHistory();
	return (
		<ActivityContainer key={activity.actid} onClick={() => history.push(`/activities/${activity.actid}`)}>
			<ActivityColumn>
				<ActivityHeader>{dayjs(activity.date).format("MM/DD/YY hh:mm A")}</ActivityHeader>
				<ActivityHeader>{activity.type.type}</ActivityHeader>
			</ActivityColumn>
		</ActivityContainer>
	);
}

export default MobileActivity;
