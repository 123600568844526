import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PhoneInput from "react-phone-input-auto-format"
import { useForm, Controller } from 'react-hook-form';
import { mediaQueries } from '../../styles/media';
import { IoIosNavigate } from 'react-icons/io';
import { AiFillPlusCircle } from 'react-icons/ai';
import { useLocation } from "../../hooks/locations/useLocations"
import Select from '../common/SelectField';
import Input from '../common/InputField';
import CitySelect from '../common/citySelect';
import CountySelect from '../common/countySelect';
import StateSelect from '../common/stateSelect';
import SizedBox from '../common/SizedBox';
import ThemeButton from '../common/ThemeButton';
import TabContainer from "../common/tabContainer"
import CompanyNotes from './companyNotes'


import { accentColor, baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';

const CompanyFormContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 50vw;
	${mediaQueries('md')`
		width: 90vw;
    `};
`;
const SectionHeader = styled.h5`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
	text-align: left;
	margin: 0;
`;

const LocationForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FormRow = styled.div`
	display: flex;
	${mediaQueries('md')`
		flex-direction: column;
    `};
	width: 100%;
`;

const ButtonColumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const ToolBarContainer = styled.div`
	align-items: center;
	border: 1px solid ${foregroundColor};
	border-radius: 0 0 5px 5px;
	display: flex;
	padding: ${baseSettings.xs};
	width: 50%;
`;

const NavigateButton = styled(IoIosNavigate)`
	color: ${foregroundColor};
	cursor: pointer;
	font-size: 4rem;
	&:hover{
		color: ${accentColor};
	}

`;

const ErrorRow = styled.div`
	display: flex;
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.small};
	font-weight: 300;
`

const Announcement = styled.div`
	color: ${accentColor};
	font-size: ${baseSettings.fontMedium};
`

const NewLocationIcon = styled(AiFillPlusCircle)`
	color: ${foregroundColor};
	cursor: pointer;
	font-size: 4rem;
	&:hover{
		color: ${accentColor};
	}
`;
const VerticalInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: ${baseSettings.small};
	width: 250px;
`;



const InputHeader = styled.p`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
`;

const PhoneInputField = styled.input`
	border-radius: 5px;
	border: none;
	border: 1px solid ${foregroundColor};
	color: ${foregroundColor};
	background: none;
	padding: 0.5rem;
	font-weight: 400;
	font-size: ${baseSettings.fontMedium};
	
`;

function CompanyLocationForm() {
	const { compid, locations = [], website } = useSelector((state) => state.company);
	const { error, loading, updateLocation, removeLocation, changesSaved, location, newLocation, setLocation } = useLocation();
	const { register, control, setValue, getValues, handleSubmit, errors } = useForm({shouldUnregister: false});

	

	const removeSelectedLocation = () => {
		removeLocation(location.locid)
	}


	// will set locaiton state to first value and starts call chain
	useEffect(() => {
		if(locations.length > 0) {
			setLocation(locations[0]) 
		}
	},[locations])

	useEffect(
		() => {
			if (location) {
				setValue('website',website)
				Object.keys(location).forEach((key) => {
					if (key === 'city') {
						setValue('city', { value: location.city?.cityid, label: location.city?.cityName } || null);
					} else if (key === 'county') {
						setValue(
							'county',
							{ value: location.county?.countid, label: location.county?.countyName } || null
						);
					} else {
						 setValue(key, location[key]);
					}
						
				});
			}
			
		},
		[location ]
	);


	


	const onSaveLocation = (values) => {
		// construct object for update.
		values.locid = location.locid;
		values.compid = compid;

		 
		if (values.city && values.city.label) {
			
			values.city = {
				cityid: values.city.value,
				cityName: values.city.label
			};
		}
		else {
			values.city = null;
		}



		if (values.county && values.county.label) {
			values.county = {
				countid: values.county.value,
				countyName: values.county.label
			};
		}
		else {
			values.county = null;
		}


		delete values.notes; // will handle these seperatly
		delete values.__typename;

		updateLocation(values);
	};


	const onNavigate = () => {
		const url = `https://www.google.com/maps/dir/?api=1&destination=${
			encodeURIComponent(`${location.address1} ${(location.city && location.city.cityName) || ''} ${location.state}`)
		}}`
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}


	return (
		<CompanyFormContainer>
			<SectionHeader>Select Location 🏦</SectionHeader>
			<Select
				noOptionsMessage={'no location found'}
				options={locations.map(({ locid, address1 }) => ({ value: locid, label: address1 }))}
				value={location ? { value: location.locid, label: location.address1 } : null}
				onChange={({ value }) => {
					setLocation(locations.find((l) => l.locid === value));
				}}
			/>
			<ToolBarContainer>
				<NewLocationIcon onClick={newLocation} />
				<SizedBox right={baseSettings.small} />
				<NavigateButton onClick={onNavigate} />

			</ToolBarContainer>
			<SizedBox bottom={baseSettings.small}/>

			<TabContainer variant={'secondary'} tabs={[{
				name: "Address",
				child: (<LocationForm onSubmit={handleSubmit(onSaveLocation)}>
				<FormRow>
					<Input register={register({required: true})} name={'address1'} text={'Address'} />
					<CitySelect control={control} creatable={true} />
				</FormRow>
				<FormRow>
					<CountySelect control={control} creatable={true} />
					<SizedBox right={baseSettings.small} />
					<StateSelect control={control} />
				</FormRow>
				<FormRow>
					<Input register={register} name={'zip'} text={'Postal Code'} />
		


					<Input register={register} name={'website'} text={'Website'} />
				</FormRow>
				<FormRow>
					<Controller
						name="phone"
						control={control}
						render={(({value, onChange}) => 
						<VerticalInputContainer>
						<InputHeader>Phone</InputHeader>
		
						<PhoneInput 
							value={value || ''}
							onChange={onChange} 
							inputComponent={PhoneInputField}
						/>
						</VerticalInputContainer>
						)}

					/>
					<Controller
						name="fax"
						control={control}
						render={(({value, onChange}) => 
						<VerticalInputContainer>
						<InputHeader>Fax</InputHeader>
		
						<PhoneInput 
							value={value || ''}
							onChange={onChange} 
							inputComponent={PhoneInputField}
						/>
						</VerticalInputContainer>
						)}

					/>
				
				
				</FormRow>
				<SizedBox top={baseSettings.small} />
				<FormRow>
					<ButtonColumn>
						{errors && Object.keys(errors).length > 0 && <ErrorRow>Invalid Entry: {Object.keys(errors).map(key => <div key={key}>{key},</div>)}</ErrorRow>}
						{error && <SectionHeader style={{ textAlign: 'center' }}>{error.message}</SectionHeader>}
						{changesSaved && <SectionHeader style={{ textAlign: 'center' }}>Location Updated</SectionHeader>}
						<SizedBox top={baseSettings.xs} />

						{location && <ThemeButton variant={'secondary'} type={'button'} scaleEffect={false} style={{width: '150px'}} onClick={removeSelectedLocation}>
							Delete 💣
						</ThemeButton> }

						<SizedBox top={baseSettings.xs} />
					
						{location && <ThemeButton type={'submit'} scaleEffect={false} loading={loading}>
							Save 💾
						</ThemeButton> }
					</ButtonColumn>
				</FormRow>
			</LocationForm>)
			},
			{
				name: "Notes",
				child: (location && location.locid !== 0) ? <CompanyNotes location={location} /> : <Announcement>Select a location</Announcement>
			}]} />

			
		</CompanyFormContainer>
	);
}

export default CompanyLocationForm;
