import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { foregroundColor, baseSettings } from '../../styles/theme';

const VerticalInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const HorizontalInputContainer = styled(VerticalInputContainer)`
    flex-direction: row;
`;

const InputHeader = styled.p`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
`;

const Input = styled.input`
	border-radius: 5px;
	border: none;
	border: 1px solid ${foregroundColor};
	color: ${foregroundColor};
	background: none;
	font-size: ${baseSettings.fontMedium};
	padding: 0.5rem;
	font-weight: 400;
	width: ${(props) => props.width};
`;

const Field = styled.div`
	border-radius: 5px;
	border: none;
	border: 1px solid ${foregroundColor};
	color: ${foregroundColor};
	background: none;
	font-size: ${baseSettings.fontMedium};
	padding: 0.5rem;
	font-weight: 400;
	min-height: ${baseSettings.medium};
	width: ${(props) => props.width};
`;

export function PlainInputFied({
	text = '',
	type = 'text',
	value = '',
	autofocus = false,
	defaultValue = '',
	orintation = 'verticle',
	width = '250px',
	disabled = false,
	...rest
}) {
	const Container = orintation === 'horizontal' ? HorizontalInputContainer : VerticalInputContainer;
	return (
		<Container>
			<InputHeader>{text}</InputHeader>
			<Input
				autoFocus={autofocus}
				width={width}
				value={value}
				defaultValue={defaultValue}
				disabled={disabled}
				type={type}
				{...rest}
			/>
		</Container>
	);
}

export function ReadOnlyField({ text = 'text', orintation = 'verticle', width = '250px', value = '' }) {
	const Container = orintation === 'horizontal' ? HorizontalInputContainer : VerticalInputContainer;

	return (
		<Container>
			<InputHeader>{text}</InputHeader>
			<Field width={width}>{value}</Field>
		</Container>
	);
}

function InputField({
	text = '',
	type = 'text',
	register = null,
	name = '',
	defaultValue = '',
	autofocus = false,
	orintation = 'verticle',
	width = '250px',
	disabled = false
}) {
	const Container = orintation === 'horizontal' ? HorizontalInputContainer : VerticalInputContainer;

	return (
		<Container>
			<InputHeader>{text}</InputHeader>
			<Input
				defaultValue={defaultValue}
				autoFocus={autofocus}
				width={width}
				ref={register}
				name={name}
				disabled={disabled}
				type={type}
			/>
		</Container>
	);
}

InputField.propTypes = {
	text: propTypes.string,
	type: propTypes.string,
	register: propTypes.func,
	name: propTypes.string,
	defaultValue: propTypes.string,
	orintation: propTypes.oneOf([ 'verticle', 'horizontal' ])
};

export default InputField;
