import { gql } from "@apollo/client"


export const GetContactActivity = gql`

    query($filter: ActivityContactFilterArgs!) {
        contactActivities(filter: $filter) {
            actid
			type {
				typeid
				type
			}
			date
			duration
			note
			participants {
				contact {
					contid
					firstName
					lastName
					title
				}
			}
        }
    }

`