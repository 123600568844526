import { gql } from '@apollo/client';

export const FILTER_INDUSTRIES = gql`
	query($name: String, $naics: Int) {
		filterIndustries(name: $name, naics: $naics) {
			industryid
			industryName
			naics
		}
	}
`;
