import React from 'react';
import styled, { css } from 'styled-components';
import { bool, func } from 'prop-types';
import modal from 'styled-react-modal';

const Modal = styled(modal)`
	align-items: center;
  	justify-content: center;

`;

function ModalContainer({ isOpen = false, children, toggleModal }) {
	return (
		<Modal isOpen={isOpen} onBackgroundClick={toggleModal} onEscapeKeydown={toggleModal}>
			{children}
		</Modal>
	);
}

ModalContainer.prototype = {
	isOpen: bool,
	toggleModal: func.isRequired
};

export default ModalContainer;
