import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import { useSpring, useSprings, animated } from 'react-spring';
import { foregroundColor, secondaryAccentColor, primaryColor, baseSettings, modalLight } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import Modal from './modalContainer';
import SizedBox from './SizedBox';
import ThemeButton from './ThemeButton';

const InputContainer = styled.div`
	align-items: center;
	border-radius: 25px;
	/* border: 2px solid orange; */
	border: ${theme('mode', {
		dark: 'none',
		light: '2px solid #00334e'
	})};
	background: ${modalLight};
	box-shadow: 1px 1px 8px 5px ${primaryColor};
	display: flex;
	padding: ${baseSettings.small};
	flex-direction: column;
	height: 25%;
	width: 35%;
	${mediaQueries('md')`
        justify-content: space-between;
		overflow: scroll;
		height: 55%;
		width: 80%;	
	
	`};
`;

const AnimatedContainer = animated(InputContainer);

const HeaderText = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontLarge};
	text-align: center;
`;

const Input = styled.input`
	background: none;
	border: none;
	border-bottom: 1px solid ${secondaryAccentColor};
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 300;
	width: 75%;
	${mediaQueries('md')`
        font-size: ${baseSettings.fontSmall};
        width: 95%;
    `};
`;

const ButtonRow = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	${mediaQueries('md')`
        flex-direction: column;
    `};
`;

const Divider = styled.div`
	margin: 0 ${baseSettings.large};
	${mediaQueries('md')`
        margin: ${baseSettings.large} 0;
    `};
`;

function CommandInputModal({
	isOpen,
	InputText,
	OnAccept,
	toggleOpen,
	OnCancel,
	AcceptButtonText = 'OK',
	CancelButtonText = 'Cancel'
}) {
	const props = useSpring({
		to: { transform: isOpen ? 'scale(1.2)' : 'scale(0)', opacity: isOpen ? 1 : 0 },
		config: { duration: 300, mass: 1, tension: 180, friction: 12 },

		from: { opacity: 0, transform: 'scale(0)' }
	});

	const [ inputValue, setInputValue ] = useState('');

	const onAccept = () => {
		OnAccept(inputValue); // Call passed method
		setInputValue('');
		toggleOpen();
	};
	const onCancel = () => {
		OnCancel(); // Call passed method
		setInputValue('');
		toggleOpen();
	};

	return (
		<Modal isOpen={isOpen} toggleOpen={toggleOpen}>
			<AnimatedContainer style={props}>
				<HeaderText>{InputText}</HeaderText>
				<SizedBox bottom={baseSettings.small} />
				<Input value={inputValue} onChange={(e) => setInputValue(e.target.value)} autoFocus />
				<SizedBox bottom={baseSettings.small} />
				<ButtonRow>
					<ThemeButton variant="secondary" onClick={onCancel}>
						{CancelButtonText}
					</ThemeButton>
					<Divider />
					<ThemeButton type="submit" onClick={onAccept}>
						{AcceptButtonText}
					</ThemeButton>
				</ButtonRow>
			</AnimatedContainer>
		</Modal>
	);
}

CommandInputModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggleOpen: PropTypes.func.isRequired,
	InputText: PropTypes.string.isRequired,
	OnAccept: PropTypes.func,
	OnCancel: PropTypes.func
};

export default CommandInputModal;
