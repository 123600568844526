import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_COMPANIES } from '../../api/companies/searchCompanies';


const numericFields = [ 'aescustid' ];

export function useCompanySearch() {
	const [ searchCompanies, { loading, error, data } ] = useLazyQuery(SEARCH_COMPANIES, {
		nextFetchPolicy: 'cache-and-network'
	});
	const filterState = useSelector((state) => state.companyFilters);
	const [ companies, setCompanies ] = useState([]);

	useEffect(
		() => {
			const filterCompanies = () => {
				const parsedValues = {};
				Object.keys(filterState).filter(key => key !== 'limit').forEach((key) => {
					if (filterState[key]) {
						if (numericFields.some((value) => value === key)) {
							parsedValues[key] = parseInt(filterState[key]);
						} else {
							parsedValues[key] = filterState[key];
						}
					}
				});
				const searchVariable = {
					...parsedValues, pagination: {
						limit: filterState.limit,
						offset: 0
					}
				};
				searchCompanies({
						variables: searchVariable
				});
			};
			filterCompanies();
		},
		[filterState, searchCompanies]
	);

	useEffect(
		() => {
			const formatCompanies = (companyArray) => {
				return companyArray.map(({ compid, companyname, aescustid, salesperson, contacts = [], locations = [] }) => ({
					compid,
					companyname,
					aescustid,
					salesperson,
					contacts: contacts.length,
					locations: locations.length
				}));
			};

			if (data && data.searchCompanies) {
				setCompanies(formatCompanies(data.searchCompanies));
			}
		},
		[ data ]
	);

	return { companies, error, loading };
}
