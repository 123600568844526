import React, { useEffect, useState } from 'react';
import { string, object } from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import theme from 'styled-theming';
import {
	baseSettings,
	foregroundColor,
	primaryBackgroundTheme,
	primaryBackgroundGradiantTheme
} from '../../styles/theme';

const LinkContainer = styled(NavLink)`
	align-items: center;
	cursor: pointer;
	padding: ${baseSettings.xs};
	border-radius: 10px;
	display: flex;
	margin: ${baseSettings.xs} ${baseSettings.xs} ${baseSettings.medium} ${baseSettings.xs};
	text-decoration: none;
	&:hover {
		${theme('mode', {
			light: primaryBackgroundGradiantTheme,
			dark: primaryBackgroundTheme
		})};
		filter: brightness(105%);
	}
`;

const LinkContainerSelected = styled(NavLink)`
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	${primaryBackgroundTheme};
	padding: ${baseSettings.xs};
	margin: ${baseSettings.xs} ${baseSettings.xs} ${baseSettings.small} ${baseSettings.xs};
	text-decoration: none;
`;

const Text = styled.div`
	color: ${({ active }) => (active ? foregroundColor : 'white')};
	filter: ${({ active }) => (active ? 'brightness(100%)' : 'brightness(90%)')};

	font-size: ${baseSettings.fontLarge};
	margin: 0 ${baseSettings.xs};
`;

const Container = ({ active, children, to }) =>
	active ? (
		<LinkContainerSelected to={to}>{children}</LinkContainerSelected>
	) : (
		<LinkContainer to={to}>{children}</LinkContainer>
	);

function MenuItem({ to, icon = null, children }) {
	let location = useLocation();

	const ReactIcon = styled(icon)`
        color: ${({ active }) => (active ? foregroundColor : 'white')};
        font-size: ${baseSettings.fontXL};
        filter: ${({ active }) => (active ? 'brightness(100%)' : 'brightness(90%)')};
    `;

	const active = location.pathname === to;

	return (
		<Container active={active} to={to}>
			<ReactIcon active={active} />
			<Text>{children}</Text>
		</Container>
	);
}

MenuItem.propTypes = {
	to: string.isRequired
};

export default MenuItem;
