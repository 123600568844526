import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getUserToken, clearUserToken } from '../api/user/tokenProvider';

const httpLink = createHttpLink({
	uri: `${process.env.REACT_APP_SERVER_ADDRESS || 'http://localhost'}:${process.env.REACT_APP_PORT || 5000}`
});

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = getUserToken();
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : ''
		}
	};
});

/**
 * Code is used to redirect the user to the login screen when their token has expired (basically it will clear the token and the router
 * will redirect to login thanks to the private route HOC)
 */
const authErrorLink = onError(({ graphQLErrors }) => {
	if (graphQLErrors) {
		if (graphQLErrors[0].message === `Not Authenticated`) {
			// This route will clear some global state, then redirect to the Sign In route
			clearUserToken();
		}
	}
});

export const client = new ApolloClient({
	link: authLink.concat(authErrorLink).concat(httpLink),
	cache: new InMemoryCache()
});
