import { gql } from '@apollo/client';

export const REASSIGN_CONTACT = gql`
	mutation($locid: Int!, $compid: Int!, $contid: Int!) {
		changeContactAssignment(locid: $locid, compid: $compid, contid: $contid) {
			contid
			firstName
			lastName
			title
			email
			cellphone
			department
			notes {
				cnoteid
				createdDate
				type {
					typeid
					type
				}
			}
			company {
				compid
				aescustid
				companyname
			}
			location {
				locid
				address1
				city {
					cityid
					cityName
				}
				state
				zip
				phone
				county {
					countid
					countyName
				}
				country {
					countid
					countryName
				}
			}
		}
	}
`;
