import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_CONTACTS_SLIM } from '../../api/contacts/searchContacts';

/**
 * 
 * @param {compid} optional company id that will be included on all calls, {lazyload} true, if false will query on load
 * @returns SalesContact
 */
export function useContactSearch({ compid, lazyload } = { lazyload: true }) {
	const [ contacts, setContacts ] = useState([]);
	const [ search, { loading, error, data } ] = useLazyQuery(SEARCH_CONTACTS_SLIM, { fetchPolicy: 'no-cache' });

	useEffect(() => {
		if (!lazyload) {
			searchContacts({});
		}
	}, []);

	useEffect(
		() => {
			if (data && data.searchContacts) {
				setContacts(data.searchContacts);
			}
		},
		[ data ]
	);

	const searchContacts = (fields) => {
		let additionalFields = {};
		if (compid) {
			additionalFields['compid'] = compid;
		}
		search({
			variables: {
				...fields,
				...additionalFields
			}
		});
	};

	return { loading, error, contacts, searchContacts };
}
