import React, { useContext, createContext, useState, useEffect } from 'react';
import { ModalProvider } from 'styled-react-modal';
import styled, { ThemeProvider } from 'styled-components';

// define our toggle context, with a default empty toggle functionconst ThemeToggleContext = React.createContext({
const ThemeToggleContext = createContext({
	toggle: () => {},
	theme: ''
});

// creating default styled modal overlay, this should be a translucent black overlay

const ModalBackground = styled.div`
	align-items: center;
	display: flex;
	position: fixed;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 30;
`;

// define exportable useContext hook object

export const useTheme = () => useContext(ThemeToggleContext);

const token_key = 'aes-theme';

function MyThemeProvider({ children }) {
	const [ themeState, setThemeState ] = useState({
		mode: localStorage.getItem(token_key) || 'dark'
	});

	useEffect(
		() => {
			localStorage.setItem(token_key, themeState.mode);
		},
		[ themeState ]
	);

	const toggle = () => {
		const mode = themeState.mode === 'light' ? `dark` : `light`;

		setThemeState({ mode: mode });
	};

	return (
		<ThemeToggleContext.Provider value={{ toggle: toggle, theme: themeState.mode }}>
			<ThemeProvider theme={themeState}>
				<ModalProvider backgroundComponent={ModalBackground}>{children}</ModalProvider>
			</ThemeProvider>
		</ThemeToggleContext.Provider>
	);
}

export default MyThemeProvider;
