const defaultUser = {
	userid: null,
	username: '',
	roles: [],
	email: '',
	workingState: ''
};

export const userReducer = (state = defaultUser, action) => {
	switch (action.type) {
		case 'user/set-user':
			const { userid, username, roles, email, workingState } = action.payload;
			return {
				userid,
				username,
				roles,
				workingState,
				email
			};
		case 'user/clear-user': {
			return defaultUser;
		}
		default:
			return state;
	}
};

export default userReducer;
