import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ReactMapGl, { GeolocateControl, Marker, Source, Layer } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { IoIosNavigate } from "react-icons/io";
import { FaBuilding } from "react-icons/fa";
import GeoEncoder from "react-map-gl-geocoder";
import { circle } from "@turf/turf";
import { FaMapMarkerAlt } from "react-icons/fa";
import "mapbox-gl/dist/mapbox-gl.css";
import PageBox from "../common/PageBox";
import "./geo-encoder.css";
import { useNearbyLocation } from "../../hooks/geo/useNearbyLocations";
import { baseSettings, primaryLighterColor, accentColor, secondaryAccentColor } from "../../styles/theme";
import MapControl from "./MapControl";
import { setLocation, setViewport, setControlState } from "../../redux/actions/radar";
import "./marker-styles.css";
import SizedBox from "../common/SizedBox";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
// this is a hack to make it work

const MarkerContainer = styled.div`
	color: "#ff3318" !important;
	display: block;
`;

const MarketDetailContainer = styled.div`
	align-items: center;
	display: none;
	flex-direction: column;
	justify-content: center;
	transition: opacity 0.33s ease-out;
	--webkit-transition: opacity 0.33s ease-out;
	z-index: 10;
`;

const MarkerHeaderText = styled.p`
	opacity: 0;
	margin: 0;
	font-size: ${baseSettings.fontSmall};
`;

const MarkerDetailedText = styled.p`
	margin: 0;
	font-size: ${baseSettings.fontSmall};
	opacity: 0;
	text-align: center;
`;

const NavIcon = styled(IoIosNavigate)`
	color: ${secondaryAccentColor};
	cursor: pointer;
	font-size: 4.4rem;
`;

const BuildingIcon = styled(FaBuilding)`
	color: ${primaryLighterColor};
	cursor: pointer;
	font-size: 3.5rem;
`;

const IconRow = styled.div`
	display: flex;
	justify-content: center;
	margin-top: ${baseSettings.xs};
	width: 100%;
	opacity: 0;
`;

const MarkerIcon = styled(FaMapMarkerAlt)`
	font-size: 3em;
`;

const MarkerSubText = styled.h5`
	color: ${primaryLighterColor};
	font-size: ${baseSettings.xs};
	font-weight: normal;
	max-width: 18ch;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-wrap: none;
`

const StyledMarker = styled(Marker)`
	color: none;
	cursor: pointer;
	z-index: 0;
	${MarkerIcon} {
		color: orange;
	}

	${MarkerHeaderText} {
		color: ${primaryLighterColor};
		font-weight: 500;
		font-size: ${baseSettings.small};
	}

	${MarkerDetailedText} {
		color: ${primaryLighterColor};
		font-weight: 300;
		font-size: ${baseSettings.small};
		transition: opacity 0.33s ease-out;
		--webkit-transition: opacity 0.33s ease-out;
	}

	${IconRow} {
		transition: opacity 0.33s ease-out;
		--webkit-transition: opacity 0.33s ease-out;
	}

	&:hover {
		${MarkerSubText} {
			display: none;
		}
		
		${MarketDetailContainer} {
			display: flex;
		}

		${MarkerHeaderText} {
			opacity: 1;
		}

		${MarkerDetailedText} {
			opacity: 1;
		}
		${IconRow} {
			opacity: 1;
		}
	}

	&:active {
    ${MarkerSubText} {
      display: none;
    }
		${MarketDetailContainer} {
			display: flex;
		}

		${MarkerHeaderText} {
			opacity: 1;
		}

		${MarkerDetailedText} {
			opacity: 1;
		}
		${IconRow} {
			opacity: 1;
		}
	}
`;

const ControlContainer = styled.div`
	position: absolute;
	left: 0;
	z-index: 3;
`;

const circleData = ({ longitude, latitude, radius }) => {
	return circle([longitude, latitude], radius, {
		steps: 50,
		units: "miles",
	});
};

const determineZoom = (radius) => {
	if (radius > 50) {
		return 7;
	} else if (radius > 10) return 8;

	return 9;
};

const SearchContainer = styled.div`
	display: flex;
`;


const randomOffset = () => {
	const choiceArray = [0, 10, 20];
	const randomIndex = Math.floor(Math.random() * 3);
	return choiceArray[randomIndex]
}


function RadarPage() {
	const geoLocation = useRef(null);
	const dispatch = useDispatch();
	const { location, viewport, controlState } = useSelector((state) => state.radar);
	const mapRef = useRef();
	const geoCodeContainer = useRef();
	const history = useHistory();
	const { locations, getLocations } = useNearbyLocation();

	const queryLocations = () => {
		const { latitude, longitude } = location;
		getLocations({
			longitude,
			latitude,
			radius: controlState.radius,
		});
	};

	useEffect(() => {
		if (location) {
			queryLocations();
		}
	}, [location]);

	useEffect(() => {
		if (controlState.type === "user" && geoLocation.current) {
			geoLocation.current._triggerGeolocate();
		}
	}, [controlState]);

	useEffect(() => {
		queryLocations();
	}, [controlState.radius]);


	const markers = useMemo(() => {

		return locations.map(({ distance, companyName, compid, location }) => {
			return (
				<StyledMarker

					key={location.locid}
					latitude={location.latitude}
					longitude={location.longitude}
					offsetLeft={-20}
					offsetTop={randomOffset()}
				>
					<MarkerContainer>
						<MarkerIcon />
						<MarkerSubText>{companyName}</MarkerSubText>
						<MarketDetailContainer>
							<MarkerHeaderText>{companyName}</MarkerHeaderText>
							<MarkerDetailedText>{location.address1 || ""}</MarkerDetailedText>
							<MarkerDetailedText>Distance {Math.round(distance)} Miles</MarkerDetailedText>
							<IconRow>
								<NavIcon onClick={() => onNavigate({ locid: location.locid })} />
								<SizedBox right={baseSettings.small} />
								<BuildingIcon onClick={() => onCompanyClick({ compid })} />
							</IconRow>
						</MarketDetailContainer>
					</MarkerContainer>
				</StyledMarker>
			);
		})

	}, [locations])

	const onNavigate = ({ locid }) => {
		//get location from nearby

		const { location: matchedLocation = null } = locations.find(({ location }) => location.locid === locid);

		if (matchedLocation) {
			const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
				`${matchedLocation.address1} ${(matchedLocation.city && matchedLocation.city.cityName) || ""} ${
					matchedLocation.state
				}`
			)}}`;
			const newWindow = window.open(url, "_blank", "noopener,noreferrer");
			if (newWindow) newWindow.opener = null;
		}
	};

	const onCompanyClick = ({ compid }) => {
		if (compid) {
			history.push(`/companies/${compid}`);
		}
	};

	return (
		<PageBox>
			<ReactMapGl {...viewport}  onViewportChange={(nextViewport) => dispatch(setViewport(nextViewport))} ref={mapRef}>
				<GeolocateControl
					ref={geoLocation}
					auto={controlState.geolocate}
					fitBoundsOptions={{ maxZoom: 8 }}
					onGeolocate={({ coords }) => {
						dispatch(setLocation(coords));
					}}
					onViewportChange={(v) => dispatch(setViewport({ ...v, zoom: determineZoom(controlState.radius) }))}
					style={{ position: "absolute", right: 45 }}
				/>
				<GeoEncoder
					mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
					mapRef={mapRef}
					placeholder="Starting Location"
					marker={true}
					countries="US"
					containerRef={geoCodeContainer}
					onViewportChange={(values) => {
						dispatch(setViewport(values));
						dispatch(setLocation(values));
						dispatch(setControlState({ ...controlState, type: "address" }));
					}}
				/>
				<ControlContainer>
					<MapControl controlState={controlState} setControlState={(state) => dispatch(setControlState(state))} mapReference={mapRef}>
						<SearchContainer ref={geoCodeContainer} ion />
					</MapControl>
				</ControlContainer>
				<Source
					id="radius-data"
					type="geojson"
					data={circleData({
						latitude: location.latitude,
						longitude: location.longitude,
						radius: controlState.radius,
					})}
				>
					<Layer
						id="point-90-hi"
						type="fill"
						paint={{
							"fill-color": "#282561",
							"fill-opacity": 0.4,
							"fill-outline-color": "white",
						}}
					/>
				</Source>
				$
				{markers}
			</ReactMapGl>
		</PageBox>
	);
}

export default RadarPage;
