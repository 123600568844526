import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_NOTE_TYPES } from '../../api/notes/getNoteTypes';

/**
 * Returns array of note types [{typeid: number, type: string}]
 */
export function useNoteTypes() {
	const { loading, data, error } = useQuery(GET_NOTE_TYPES);
	const [ types, setTypes ] = useState([]);

	useEffect(
		() => {
			if (data && data.noteTypes) {
				setTypes(data.noteTypes);
			}
		},
		[ data ]
	);

	return { loading, types, error };
}
