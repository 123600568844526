import React from "react";
import styled from "styled-components";
import { BiError } from "react-icons/bi";
import { mediaQueries } from "../../styles/media";
import { foregroundColor, accentColor, baseSettings } from "../../styles/theme";
import { useGraphProfile } from "../../hooks/graph/useGraphProfile";
import { SiMicrosoft } from "react-icons/si";

const CardContainer = styled.div`
	border: 1px solid
		${(props) => {
			switch (props.status) {
				case "loaded":
					return "#03c4a1";
				case "loading":
					return "#ffe042";
				case "error":
					return "red";
				default:
					return foregroundColor;
			}
		}};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	max-width: 100vw;
	padding: ${baseSettings.xs} ${baseSettings.small};
`;

const CardRow = styled.div`
	align-items: center;
	display: flex;
`;

const DisplayName = styled.p`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 600;
`;

const ErrorIcon = styled(BiError)`
	color: red;
	font-size: 2.2em;
	margin: 0 ${baseSettings.xs};
`;

const MicrosoftIcon = styled(SiMicrosoft)`
	color: ${foregroundColor};
	font-size: 2.4em;
	margin-right: ${baseSettings.xs};
`;

const ErrorText = styled.p`
	cursor: pointer;
	color: red;
	font-size: ${baseSettings.fontMedium};
	word-wrap: break-word;
	${mediaQueries("md")`
		font-size: ${baseSettings.fontxs};
	`};
`;

function ProfileLoaded({ profile }) {
	return (
		<>
			<CardRow>
				<MicrosoftIcon />
				<DisplayName>{profile.username}</DisplayName>
			</CardRow>
			<CardRow>Syncing information with {profile.mail}</CardRow>
		</>
	);
}

function ProfileError({ error, requery }) {
	return (
		<CardRow>
			<ErrorIcon />
			<ErrorText onClick={() => requery()}>
				There was an error signing you in to office 365. Click here to try again.
			</ErrorText>
		</CardRow>
	);
}

function MSProfileCard() {
	const {
		profileState: [state, profile],
	} = useGraphProfile();

	return (
		<CardContainer status={state}>
			{state === "loaded" && profile && <ProfileLoaded profile={profile} />}
			{state === "error" && <ProfileError requery={() => {}} error={profile} />}
			{state === "loading" && <div>loading..</div>}
		</CardContainer>
	);
}

export default MSProfileCard;
