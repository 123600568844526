import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { PublicRoute } from './PublicRouter';
import { PrivateRoute } from './PrivateRouter';
import LoginPage from '../components/login/login';
import DashboardPage from '../components/dashboard/dashboard';
import CompaniesPage from '../components/companies/companiesPage';
import CompanyDetailPage from '../components/companies/companyDetailPage';
import MetricList from '../components/repair-metrics/metricList';
import ContactsDetailPage from '../components/contacts/ContactDetail';
import ContactsPage from '../components/contacts/ContactsPage';
import ActivityMasterPage from '../components/activity/ActivityMasterPage';
import ActivityDetailPage from '../components/activity/ActivityDetailPage';
import MicrosoftAuthPage from '../components/auth/MicrosoftAuthPage';
import CustomerMetricsPage from '../components/aes-customers/CustomerMetricsPage';
import ScheduleActivityPage from '../components/activity/ScheduleActivityPage';
import NewCustomerPage from '../components/nci/NewCustomerPage';
import CustomerRadarPage from '../components/radar/RadarPage';
import AESCustomerDetailedPage from '../components/aes-customer-detail/aesCustomerDetailedPage';
import NotFoundPage from '../components/notFoundPage';

const AppRouter = () => (
	<BrowserRouter>
		<Switch>
			<PrivateRoute path="/dashboard" component={DashboardPage} />
			<PrivateRoute path="/companies/:id(\d+)" component={CompanyDetailPage} />
			<PrivateRoute exact path="/companies" component={CompaniesPage} />
			<PrivateRoute exact path="/contacts/:contid(\d+)" component={ContactsDetailPage} />
			<PrivateRoute path="/contacts" component={ContactsPage} />
			<PrivateRoute path="/repairs/metrics/:type(InProgress|WAPP|MonthlyShipped|Late)" component={MetricList} />
			<PrivateRoute path="/schedule-activity/page/:page?(\d+)" component={ScheduleActivityPage} />
			<PrivateRoute path="/customer-metrics" component={CustomerMetricsPage} />
			<PrivateRoute path="/schedule-activity" component={ScheduleActivityPage} />
			<PrivateRoute path="/activities/:actid(\d+)" component={ActivityDetailPage} />
			<PrivateRoute path="/new-aes-customer/:compid(\d+)" component={NewCustomerPage} />
			<PrivateRoute path="/aes-customer/:custId(\d+)" component={AESCustomerDetailedPage} />
			<PrivateRoute path="/activities" component={ActivityMasterPage} />
			<PrivateRoute path="/auth/microsoft" component={MicrosoftAuthPage} />
			<PrivateRoute path="/customer-radar" component={CustomerRadarPage} />
			<PublicRoute exact path="/" component={LoginPage} />
			<Route component={NotFoundPage} />
		</Switch>
	</BrowserRouter>
);

export default AppRouter;
