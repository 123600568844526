import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { CubeGrid } from 'better-react-spinkit';
import { useForm, Controller } from 'react-hook-form';
import { useParams, Redirect } from 'react-router-dom';
import PageBox from '../common/PageBox';
import { useActivity } from '../../hooks/activities/useActivity';
import { mediaQueries } from '../../styles/media';
import { AttendeeCard, AttendeeContainer } from '../activity/ContactPage';
import DateTimePicker from '../common/DateTimePicker';
import { foregroundColor, accentColor, baseSettings } from '../../styles/theme';
import { useTheme } from '../../styles/ThemeProvider';
import ActivitySelect from '../common/ActivityTypeSelect';
import TabContainer from '../common/tabContainer';
import ThemeButton from '../common/ThemeButton';
import SizedBox from '../common/SizedBox';
import ConfirmModal from '../common/CommonInputModal';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const ActivityForm = styled.form`
	display: flex;
	flex-direction: column;
	width: 20vw;
	${mediaQueries('md')`
		width: 100%;
		padding-bottom: ${baseSettings.small};
	`};
`;

const LabelText = styled.h3`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 400;
`;

const ActivityContainer = styled.div`width: 100%;`;

const NoteArea = styled.textarea`
	background: transparent;
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	color: ${foregroundColor};
	padding: ${baseSettings.xs};
	font-size: ${baseSettings.fontMedium};
	text-align: left;
	height: 350px;
`;

const ButtonStack = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${baseSettings.small};
`;

const ParticipantsContainer = styled.div`
	width: 20vw;
	${mediaQueries('md')`
		width: 100%;
		padding-bottom: ${baseSettings.small};
	`};
`;

function Form({ activity, saveActivity, setModalOpen }) {
	const { register, handleSubmit, control, formState } = useForm({ defaultValues: activity });

	const onSave = (values) => {
		saveActivity({
			...values,
			typeid: values.type.typeid,
			date: dayjs(values.date).toISOString()
		});
	};

	return (
		<TabContainer
			tabs={[
				{
					child: (
						<ActivityForm onSubmit={handleSubmit(onSave)}>
							<LabelText>Activity Date</LabelText>
							<Controller
								control={control}
								name="date"
								render={({ value, onChange }) => (
									<DateTimePicker
										date={value ? dayjs(value).toDate() : null}
										key={'date'}
										setDate={(value) => onChange(value)}
									/>
								)}
							/>
							<Controller
								name="type.typeid"
								control={control}
								render={({ value, onChange }) => (
									<ActivityContainer>
										<ActivitySelect
											key="actSelect"
											selectedValue={value}
											selectionChanged={(value) => onChange(value)}
										/>
									</ActivityContainer>
								)}
							/>
							<LabelText>Notes</LabelText>
							<NoteArea ref={register} name="note" maxLength={500} />
							<ButtonStack>
								<ThemeButton scaleEffect={false} type="submit">
									Save
								</ThemeButton>
								<SizedBox bottom={baseSettings.medium} />
								<ThemeButton variant="secondary" scaleEffect={false} onClick={() => setModalOpen(true)}>
									Remove ❌
								</ThemeButton>
								<SizedBox bottom={baseSettings.xs} />
								{/* <ThemeButton variant="secondary" scaleEffect={false}>
									Delete
								</ThemeButton> */}
							</ButtonStack>
						</ActivityForm>
					),
					name: 'Info'
				},
				{
					name: 'Participants',
					child: (
						<AttendeeContainer>
							<div>This is read only at this time, waiting to finish calendar sync code 👍</div>
							{activity.participants.map(({ contact }) => (
								<AttendeeCard
									name={`${contact.firstName || ''} ${contact.lastName || ''}`}
									company={contact.title || ''}
									contid={contact.contid}
									key={contact.contid}
								/>
							))}
						</AttendeeContainer>
					)
				}
			]}
		/>
	);
}

function ActivityDetailPage() {
	const { actid } = useParams();
	const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
	const { activity, error, loading, updateActivity: saveActivity, removeActivity } = useActivity(actid);
	const themeData = useTheme();

	const remove = (input) => {
		if (input.toLowerCase() === 'yes') {
			removeActivity({ actid: activity.actid });
		}
	};

	return (
		<PageBox>
			<Container>
				<ConfirmModal
					isOpen={deleteModalOpen}
					toggleOpen={() => setDeleteModalOpen(!deleteModalOpen)}
					OnAccept={remove}
					OnCancel={() => setDeleteModalOpen(false)}
					InputText="Type Yes to remove"
				/>
				{loading && <CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />}
				{!loading &&
				activity && <Form activity={activity} saveActivity={saveActivity} setModalOpen={setDeleteModalOpen} />}
				{!loading && error && <LabelText>No Activity Found 😭</LabelText>}
			</Container>
		</PageBox>
	);
}

export default ActivityDetailPage;
