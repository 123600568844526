import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PageBox from '../common/PageBox';
import MainActivityCalendar from './calendar/MainActivityCalendar';
import { baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

const ToolBarRow = styled.div`display: flex;`;

const ScheduleButton = styled(NavLink)`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	background: transparent;
	color: ${foregroundColor};
	cursor: pointer;
	margin-left: ${baseSettings.small};
	margin-top: ${baseSettings.xs};
	padding: ${baseSettings.xs};
	transition: background 0.10s ease-in;
	text-decoration: none;
	--webkit--transition: background 0.10s ease-in;
	&:hover {
		background: ${secondaryAccentColor};
	}
`;

function ActivityMasterPage() {
	return (
		<PageBox>
			<Container>
				<ToolBarRow>
					<ScheduleButton to="/schedule-activity">Add Activity</ScheduleButton>
				</ToolBarRow>
				<MainActivityCalendar aspectRatio={3} />
			</Container>
		</PageBox>
	);
}

export default ActivityMasterPage;
