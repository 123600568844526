import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { mediaQueries } from '../../styles/media';
import {
	accentColor,
	secondaryAccentColor,
	baseSettings,
	foregroundColor,
	lightforegroundColor
} from '../../styles/theme';

const TextColumn = styled.div`
	display: flex;
	margin: ${baseSettings.small};
	flex-direction: column;
	justify-content: flex-start;
	${mediaQueries('md')`
		align-items: center;
	
    `};
`;

const TextHeader = styled.h5`
	color: ${lightforegroundColor};
	margin: 0 0 ${baseSettings.xs} 0;
	text-align: left;
	font-weight: 400;
	font-size: ${baseSettings.fontSmall};
`;

const TextValue = styled.h2`
	color: ${(props) => {
		switch (props.colorScheme) {
			case 'less':
				return '#FF5260';
			case 'greater':
				return '#2ACB8E';
			default:
				return foregroundColor;
		}
	}};
	margin: 0;
	text-align: left;
	font-weight: 600;
	font-size: ${baseSettings.fontXL};
`;

function MetricText({
	numberValue = 0,
	numberDescription = '',
	unit = 'numeric',
	loading = true,
	colorScheme = 'normal'
}) {
	const value =
		unit === 'currency'
			? '$' + numberValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
			: numberValue;

	const Loader = () => (
		<ContentLoader viewBox="0 0 210 100" backgroundColor={'white'} style={{ height: '100px', width: '210px' }}>
			<rect x="0" y="20" rx="5" ry="5" width="75" height="10" />
			<rect x="0" y="55" rx="5" ry="5" width="100" height="25" />
		</ContentLoader>
	);

	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<TextColumn>
					<TextHeader>{numberDescription}</TextHeader>
					<TextValue colorScheme={colorScheme}>{value}</TextValue>
				</TextColumn>
			)}
		</div>
	);
}

MetricText.propTypes = {
	numberValue: PropTypes.number.isRequired,
	colorScheme: PropTypes.string,
	numberDescription: PropTypes.string,
	unit: PropTypes.oneOf([ 'numeric', 'currency' ]),
	loading: PropTypes.bool
};

export default MetricText;
