import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { ADD_ACTIVITY } from '../../api/activities/addActivity';
import { useGraphEvent } from '../../hooks/graph/useGraphEvent';

/**
 * hook for adding an activity to the system.
 * successful result will return actid to be used for routing to detailed page.
 * Will also schedule to users outlook if authenticated and sync is set in activity state
 */
export function useAddActivity() {
	const [ add, { loading, error, data } ] = useMutation(ADD_ACTIVITY);
	const [ actId, setActId ] = useState(null);
	const { scheduleEvent } = useGraphEvent();

	useEffect(
		() => {
			if (data && data.addActivity) {
				setActId(data.addActivity.actid);
			}
		},
		[ data ]
	);

	/**
     * Will add a new activity
     * @param {
     *  date, (in unixcode numerical format)
     *  participants (num array),
     *  typeid,
     *  note,
     *  duration (Int in minutes)
     * } 
     */
	const addActivity = (data) => {
		const isSynced = data.sync ? true : false;
		const subject = data.subject + '';
		delete data.sync;
		delete data.subject;
		add({
			variables: {
				data: {
					...data,
					participants: data.participants.map((p) => p.contid)
				}
			}
		});
		if (isSynced) {
			scheduleEvent({
				startDate: data.date,
				endDate: dayjs(data.date).add(data.duration, 'minute'),
				body: data.note || '',
				subject: subject,
				participants: data.participants
			});
		}
	};

	return { loading, error, actId, addActivity };
}
