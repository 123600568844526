import { gql } from '@apollo/client';

export const CONVERT_COMPANY = gql`
	mutation($data: NewNCIInput!) {
		convertCompany(data: $data) {
			compid
			aescustid
		}
	}
`;
