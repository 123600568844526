import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setActivityProps } from '../../redux/actions/newActivity';
import { baseSettings, foregroundColor, lightforegroundColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';

const NoteDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 75vw;
	${mediaQueries('md')`
        width: 95%;
    
    `};
`;

const Header = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontXL};
	font-weight: 600;
`;

const NoteArea = styled.textarea`
	border: 1px solid ${foregroundColor};
	background: none;
	border-radius: 5px;
	color: ${foregroundColor};
	padding: ${baseSettings.xs};
	font-size: ${baseSettings.fontMedium};
	height: 55%;
	width: 45%;
	${mediaQueries('md')`
        width: 100%;
    
    `};
`;

const SubjectInput = styled.input`
	border: 1px solid ${foregroundColor};
	background: transparent;
	color: ${foregroundColor};
	padding: ${baseSettings.xs};
	font-size: ${baseSettings.fontMedium};
	width: 45%;
	${mediaQueries('md')`
        width: 100%;
    
    `};
`;

function NotePage() {
	const { note, sync } = useSelector((state) => state.newActivity);
	const dispatch = useDispatch();

	return (
		<NoteDiv>
			<Header>Any Notes?</Header>
			<NoteArea
				value={note}
				onInput={(e) => dispatch(setActivityProps({ note: e.target.value }))}
				maxLength={750}
			/>
			{sync && <Header>Outlook Subject 📆</Header>}
			{sync && (
				<SubjectInput
					maxLength={40}
					placeholder="This will show in your outlook calendar"
					onInput={(e) => dispatch(setActivityProps({ subject: e.target.value }))}
				/>
			)}
		</NoteDiv>
	);
}

export default NotePage;
