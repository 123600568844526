import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import { mediaQueries } from '../../styles/media';
import { accentColor, secondaryAccentColor, baseSettings, foregroundColor } from '../../styles/theme';

const Box = styled.div`
	align-items: stretch;
	background: ${({ variant }) => (variant === 'secondary' ? secondaryAccentColor : accentColor)};
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: ${baseSettings.xs};
	margin: ${baseSettings.xs};
	overflow: hidden;
	height: 250px;
	width: 220px;
	${mediaQueries('md')`
		min-width: 55vw;
        width: 55vw;
	`};
`;

const TextRowDiv = styled.div`
	align-items: flex-end;
	display: flex;
	flex-direction: row;
`;

const NumericText = styled.h2`
	margin: 0;
	color: white;
	font-size: 6.5rem;
	margin-right: 2px;
`;

const NumericUnit = styled.p`
	color: white;
	font-size: ${baseSettings.fontSmall};
	text-overflow: ellipsis;
`;

const DollarAmount = styled(NumericUnit)`
	

`;

const DescriptionText = styled.p`
	color: white;
	text-transform: uppercase;
	font-weight: 200;
	font-size: ${baseSettings.small};
	text-align: left;
	text-overflow: ellipsis;
`;

const Loader = () => (
	<ContentLoader viewBox="0 0 220 250" backgroundColor={'white'}>
		<rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
		<rect x="0" y="100" rx="4" ry="4" width="150" height="85" />
		<rect x="160" y="160" rx="4" ry="4" width="55" height="25" />
		<rect x="0" y="200" rx="4" ry="4" width="220" height="10" />
		<rect x="0" y="215" rx="4" ry="4" width="220" height="10" />

		{/* <rect x="80" y="75" rx="3" ry="3" width="250" height="10" /> */}
	</ContentLoader>
);

function NumericMetricBox({
	numericValue,
	numericUnit,
	description,
	variant = 'primary',
	reactIcon,
	loading = true,
	totalValue = ''
}) {
	const Icon = styled(reactIcon)`
        color: white;
        font-size: 4.5rem;
	`;

	return (
		<Box variant={variant}>
			{loading ? (
				<Loader />
			) : (
				<div>
					<Icon />
					<TextRowDiv>
						<NumericText>{numericValue}</NumericText>
						<NumericUnit>{numericUnit}</NumericUnit>
					</TextRowDiv>
					{totalValue && <DollarAmount>{totalValue}</DollarAmount>}
					<DescriptionText>{description}</DescriptionText>
				</div>
			)}
		</Box>
	);
}

NumericMetricBox.propTypes = {
	numericValue: PropTypes.number.isRequired,
	numericUnit: PropTypes.string.isRequired,
	totalValue: PropTypes.string,
	description: PropTypes.string,
	variant: PropTypes.oneOf([ 'primary', 'secondary' ]),
	reactIcon: PropTypes.func.isRequired,
	loading: PropTypes.bool
};

export default NumericMetricBox;
