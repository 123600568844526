import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { GET_COMPANY } from '../../api/companies/getCompany';
import { setCompany } from '../../redux/actions/company';
import { setInitialLocation } from '../../redux/actions/location';

/**
 * Will handle all logic and redux state for fetching single company
 * params: compid {number} of the company ID to load
 */
export function useCompany({ compid }) {
	const { refetch, loading, error, data } = useQuery(GET_COMPANY, { variables: { compid } });
	const dispatch = useDispatch();
	const company = useSelector((state) => state.company);

	useEffect(() => {
		refetch();
	}, []);

	// TODO test null company
	useEffect(
		() => {
			if (data && data.company) {
				dispatch(setCompany(data.company));
				dispatch(setInitialLocation(data.company.locations)); // sets initial location to the first one
			}
		},
		[ data, dispatch ]
	);

	return { loading, error, company };
}
