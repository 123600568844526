import styled from "styled-components";
import { Virtuoso } from "react-virtuoso";
import { useMemo } from "react";
import { mediaQueries } from "../../../styles/media";
import { baseSettings } from "../../../styles/theme";
import { useIsMobile } from "../../../hooks/design/useIsMobile";
import dayjs from "dayjs";
import GenericTable from "../../common/genericTable";
import MobileActivity from "./MobileActivity";

const TableContainer = styled.div`
	flex: 0.45;
	margin-right: ${baseSettings.small};
	min-width: 450px;
	${mediaQueries("md")`
    margin-bottom: ${baseSettings.small};
    flex: none;
    width: 50vw;
`};
`;

function MobileActivitiesList({ activities = [] }) {
	return (
		<Virtuoso
			style={{ height: "650px", width: "60vw" }}
			data={activities}
			itemContent={(_, activity) => <MobileActivity activity={activity} />}
		/>
	);
}

function ActivitiesList({ activities = [], onSelect }) {
	const isMobile = useIsMobile();
	const columns = useMemo(
		() => [
			{
				Header: "actid",
				accessor: "actid",
			},
			{
				Header: "Date",
				accessor: (value) => (value.date ? dayjs(value.date).format("MM-DD-YY") : ""),
			},
			{
				Header: "Type",
				accessor: "type.type",
			},
		],
		[]
	);

	return (
		<TableContainer>
			{isMobile ? (
				<MobileActivitiesList activities={activities} />
			) : (
				<GenericTable
					columns={columns}
					data={activities}
					width="100%"
					hiddenColumns={["actid"]}
					onSelect={({ actid }) => onSelect({ actid })}
				/>
			)}
		</TableContainer>
	);
}

export default ActivitiesList;
