import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';

const BadgeContainer = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	margin-bottom: ${baseSettings.xs};
`;

const IDText = styled.p`
	color: ${foregroundColor};
	margin: 0;
	font-size: ${baseSettings.fontSmall};
	font-weight: 600;
	text-align: center;
	${mediaQueries('md')`
        font-size: ${baseSettings.fontxs};
    `};
`;

const NavText = styled(NavLink)`
	text-decoration: none;
	text-align: center;
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
`;

function CustIdBadge({ aescustid }) {
	return (
		<BadgeContainer>
			<IDText>Company Converted</IDText>
			<NavText to={`/aes-customer/${aescustid}`}>- See Customer {aescustid} -</NavText>
		</BadgeContainer>
	);
}

CustIdBadge.propTypes = {
	aescustid: propTypes.number.isRequired
};

export default CustIdBadge;
