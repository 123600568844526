import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { useCounties } from '../../hooks/counties/useCounties';
import CretableSelect from './CretableSelect';

import Select from './SelectField';

function CountySelect({ control = null, creatable = false }) {
	const { counties, loading, error } = useCounties();

	const onInputChange = (value) => {
		if (value) {
		}
	};


	let options = counties.map(({ countid, countyName }) => ({ label: countyName, value: countid }));

	

	return (
		<Controller
			control={control}
			name={'county'}
			render={(props) => {
				const option = options.find(({ value }) => value === (props.value?.value ?? props.value)) || { value: '', label: '' };
				return creatable ? (
					<CretableSelect
						text={'County Name'}
						onInputChange={onInputChange}
						onChange={({ value, label }) => {
							
							let chosenOption = { value, label };
							if (!options.find((item) => item.value === value)) {
								// add option, this is cretable
								options = options.filter((item) => item.value !== 0);
								options.push({
									value: 0,
									label: value
								});
								chosenOption.value = 0;
							}
							props.onChange(chosenOption);
						}}
						options={options}
						value={{ label: `${option.label}`, value: `${option.value}` }}
						noOptionsMessage={'County Not Found'}
						loading={loading}
					/>
				) : (
					<Select
						text={'County Name'}
						onInputChange={onInputChange}
						onChange={({ value, label }) => props.onChange({label, value})}
						options={options}
						value={{ label: `${option.label}`, value: `${option.value}` }}
						noOptionsMessage={'County Not Found'}
						loading={loading}
					/>
				);
			}}
		/>
	);
}

export default CountySelect;
