import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_AES_COMPANIES } from '../../api/aesCompanies/searchCompanies';

export function useSystemCompanySearch() {
	const [ search, { loading, error, data } ] = useLazyQuery(SEARCH_AES_COMPANIES);
	const [ companies, setCompanies ] = useState([]);

	useEffect(
		() => {
			if (data && data.searchSystemCustomers) {
				setCompanies(data.searchSystemCustomers);
			}
		},
		[ data ]
	);

	const searchCompanies = ({ custid, customerName }) => {
		search({
			variables: {
				id: custid,
				customerName
			}
		});
	};

	return { searchCompanies, loading, error, companies };
}
