import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { BiFilter } from 'react-icons/bi';
import { baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';
import { isFilterApplied } from '../../redux/reducers/contactFilter';
import PageBox from '../common/PageBox';
import ContactGrid from './ContactGrid';
import ContactFilterModal from './ContactFilterModal';
import LimitDropdown from "../companies/LmitDropdown";

const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	width: 100vw;
`;

const ToolBarRow = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const ToolBarContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex-wrap: nowrap;
	padding: ${baseSettings.small} 0 ${baseSettings.xs} 0;
	margin-right: ${baseSettings.large};
`;

const StyledFilterIcon = styled(BiFilter)`
    color: ${(props) => (props.filterApplied ? 'white' : secondaryAccentColor)};
	background: ${(props) => (props.filterApplied ? secondaryAccentColor : 'transparent')};
	border-radius: 5px;
    font-size: 5rem;
    transition: scale 0.33s ease-out;
    --webkit-transition: scale 0.33s ease-out;

    &:hover {
        scale: 1.2;
        --webkit-transform: scale(1.1);
    }
`;

const Header = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	margin: ${baseSettings.small};
	margin-left: 0;
	font-weight: 400;
`;

function ContactsPage() {
	const [ filterOpen, setFilterOpen ] = useState(false);
	const filterState = useSelector((state) => state.contactFilters);

	return (
		<PageBox>
			<ContactFilterModal isOpen={filterOpen} toggleOpen={() => setFilterOpen(!filterOpen)} />

			<BodyContainer>
				<ToolBarRow>
					<ToolBarContainer>
						<LimitDropdown type={'contact'} />
						<StyledFilterIcon
							onClick={() => setFilterOpen(true)}
							filterApplied={isFilterApplied(filterState)}
						/>
					</ToolBarContainer>
				</ToolBarRow>
				<Header>Your Contacts</Header>
				<ContactGrid />
			</BodyContainer>
		</PageBox>
	);
}

export default ContactsPage;
