import { gql } from '@apollo/client';
import { Contact } from './contactFragment';
export const GET_CONTACT = gql`
	${Contact}
	query($contid: Int!) {
		contact(contid: $contid) {
			...ContactParts
		}
	}
`;
