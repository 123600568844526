import React from "react";
import Select from "../common/SelectField";
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux";
import { setLimit } from "../../redux/actions/companyFilter";
import { setContactLimit } from "../../redux/actions/contactFilter"
import { foregroundColor, baseSettings  } from '../../styles/theme';



const Wrapper = styled.div`
	align-items: center;
	display: flex;
	margin-right: 16px;
	width: 150px;
`

const Text = styled.span`
	color: ${foregroundColor};
	margin-right: 6px;
	font-size: ${baseSettings.fontMedium};
`


const options = [
	{label: 10, value: 10},
	{label: 50, value: 50},
	{label: 100, value: 100},
	{label: 'All', value: 10000}
]


const LimitDropdown = ({type = 'company'}) => {
	const dispatch = useDispatch();
	const {limit: companyLimit} = useSelector(state => state.companyFilters)
	const {limit: contactLimit} = useSelector(state => state.contactFilters)


	const onLimitChange = (value) => {
		if(type === 'company')
		{
			dispatch(setLimit(value.value))
		}
		else {
			dispatch(setContactLimit((value.value)))
		}

	}

	const selectedValue = options.find(v => v.value === (type === 'company' ? companyLimit : contactLimit));

	return (
		<Wrapper>
			<Text>Limit: </Text>
			<Select onChange={onLimitChange} options={options} value={selectedValue}/>

		</Wrapper>
	);
};

export default LimitDropdown;
