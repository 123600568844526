import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ADD_COMPANY_NOTE } from '../../api/notes/addCompanyNote';
import { REMOVE_COMPANY_NOTE } from '../../api/notes/removeCompanyNote';
import { setLocationNotes } from '../../redux/actions/location';

/**
 * Handles the addition, update, and removal of company notes
 * Will update the provided company.location.notes state
 * @param {*} locid 
 */
export function useCompanyNotes(locid) {
	const [ error, setError ] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const dispatch = useDispatch();
	const [
		addOrUpdate,
		{ data: addOrUpdateData, loading: addOrUpdateLoading, error: addOrUpdateError }
	] = useMutation(ADD_COMPANY_NOTE);
	const [ remove, { data: removeData, loading: removeLoading, error: removeError } ] = useMutation(
		REMOVE_COMPANY_NOTE
	);

	useEffect(
		() => {
			setLoading(addOrUpdateLoading || removeLoading);
		},
		[ addOrUpdateLoading, removeLoading ]
	);

	useEffect(
		() => {
			if (addOrUpdateData && addOrUpdateData.addOrUpdateCompanyNote) {
				dispatch(setLocationNotes(addOrUpdateData.addOrUpdateCompanyNote));
			}
		},
		[ addOrUpdateData, dispatch, locid ]
	);

	useEffect(
		() => {
			if (removeData && removeData.removeCompanyNote) {
				dispatch(setLocationNotes(removeData.removeCompanyNote));
			}
		},
		[ removeData, dispatch, locid ]
	);

	useEffect(
		() => {
			setError(addOrUpdateError, removeError);
		},
		[ addOrUpdateError, removeError ]
	);

	const addNote = (noteArgs) => {
		addOrUpdate({
			variables: {
				noteArgs
			}
		});
	};

	const removeNote = (cnoteid) => {
		remove({
			variables: {
				cnoteid
			}
		});
	};

	return { loading, error, addNote, removeNote };
}
