import { gql } from '@apollo/client';

export const GET_COUNTIES = gql`
	query {
		counties {
			countid
			countyName
		}
	}
`;
