import React from 'react';
import theme from 'styled-theming';
import styled from 'styled-components';
import propTypes from "prop-types"
import { useForm, Controller } from 'react-hook-form';
import { useSpring, animated } from 'react-spring';
import { mediaQueries } from '../../styles/media';
import { foregroundColor, baseSettings, modalLight, primaryColor } from '../../styles/theme';
import { useNoteTypes } from '../../hooks/noteTypes/useNoteTypes'
import Modal from '../common/modalContainer';
import SelectField from '../common/SelectField'
import ThemeButton from '../common/ThemeButton'
import SizedBox  from '../common/SizedBox'


const NoteContainer = styled.form`
	align-items: center;
	border-radius: 25px;
	/* border: 2px solid orange; */
	border: ${theme('mode', {
		dark: 'none',
		light: '2px solid #00334e'
	})};
	background: ${modalLight};
	box-shadow: 1px 1px 8px 5px ${primaryColor};
	display: flex;
	flex-direction: column;
	height: 55%;
	width: 25%;
    justify-content: space-around;
	padding: ${baseSettings.small};
	${mediaQueries('md')`
		overflow: scroll;
		height: 80%;
		width: 80%;	
	
	`};
`;

const NoteArea = styled.textarea`
	border: 1px solid ${foregroundColor};
	background: none;
	border-radius: 5px;
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	padding: ${baseSettings.xs};
	height: 35%;
	width: 100%;
	${mediaQueries('md')`
        font-size: ${baseSettings.fontSmall};
		height: 65%;
		width: 100%;
	`};
`;

const Header = styled.h2`
    color: ${foregroundColor};
    font-size: ${baseSettings.fontMedium};
    font-weight: 500;

`

const Container = animated(NoteContainer);

function NewContactNoteModal({ isOpen, toggleOpen, onSubmit }) {
	const props = useSpring({
		to: { transform: isOpen ? 'scale(1.2)' : 'scale(0)', opacity: isOpen ? 1 : 0 },
		config: { duration: 300, mass: 1, tension: 180, friction: 12 },
		from: { opacity: 0, transform: 'scale(0)' }
	});
    const { register, handleSubmit, control } = useForm();
    const { loading, types } = useNoteTypes()

    const submit =(values) => {
        onSubmit(values)
        toggleOpen()
    }

	return (
		<Modal isOpen={isOpen} toggleOpen={toggleOpen}>
			<Container style={props} onSubmit={handleSubmit(submit)}>
                <Header>Add New Note</Header>
				<NoteArea placeholder="Enter your note here" name="note" ref={register({ required: true })} />
                <Controller
				name="typeid"
				rules={{required: true}}
				control={control}
				render={(props) => {
					const selctedValue = types.find(({ typeid }) => typeid === props.value);
					return (
						<SelectField
							text={'Note Type'}
							loading={loading}
							onChange={({ value }) => props.onChange(value)}
							value={{
								value: selctedValue?.typeid,
								label: selctedValue?.type
							}}
							options={types.map(({ typeid, type }) => ({ value: typeid, label: type }))}
						/>
					);
				}}
				loading={loading}
			/>
            <SizedBox bottom={baseSettings.small}/>
            <ThemeButton variant="secondary" scaleEffect={false} onClick={toggleOpen}>Cancel</ThemeButton>
            <SizedBox bottom={baseSettings.small}/>

            <ThemeButton type="submit" scaleEffect={false}>Add Note</ThemeButton>

			</Container>
		</Modal>
	);
}

NewContactNoteModal.propTypes = {
    isOpen: propTypes.bool.isRequired,
    toggleOpen: propTypes.func.isRequired,
    onSubmit: propTypes.func.isRequired
}

export default NewContactNoteModal;
