export const getFilter = () => ({
	type: 'company-filter/get'
});

export const clearFilter = () => ({
	type: 'company-filter/clear'
});

export const setFilter = (filter) => ({
	type: 'company-filter/set',
	payload: filter
});

export const setLimit = (limit = 50) => ({
	type: 'company-filter/set-limit',
	payload: limit
})
