import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import NumericMetricBox from '../common/NumericMetricBox';
import MetrixText from '../common/MetricText';
import { GiAutoRepair } from 'react-icons/gi';
import { AiFillQuestionCircle, AiOutlineClockCircle } from 'react-icons/ai';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaTruckMoving } from 'react-icons/fa';
import { baseSettings, lightforegroundColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import { useDashboardNumeric } from '../../hooks/metrics/useDashboardNumeric';

// the column for both rows
const MetricContainer = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	width: 100%;
	${mediaQueries('md')`
		align-items: center;
	
    `};
`;

const NumericContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	${mediaQueries('md')`
		align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
    `};
`;

const DollarRow = styled.div`
	display: flex;
	justify-content: flex-start;
	border-radius: 25px;
	margin-left: ${baseSettings.xs};
	border: 1px dashed ${lightforegroundColor};
	padding: 0 ${baseSettings.medium};
	${mediaQueries('md')`
		align-items: center;
		flex-direction: column;
		padding: 0 ${baseSettings.xs};
		width: 50%;
    `};
`;

const MetricNav = styled(NavLink)`
	text-decoration: none;
`;

const formatToUsd = (value) => {
	if (typeof value === 'number') {
		return '$' + value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return '';
};

function SalesNumericMetrics() {
	const { loading, metrics } = useDashboardNumeric();

	const {
		inProgress = 0,
		customerCount = 0,
		itemsInWapp = 0,
		shipmentsThisMonth = 0,
		totalMonthSold = 0,
		totalApprovedInHouse = 0,
		totalInWapp = 0,
		countPastESD = 0,
		itemQuotePastESD = 0
	} =
		metrics || {};

	return (
		<MetricContainer>
			<NumericContainer>
				<MetricNav to={'/repairs/metrics/InProgress'}>
					<NumericMetricBox
						loading={loading}
						description={'Items in progress'}
						numericValue={inProgress}
						numericUnit={'item(s)'}
						reactIcon={GiAutoRepair}
						totalValue={formatToUsd(totalApprovedInHouse)}
					/>
				</MetricNav>
				<MetricNav to={'/repairs/metrics/Late'}>
					<NumericMetricBox
						loading={loading}
						description={'Late Items'}
						numericValue={countPastESD}
						variant={'secondary'}
						numericUnit={'item(s)'}
						reactIcon={AiOutlineClockCircle}
						totalValue={formatToUsd(itemQuotePastESD)}
					/>
				</MetricNav>
				<MetricNav to={'/repairs/metrics/WAPP'}>
					<NumericMetricBox
						loading={loading}
						description={'Items waiting for approval'}
						numericValue={itemsInWapp}
						variant={'primary'}
						numericUnit={'item(s)'}
						reactIcon={AiFillQuestionCircle}
						totalValue={formatToUsd(totalInWapp)}
					/>
				</MetricNav>
				<MetricNav to="/customer-metrics">
					<NumericMetricBox
						loading={loading}
						description={'customer count'}
						numericValue={customerCount}
						variant={'secondary'}
						numericUnit={'accts.'}
						reactIcon={BsFillPersonFill}
					/>
				</MetricNav>
				<MetricNav to={'/repairs/metrics/MonthlyShipped'}>
					<NumericMetricBox
						loading={loading}
						description={'Items shipped this month'}
						numericValue={shipmentsThisMonth}
						variant={'primary'}
						numericUnit={'item(s)'}
						reactIcon={FaTruckMoving}
						totalValue={formatToUsd(totalMonthSold)}
					/>
				</MetricNav>
			</NumericContainer>
		</MetricContainer>
	);
}

export default SalesNumericMetrics;
