import { gql } from '@apollo/client';

export const SEARCH_ACTIVITIES = gql`
	query($startDate: Date, $endDate: Date, $typeid: Int, $compid: Int) {
		searchActivities(startDate: $startDate, endDate: $endDate, typeid: $typeid, compid: $compid) {
			actid
			date
			duration
			note
			type {
				typeid
				type
			}
		}
	}
`;
