import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import { Circle } from 'better-react-spinkit';
import { baseSettings, accentColor, secondaryAccentColor } from '../../styles/theme';
import { useTheme } from '../../styles/ThemeProvider';

const Button = styled.button`
	background-color: ${(props) => (props.variant === 'primary' ? accentColor : secondaryAccentColor)};
	color: white;
	cursor: pointer;
	border: none;
	padding: ${(props) => props.fontSize};
	font-size: ${(props) => props.fontSize};
	font-weight: ${(props) => props.fontWeight};
	transition: scale 0.33s ease-out;
	--webkit-transition: scale 0.33s ease-out;
	width: 100%;
	&:hover {
		background: ${theme('mode', {
			dark: (props) => (props.variant === 'primary' ? secondaryAccentColor : accentColor),
			light: (props) => (props.variant === 'primary' ? secondaryAccentColor : accentColor)
		})};
		scale: ${(props) => (props.scaleEffect ? '1.1' : '1.0')};
		--webkit-transform: scale(${(props) => (props.scaleEffect ? 1.1 : 1.0)});
	}
`;

function ThemeButton({
	variant = 'primary',
	fontSize = baseSettings.fontMedium,
	scaleEffect = true,
	fontWeight = 300,
	disabled = false,
	style = {},
	type = 'button',
	children,
	loading = false,
	onClick = null,
	...rest
}) {
	const themeData = useTheme();

	return (
		<Button
			fontSize={fontSize}
			fontWeight={fontWeight}
			variant={variant}
			style={style}
			type={type}
			onClick={onClick}
			scaleEffect={scaleEffect}
			disabled={disabled || loading}
		>
			{loading ? <Circle color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={25} /> : children}
		</Button>
	);
}

export default ThemeButton;
