import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CubeGrid } from 'better-react-spinkit';
import { Virtuoso } from 'react-virtuoso';
import { useSystemCompanySearch } from '../../hooks/systemCompanies/useSystemCompSarch';
import InputField from '../common/InputField';
import SizedBox from '../common/SizedBox';
import { baseSettings, foregroundColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';
import { useTheme } from '../../styles/ThemeProvider';
import { useIsMobile } from '../../hooks/design/useIsMobile';
import GenericTable from '../common/genericTable';

const BodyContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 ${baseSettings.small};
	width: 100vw;
	overflow-y: none;
`;

const ToolBarRow = styled.div`
	display: flex;
	width: 100%;
`;

const FilterInputForm = styled.form`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	flex-wrap: nowrap;
	padding: ${baseSettings.small} 0 ${baseSettings.xs} 0;
	${mediaQueries('md')`
		flex-direction: column;
	
	`};
`;
const LoadingDiv = styled.div`
	display: flex;
	flex-direction: column-reverse;
	margin-left: ${baseSettings.small};
`;

/// Mobile view code //

const CompanyDiv = styled.div`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	margin: ${baseSettings.xs} 0;
	text-decoration: none;
	min-height: 10vh;
`;

const CompanyDivHeader = styled.h3`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.small};
	font-weight: 600;
`;

const CompanyDivText = styled(CompanyDivHeader)`
	font-weight: 300;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const CompanyLink = styled(NavLink)`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.small};
	font-weight: 300;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;

`;

const CompanyDivRow = styled.div`
	align-items: center;
	display: flex;
	margin-top: 2px;
`;

function Company(props) {
	const { id = 0, customerName = '', mainLocation = {}, lastShipped = null } = props;

	return (
		<CompanyDiv>
			<CompanyDivRow>
				{/* <NumberIcon /> */}
				<CompanyDivHeader>{id}</CompanyDivHeader>
				<SizedBox right={baseSettings.xs} />
				<CompanyLink to={`/aes-customer/${id}`}>{customerName}</CompanyLink>
			</CompanyDivRow>
			<CompanyDivRow>
				<CompanyDivHeader>{mainLocation.shipToState || ''}</CompanyDivHeader>
				<SizedBox right={baseSettings.xs} />
				<CompanyDivText>{lastShipped ? dayjs(lastShipped).format('MM-DD-YY') : 'Never Shipped'}</CompanyDivText>
			</CompanyDivRow>
		</CompanyDiv>
	);
}

////////////////////////////////////////
function AesCompaniesGrid() {
	const { register, handleSubmit } = useForm({ mode: 'onChange' });
	const { companies, error, loading, searchCompanies } = useSystemCompanySearch();
	const themeData = useTheme();
	const isMobile = useIsMobile();

	const columns = useMemo(
		() => [
			{
				Header: 'Cust ID',
				accessor: 'id',
				totalWidth: 5
			},
			{
				Header: 'Name',
				accessor: 'customerName'
			},
			{
				Header: 'Industry',
				accessor: 'industry'
			},
			{
				Header: 'City',
				accessor: 'mainLocation.shipToCity'
			},
			{
				Header: 'State',
				accessor: 'mainLocation.shipToState'
			},
			{
				Header: 'Last Shipped',
				accessor: (value) =>
					value && value.lastShipped ? dayjs(value.lastShipped).format('MM-DD-YY') : 'Never Shipped'
			},
			{
				Header: 'Program Mgr',
				accessor: 'programManager'
			},
			{
				Header: 'Sales Rep',
				accessor: 'salesPerson'
			}
		],
		[]
	);

	const applyFilter = ({ custId, companyName }) => {
		searchCompanies({
			custid: custId ? parseInt(custId) : null,
			customerName: companyName
		});
	};

	return (
		<BodyContainer>
			<ToolBarRow>
				<FilterInputForm onChange={handleSubmit(applyFilter)}>
					<InputField
						text="Company ID"
						width={'120px'}
						register={register({ pattern: /^\d+$/ })}
						name="custId"
						type={'number'}
					/>
					<SizedBox right={baseSettings.small} />
					<InputField text="Name" register={register} name="companyName" />
					{loading && (
						<LoadingDiv>
							<CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={25} style={{}} />
						</LoadingDiv>
					)}
				</FilterInputForm>
			</ToolBarRow>
			{isMobile ? (
				<Virtuoso
					style={{ height: '650px' }}
					data={companies}
					itemContent={(_, company) => (
						<Company
							id={company.id}
							customerName={company.customerName}
							mainLocation={company.mainLocation}
							lastShipped={company.lastShipped}
						/>
					)}
				/>
			) : (
				<GenericTable
					columns={columns}
					data={companies}
					navColumn={{
						route: '/aes-customer'
					}}
				/>
			)}
		</BodyContainer>
	);
}

export default AesCompaniesGrid;
