import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export const PublicRoute = ({ component: Component, ...rest }) => {
	const user = useSelector((state) => state.user);

	return (
		<Route
			{...rest}
			component={(props) =>
				user.userid ? <Redirect to={{ pathname: '/dashboard' }} /> : <Component {...props} />}
		/>
	);
};
