import React, { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import PageBox from "../common/PageBox";
import { useHistory, useParams } from "react-router-dom";
import { setInitialCompany } from "../../redux/actions/nci";
import MultiPageComponent from "../common/MultiPageComponent";
import AddressPage from "./AddressPage";
import ContactPage from "./ContactPage";
import IndustryPage from "./IndustryPage";
import ConfirmationPage from "./ConfirmationPage";
import NotePage from "./NotePage";
import { CONVERT_COMPANY } from "../../api/companies/convertCompany";
import { useCompany } from "../../hooks/companies/useCompany";

function NewCustomerPage() {
	const { compid = 0 } = useParams();
	const { company, error, loading } = useCompany({ compid: parseInt(compid) });
	const [convert, { loading: convertLoading, error: convertError, data }] = useMutation(CONVERT_COMPANY);
	const history = useHistory();
	const nci = useSelector((state) => state.nci);
	const dispatch = useDispatch();
	const apolloError = error || convertError;

	const pages = [
		{
			validate: () => (nci.locid ? true : false),
			component: <AddressPage company={company} />,
		},
		{
			validate: () => (nci.contid ? true : false),
			component: <ContactPage company={company} />,
		},
		{
			validate: () => !!(nci.typeid && nci.naics && !isNaN(nci.potential) && nci.potential > 0),
			component: <IndustryPage />,
		},
		{
			validate: () => true,
			component: <NotePage company={company} />,
		},
		{
			validate: () => true,
			component: <ConfirmationPage company={company} />,
		},
	];

	useEffect(() => {
		dispatch(setInitialCompany({ compid: parseInt(compid) }));
	}, [compid]);

	useEffect(() => {
		if (data && data.convertCompany) {
			history.push(`/companies/${data.convertCompany.compid}`);
		}
	}, [data]);

	const onSubmit = () => {
		// grab state and send mutation
		convert({
			variables: {
				data: nci,
			},
		});
	};

	return (
		<PageBox>
			{!loading && company && (
				<MultiPageComponent
					loading={loading}
					pages={pages}
					handleSubmit={onSubmit}
					errorMessage={apolloError ? apolloError.message : ""}
				/>
			)}
		</PageBox>
	);
}

export default NewCustomerPage;
