const baseFilterState = {
	contid: null,
	compid: null,
	firstName: '',
	lastName: '',
	state: '',
	limit: 50
};

export const isFilterApplied = (filterState) => {
	return JSON.stringify(filterState) !== JSON.stringify(baseFilterState);
};

export function contactFilterReducer(state = baseFilterState, action) {
	switch (action.type) {
		case 'contact-filter/set': {
			state = { ...state, ...action.payload };
			return state;
		}
		case 'contact-filter/clear': {
			state = { ...baseFilterState };
			return state;
		}

		case 'contact-filter/set-limit': {
			state = {...state, limit: action.payload}
			return state;
		}

		default: {
			return state;
		}
	}
}
