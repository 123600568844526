import { gql } from '@apollo/client';

export const ADD_COMPANY_NOTE = gql`
	mutation($noteArgs: SalesCompanyNoteArgs!) {
		addOrUpdateCompanyNote(noteArgs: $noteArgs) {
			cnoteid
			createdDate
			note
			type {
				typeid
				type
			}
		}
	}
`;
