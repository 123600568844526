import { gql } from '@apollo/client';
import { Contact } from './contactFragment';
export const DUPLICATE_CONTACT = gql`
    ${Contact}
    mutation ($contactId: Int!) {
        duplicateContact(contactId: $contactId){
            ...ContactParts
        }
    }
`;
