import React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { CubeGrid } from 'better-react-spinkit';
import { foregroundColor, secondaryAccentColor, baseSettings, primaryBackgroundTheme } from '../../styles/theme';
import { useIsMobile } from '../../hooks/design/useIsMobile';
import { useTheme } from '../../styles/ThemeProvider';
import { useCustomerMetrics } from '../../hooks/metrics/useCustomerMetrics';
import GenericTable from '../common/genericTable';
import PageBox from '../common/PageBox';

const TableContainer = styled.div`
	margin-top: ${baseSettings.small};
	max-height: 75vh;
	overflow-y: auto;
`;

const Header = styled.h3`
	margin: 0;
	margin-top: ${baseSettings.small};
	font-size: ${baseSettings.medium};
	color: ${foregroundColor};
`;

// Mobile Design //
const MobileDiv = styled.div`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	padding: ${baseSettings.xs};
	margin-bottom: 4px;
	flex-direction: column;
	width: 100%;
	height: 15vh;
`;

const MobileRow = styled.div`
	display: flex;
	background-color: ${primaryBackgroundTheme};
	justify-content: space-between;
`;

const CustID = styled.h3`
	font-size: ${baseSettings.fontMedium};
	margin: 0;
	font-weight: 600;
`;

const CustomerName = styled.p`
	font-size: ${baseSettings.fontSmall};
	margin: 0;
	font-weight: 300;
	text-overflow: ellipsis;
	word-wrap: break-word;
	width: 100%;
	margin-top: ${baseSettings.xs};
`;

const SalesColumn = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
`;
const ColumnHeader = styled.p`
	margin: 0;
	font-size: ${baseSettings.fontSmall};
	font-weight: 600;
	margin-bottom: ${baseSettings.xs};
`;

const ColumnData = styled.p`
	color: ${secondaryAccentColor};
	margin: 0;
	font-size: ${baseSettings.fontSmall};
	font-weight: 600;
`;

const PrimaryRow = styled.div`
	display: flex;
	justify-content: space-between;
`;
const LastShipped = styled(CustID)`
    font-weight: 300;
`;

const Error = styled.p`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontMedium};
`;

function MobileItem({ customer }) {
	const history = useHistory();

	return (
		<MobileDiv onClick={() => history.push(`/aes-customer/${customer.id_}`)}>
			<PrimaryRow>
				<CustID>{customer.id_}</CustID>
				<LastShipped>{`🚚${customer.lastShipped
					? dayjs(customer.lastShipped).format('MM-DD-YY')
					: ''}`}</LastShipped>
			</PrimaryRow>
			<CustomerName>{customer.customerName}</CustomerName>
			<MobileRow>
				<SalesColumn>
					<ColumnHeader>WIP</ColumnHeader>
					<ColumnData>{customer.wipQuote}</ColumnData>
				</SalesColumn>
				<SalesColumn>
					<ColumnHeader>WAPP</ColumnHeader>
					<ColumnData>{customer.wappQuote}</ColumnData>
				</SalesColumn>
				<SalesColumn>
					<ColumnHeader>{`${dayjs().subtract(1, 'year').format('YY')} Sales`}</ColumnHeader>
					<ColumnData>{customer.year4Quote}</ColumnData>
				</SalesColumn>
				<SalesColumn>
					<ColumnHeader>{`${dayjs().subtract(0, 'year').format('YY')} Sales`}</ColumnHeader>
					<ColumnData>{customer.year5Quote}</ColumnData>
				</SalesColumn>
			</MobileRow>
		</MobileDiv>
	);
}

function CustomerMetricsPage() {
	const { loading, error, columns, metrics } = useCustomerMetrics();
	const themeData = useTheme();
	const isMobile = useIsMobile();

	const Component = () =>
		!isMobile ? (
			<GenericTable
				columns={columns}
				data={metrics}
				navColumn={{
					route: `/aes-customer`
				}}
			/>
		) : (
			metrics.map((metric) => <MobileItem key={metric.id_} customer={metric} />)
		);

	return (
		<PageBox>
			<Header>Your Customers</Header>
			<TableContainer>
				{error && <Error>{error.message}</Error>}
				{loading && <CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />}
				{!loading && <Component />}
			</TableContainer>
		</PageBox>
	);
}

export default CustomerMetricsPage;
