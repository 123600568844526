import React, { useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { CubeGrid } from 'better-react-spinkit';
import { BsPerson } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import GenericTable from '../../components/common/genericTable';
import { baseSettings, primaryColor, foregroundColor } from '../../styles/theme';
import { useIsMobile } from '../../hooks/design/useIsMobile';
import { Virtuoso } from 'react-virtuoso';
import { useTheme } from '../../styles/ThemeProvider';
import { useCompanySearch } from '../../hooks/companies/useCompanySearch';

const LoadingRow = styled.div`
	align-items: center;
	justify-content: center;
	display: flex;
	height: 50%;
`;

const TableContainer = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	border: 1px solid ${primaryColor};
	height: 90%;
`;

const CompanyDiv = styled(NavLink)`
	border: 1px solid ${foregroundColor};
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	margin: ${baseSettings.xs} 0;
	text-decoration: none;
`;

const CompanyDivHeader = styled.h3`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.small};
	font-weight: 600;
`;

const CompanyDivText = styled(CompanyDivHeader)`
	font-weight: 300;
`;

const CompanyDivRow = styled.div`
	align-items: center;
	display: flex;
	margin-top: 2px;
`;

const PersonIcon = styled(BsPerson)`
	color: ${foregroundColor};
	font-size: ${baseSettings.small};
	margin-right: ${baseSettings.xs};
`;

const Company = (props) => {
	const { compid = 0, companyname = '', contacts = 0 } = props;

	return (
		<CompanyDiv to={`/companies/${compid}`}>
			<CompanyDivHeader>{companyname}</CompanyDivHeader>
			<CompanyDivRow>
				<PersonIcon />
				<CompanyDivText>{contacts}</CompanyDivText>
			</CompanyDivRow>
		</CompanyDiv>
	);
};

function CompaniesGrid() {
	const isMobile = useIsMobile();
	const { loading, companies } = useCompanySearch();
	const themeData = useTheme();

	const columns = useMemo(
		() => [
			{
				Header: 'Company ID',
				accessor: 'compid'
			},
			{
				Header: 'Company Name',
				accessor: 'companyname'
			},
			{
				Header: 'Sales Rep',
				accessor: 'salesperson'
			},
			{
				Header: 'AES ID',
				accessor: 'aescustid'
			},
			{
				Header: 'Location #',
				accessor: 'locations'
			},
			{
				Header: 'Contact #',
				accessor: 'contacts'
			}
		],
		[]
	);

	const Component = () => {
		if (loading) {
			return (
				<LoadingRow>
					<CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />
				</LoadingRow>
			);
		}

		if (isMobile) {
			return (
				<Virtuoso
					style={{ height: '650px' }}
					data={companies}
					itemContent={(_, company) => (
						<Company
							compid={company.compid}
							companyname={company.companyname}
							contacts={company.contacts}
						/>
					)}
				/>
			);
		}

		return (
			<TableContainer>
				<GenericTable
					columns={columns}
					data={companies}
					hiddenColumns={[ 'compid' ]}
					navColumn={{ route: '/companies' }}
					pagnationSize={15}
				/>
			</TableContainer>
		);
	};

	return <Component />;
}

export default CompaniesGrid;
