import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useSelector } from 'react-redux';
import theme from 'styled-theming';
import { CubeGrid } from 'better-react-spinkit';
import { mediaQueries } from '../../styles/media';
import Modal from '../common/modalContainer';
import CompanySelect from '../common/companySelect';
import CompanyLocationSelect from '../common/companyLocationSelect';
import ThemeButton from '../common/ThemeButton';
import { useTheme } from '../../styles/ThemeProvider';
import { useContactReassign } from '../../hooks/contacts/useContactReassign';

import {
	primaryColor,
	modalLight,
	baseSettings,
	foregroundColor,
	secondaryAccentColor,
	accentColor
} from '../../styles/theme';

const PreAnContainer = styled.div`
	align-items: center;
	border-radius: 25px;
	/* border: 2px solid orange; */
	border: ${theme('mode', {
		dark: 'none',
		light: '2px solid #00334e'
	})};
	background: ${modalLight};
	box-shadow: 1px 1px 8px 5px ${primaryColor};
	display: flex;
	flex-direction: column;
	height: 45%;
	width: 25%;
	${mediaQueries('md')`
		overflow: scroll;
		height: 80%;
		width: 80%;	
	
	`};
`;

const Header = styled.div`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.small};
	font-weight: 500;
	text-align: center;
	line-height: 1.2;
`;

const SelectorRows = styled.div`
	display: flex;
	width: 100%;
`;

const PaddingContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
`;

const StyledLocationDisplay = styled.div`
	color: ${foregroundColor};
	display: flex;
	flex-direction: column;
	font-size: ${baseSettings.fontMedium};
	font-weight: 400;
	margin-top: ${baseSettings.small};
	text-align: left;
`;

const StyledLocationText = styled.p`
	font-size: ${baseSettings.fontSmall};
	font-weight: 300;
`;

const ErrorMessage = styled.p`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
	font-weight: 300;
`;

const ButtonRow = styled.div`display: flex;`;

const Container = animated(PreAnContainer);

function ContactCompanySection({ isOpen, toggleOpen }) {
	const themeData = useTheme();
	const contact = useSelector((state) => state.contact);
	const [ location, setLocation ] = useState(null);
	const [ company, setCompany ] = useState(null);
	const { loading, error, assignContact, contact: updatedContact } = useContactReassign();

	useEffect(
		() => {
			if (contact && contact.company) {
				setCompany(contact.company);
			}
			if (contact && contact.location) {
				setLocation(contact.location);
			}
		},
		[ contact ]
	);

	useEffect(
		() => {
			if (updatedContact) {
				toggleOpen();
			}
		},
		[ updatedContact ]
	);

	const props = useSpring({
		to: { transform: isOpen ? 'scale(1.2)' : 'scale(0)', opacity: isOpen ? 1 : 0 },
		config: { duration: 300, mass: 1, tension: 180, friction: 12 },
		from: { opacity: 0, transform: 'scale(0)' }
	});

	const onConfirm = () => {
		assignContact({
			compid: company.compid || 0,
			locid: location.locid || 0,
			contid: contact.contid
		});
	};

	return (
		<Modal isOpen={isOpen} toggleOpen={toggleOpen}>
			<Container style={props}>
				<PaddingContainer>
					<Header>🔥 DANGER 🔥 </Header>
					<Header>reassigning contact Company/Location. Please confirm before submitting.</Header>
					<SelectorRows>
						<CompanySelect company={company} setValue={(value) => setCompany(value)} />
					</SelectorRows>
					<SelectorRows>
						{company && (
							<CompanyLocationSelect
								location={location}
								compid={company.compid}
								setValue={(value) => {
									setLocation(value);
								}}
							/>
						)}
					</SelectorRows>
					{location &&
					!error &&
					location.city && (
						<SelectorRows>
							<StyledLocationDisplay>
								<StyledLocationText>🏦 City: {location.city.cityName}</StyledLocationText>
							</StyledLocationDisplay>
						</SelectorRows>
					)}
					{location &&
					!error &&
					location.state && (
						<SelectorRows>
							<StyledLocationDisplay>
								<StyledLocationText>🗽 State: {location.state}</StyledLocationText>
							</StyledLocationDisplay>
						</SelectorRows>
					)}
					{loading && <CubeGrid color={themeData.theme === 'dark' ? 'white' : '#ee6f57'} size={45} />}
					{error && <ErrorMessage>This is a error placeholder</ErrorMessage>}
					{!loading && (
						<ButtonRow>
							<ThemeButton variant={'secondary'} scaleEffect={false} onClick={() => toggleOpen()}>
								Cancel
							</ThemeButton>

							<ThemeButton scaleEffect={false} onClick={onConfirm}>
								Confirm
							</ThemeButton>
						</ButtonRow>
					)}
				</PaddingContainer>
			</Container>
		</Modal>
	);
}

export default ContactCompanySection;
