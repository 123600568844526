import React, { useState } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import theme from 'styled-theming';
import { accentColor, foregroundColor, baseSettings, secondaryAccentColor } from '../../styles/theme';
import SizedBox from './SizedBox';

const TabContainerVerticle = styled.div`
	display: flex;
	flex-direction: column;
`;

const VerticleTabRow = styled.div`
	display: flex;
	overflow-x: auto;
	overflow-y: auto;
	min-height: 24px;
	width: calc(100% - 8vw);
`;

const VerticleTabHeader = styled.div`
	cursor: pointer;
	display: flex;
	border-bottom: ${(props) =>
		props.active === true
			? theme('mode', {
					dark: `2px solid ${props.variant === 'primary' ? '#03c4a1' : '#FF5260'}`,
					light: `2px solid ${props.variant === 'primary' ? '#ee6f57' : '#00334e'}`
				})
			: `none`};
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	margin-right: ${baseSettings.medium};
`;

const VerticleTabBody = styled.div`
	display: flex;
	margin-top: ${baseSettings.medium};
	height: 85%;
	padding-top: ${baseSettings.xs};
	width: ${(props) => props.width};
`;

function TabContainer({ orintation = 'horizontal', tabs, variant = 'primary', width = '85%' }) {
	const [ selectedTab, setSelectedTab ] = useState(0); // default to first tab
	return (
		<TabContainerVerticle>
			<VerticleTabRow>
				{tabs.map((tab) => (
					<VerticleTabHeader
						variant={variant}
						active={selectedTab === tabs.indexOf(tab)}
						key={`${tab.name}-tab`}
						onClick={() => setSelectedTab(tabs.indexOf(tab))}
					>
						{tab.name}
						<SizedBox bottom={'20px'} />
					</VerticleTabHeader>
				))}
			</VerticleTabRow>
			<VerticleTabBody width={width}>{tabs[selectedTab].child}</VerticleTabBody>
		</TabContainerVerticle>
	);
}

export default TabContainer;
