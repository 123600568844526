import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { YTD_GOALS } from '../../api/metrics/dashboardMertics';

export function useY2DGoals() {
	const { loading, error, data } = useQuery(YTD_GOALS);
	const [ metrics, setMetrics ] = useState(null);

	useEffect(
		() => {
			if (data && data.ytdMetrics) {
				setMetrics(data.ytdMetrics);
			}
		},
		[ data ]
	);

	return { loading, error, metrics };
}
