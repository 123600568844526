import React from "react";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import { useIndustries } from "../../hooks/industries/useIndustries";

import Select from "../common/SelectField";

function NACISSelect({ control = null }) {
	const { industires, loading, filterNaics } = useIndustries({ prefill: true });

	const onInputChange = (value) => {
		if (value) {
			filterNaics(value);
		}
	};

	const options = industires.map(({ naics }) => ({ label: naics, value: naics }));

	return (
		<Controller
			control={control}
			name="naics"
			render={(props) => {
				const option = options.find(({ value }) => value === props.value) || { value: "", label: "" };
				return (
					<Select
						text={"NAICS #"}
						onInputChange={onInputChange}
						onChange={({ value }) => {
							onInputChange(value);
							props.onChange(value);
						}}
						options={options}
						noOptionsMessage={"NAICS# Not Found"}
						loading={loading}
						value={{ label: `${option.value}`, value: `${option.value}` }}
					/>
				);
			}}
		/>
	);
}

export default NACISSelect;
