const defaultState = {
	typeid: null,
	date: new Date(),
	duration: 15,
	participants: [],
	subject: '',
	note: '',
	sync: false
};

export function newActivityReducer(state = defaultState, action) {
	switch (action.type) {
		case 'new-activity/set': {
			state = { ...state, ...action.payload };
			return state;
		}
		case 'new-activity/clear': {
			return defaultState;
		}
		case 'new-activity/add-contact': {
			state = {
				...state,
				participants: [
					...state.participants.filter((c) => c.contid !== action.payload.contid),
					action.payload
				]
			};
			return state;
		}
		case 'new-activity/call-contact': {
			return {
				...defaultState,
				typeid: 2, // 2 is call
				participants: [ action.payload ],
				duration: 5
			};
		}
		case 'new-activity/remove-contact': {
			state = {
				...state,
				participants: [ ...state.participants.filter((c) => c.contid !== action.payload) ]
			};
			return state;
		}
		default: {
			return state;
		}
	}
}
