import { useState, useEffect } from "react";
import { useGraph } from "../../provider/GraphProvider";

export const baseUrl =
	"https://graph.microsoft.com/v1.0/me/events?$select=subject,body,bodyPreview,organizer,attendees,start,end,location";

export const OutlookEvent = {
	subject: "",
	body: {
		content: "",
	},
	start: {
		dateTime: "",
	},
};

/**
 * Will return the entire calendar for a user's outlook
 */
export const useGraphCalendar = (mapper) => {
	const { graphService, callMSGraphGet } = useGraph();
	const [status, setStatus] = useState("paused");
	const [events, setEvents] = useState([]);

	useEffect(() => {
		const getOutlookEvents = async () => {
			try {
				const rest = await callMSGraphGet(baseUrl);
				if (rest && rest.value) {
					setEvents(rest.value.map((v) => mapper(v)));
				}
			} catch (e) {
				setStatus("error");
			}
		};

		getOutlookEvents();
	}, []);

	return { events, status };
};
