import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { DASHBOAD_METRICS } from '../../api/metrics/dashboardMertics';
export function useDashboardNumeric() {
	const { loading, error, data } = useQuery(DASHBOAD_METRICS);
	const [ metrics, setMetrics ] = useState(null);

	useEffect(
		() => {
			if (data && data.dashboardMetrics) {
				setMetrics(data.dashboardMetrics);
			}
		},
		[ data ]
	);

	return { loading, error, metrics };
}
