import { gql } from '@apollo/client';

export const UPDATE_ACTIVITY = gql`
	mutation($actid: Int!, $updateData: UpdateSalesActivityInput!) {
		updateActivity(actid: $actid, updateData: $updateData) {
			actid
			type {
				typeid
				type
			}
			date
			duration
			note
			participants {
				contact {
					contid
					firstName
					lastName
					title
				}
			}
		}
	}
`;
