import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTheme } from "../../styles/ThemeProvider";
import { SEARCH_ACTIVITIES } from "../../api/activities/searchActivities";
import { useGraphCalendar } from "../graph/useGraphCalendar";

/** Will fetch two months of activites by default
 * can also supply a mapper function to format the output of the activities
 */
export function useSearchActivities(mapper = null) {
	const { refetch: search, loading, error, data } = useQuery(SEARCH_ACTIVITIES, { fetchPolicy: "cache-and-network" });
	const [activities, setActivities] = useState([]);
	const theme = useTheme();

	const outlookEventMapper = (event) => {
		return {
			id: event.id,
			title: event.subject,
			start: event.start?.dateTime,
			end: event.end.dateTime,
			classNames: [theme.theme === "dark" ? "outlook-cal-dark" : "outlook-cal-light"],
		};
	};

	const { events } = useGraphCalendar(outlookEventMapper);

	useEffect(() => {
		if (data && data.searchActivities) {
			if (mapper) {
				setActivities(mapper(data.searchActivities));
			} else {
				setActivities(data.searchActivities);
			}
		}
	}, [data]);

	useEffect(() => {
		if (events?.length > 0) {
			setActivities((old) => [...old, ...events]);
		}
	}, [events]);

	const searchActivities = ({ actid, startDate, endDate, compid }) => {
		search({
			actid,
			startDate,
			endDate,
			compid,
		});
	};

	return { loading, error, searchActivities, activities };
}
