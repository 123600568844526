import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datetime-picker';
import { useTheme } from '../../styles/ThemeProvider';
import style from '../../styles/datePicker.css';
import { baseSettings, foregroundColor } from '../../styles/theme';

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;
const InputHeader = styled.p`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
	margin-bottom: ${baseSettings.xs};
`;

const PickerContainer = styled.div`width: 850px;`;

function DateTimePicker({ header = '', setDate, date = new Date() }) {
	const { theme } = useTheme();
	const [ stateDate, setStateDate ] = useState(date);

	useEffect(
		() => {
			setDate(stateDate);
		},
		[ stateDate ]
	);

	return (
		<Container>
			{header !== '' && <InputHeader>{header}</InputHeader>}
			<PickerContainer>
				<DatePicker
					onChange={setStateDate}
					value={stateDate}
					className={[
						`react-datetime-picker__border-${theme}`,
						`react-datetime-picker__inputGroup__input--${theme}`,
						`react-datetime-picker__inputGroup__amPm--${theme}`,
						`buttons--${theme}`
					]}
				/>
			</PickerContainer>
		</Container>
	);
}

DateTimePicker.propTypes = {
	header: propTypes.string,
	date: propTypes.instanceOf(Date),
	setDate: propTypes.func.isRequired
};

export default DateTimePicker;
