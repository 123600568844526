import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { baseSettings, primaryBackgroundTheme } from '../../styles/theme';
import { useOnClickOutside } from '../../hooks/useClickOnOutside';
import { mediaQueries } from '../../styles/media';
import Menu from '../menu/menu';
import BurgerMenu from '../menu/burger';

const AppContainer = styled.div`
	display: flex;
	${primaryBackgroundTheme};
	min-height: 100vh;
	max-height: 100vh;
	width: 100vw;
	overflow: none;
`;

const ChildContainer = styled.div`
	overflow-y: hidden;
	overflow-x: hidden;
	${mediaQueries('md')`
		padding: ${baseSettings.xs};
		padding-right: ${baseSettings.small};
	`};
	min-height: 100vh;
	width: 100%;
`;

function PageContainer({ children }) {
	const node = useRef();
	const [ open, setOpen ] = useState(false);
	useOnClickOutside(node, () => setOpen(false));

	return (
		<AppContainer>
			<BurgerMenu open={open} setOpen={setOpen} />

			<Menu open={open} setOpen={setOpen} />
			<ChildContainer ref={node}>{children}</ChildContainer>
		</AppContainer>
	);
}

export default PageContainer;
