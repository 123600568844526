import { gql } from '@apollo/client';

export const ADD_OR_UPDATE_LOCATION = gql`
	mutation($location: LocationArgs!) {
		saveLocation(location: $location) {
			locid
			address1
			phone
			fax
			city {
				cityid
				cityName
			}
			state
			zip
			county {
				countid
				countyName
			}
		}
	}
`;
