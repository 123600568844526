

const baseState = {
	page: 0
}


export const tableReducer = (state = baseState, action) => {
	switch(action.type) {
		case 'table/set-page': {
			return {...state, page: action.payload}
		}

		case 'table/reset': {
			return {...state, page: 0}
		}
		default: {
			return state;
		}
	}
}
