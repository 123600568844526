export const getFilter = () => ({
	type: 'contact-filter/get'
});

export const setFilter = (filter = {}) => ({
	type: 'contact-filter/set',
	payload: filter
});

export const clearFilter = () => ({
	type: 'contact-filter/clear'
});

export const setContactLimit = (limit = 50) => ({
	type: 'contact-filter/set-limit',
	payload: limit
})
