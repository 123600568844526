import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import caltheme from './cal-them.css';
import { useTheme } from '../../../styles/ThemeProvider';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import listViewPlugin from '@fullcalendar/list';
import { baseSettings, foregroundColor } from '../../../styles/theme';
import { mediaQueries } from '../../../styles/media';
import propTypes from 'prop-types';
import { useSearchActivities } from '../../../hooks/activities/useSearchActivities';
import { useIsMobile } from '../../../hooks/design/useIsMobile';

const CalendarContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.small};
	height: 100vh;
	width: 90%;
`;

const eventMapper = (activities = []) => {
	return activities.map(({ actid, type, note, date, duration }) => ({
		id: actid,
		title: `${type.type}-${note.substring(0, 15)}`,
		start: dayjs(date).toDate(),
		end: dayjs(date).add(duration, 'minute').toDate(),
		textColor: foregroundColor,
		url: `/activities/${actid}`,
		className: [ 'themedCalLink' ]
	}));
};

const Calendar = styled(FullCalendar)`
	${mediaQueries('md')`
		height: 100%;
	`};


`;

function MainActivityCalendar({ height = '100%', aspectRatio = 1.35, events = [] }) {
	const { activities } = useSearchActivities(eventMapper);
	const isMobile = useIsMobile();
	const history = useHistory();
	const { theme } = useTheme();

	const CalendarComponent = () => {
		if (isMobile) {
			return (
				<Calendar
					plugins={[ listViewPlugin ]}
					st
					events={activities}
					initialView="listWeek"
					aspectRatio={0.5}
					eventClick={(info) => {
						info.jsEvent.preventDefault();
						if (info.event.url) {
							history.push(info.event.url);
						}
					}}
					dayCellClassNames={(args) => {
						const classNames = [ theme === 'dark' ? 'tdDark' : 'tdLight' ];
						if (args.isToday) {
							classNames.push(theme === 'dark' ? 'tdTodayDark' : 'tdTodayLight');
						}

						return classNames;
					}}
				/>
			);
		}
		return (
			<Calendar
				plugins={[ dayGridPlugin ]}
				st
				events={activities}
				initialView="dayGridMonth"
				aspectRatio={aspectRatio}
				eventClick={(info) => {
					info.jsEvent.preventDefault();
					if (info.event.url) {
						history.push(info.event.url);
					}
				}}
				dayCellClassNames={(args) => {
					const classNames = [ theme === 'dark' ? 'tdDark' : 'tdLight' ];
					if (args.isToday) {
						classNames.push(theme === 'dark' ? 'tdTodayDark' : 'tdTodayLight');
					}

					return classNames;
				}}
			/>
		);
	};

	return (
		<CalendarContainer>
			<CalendarComponent />
		</CalendarContainer>
	);
}

MainActivityCalendar.propTypes = {
	aspectRatio: propTypes.number,
	events: propTypes.array
};

export default MainActivityCalendar;
