// defaults to mount airy
const defaultLocation = {
	latitude: 37.7577,
	longitude: -122.4376,
};

const defaultControlState = {
	type: "user", // or address
	address: "",
	radius: 5, // radius in miles
	geolocate: true,
};

const defaultViewPort = {
	width: "100%",
	height: "100%",
	latitude: 37.7577,
	longitude: -122.4376,
	zoom: 6,
};

const defaultState = {
	location: defaultLocation,
	viewport: defaultViewPort,
	controlState: defaultControlState,
};

export const radarReducer = (state = defaultState, action) => {
	switch (action.type) {
		case "radar/clear-viewport":
			return {
				...state,
				viewport: defaultViewPort,
			};
		case "radar/set-viewport":
			return {
				...state,
				viewport: {
					...action.payload,
				},
			};
		case "radar/clear-location":
			return {
				...state,
				location: defaultLocation,
			};
		case "radar/set-location":
			const { latitude, longitude } = action.payload;
			return {
				...state,
				location: {
					latitude,
					longitude,
				},
			};
		case "radar/set-control-state": {
			return {
				...state,
				controlState: action.payload,
			};
		}

		case "radar/toggle-geolocate": {
			return {
				...state,
				controlState: {
					...state.controlState,
					geolocate: !state.controlState.geolocate,
				},
			};
		}

		case "radar/clear-control-state": {
			return {
				...state,
				controlState: defaultControlState,
			};
		}

		default:
			return state;
	}
};
