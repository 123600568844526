import React from 'react';
import styled from 'styled-components';
import { baseSettings, foregroundColor, secondaryAccentColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media';

const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 45%;
	${mediaQueries('md')`
        width: 100%;
    `};
`;

const HeaderText = styled.h2`
	margin: 0;
	color: ${foregroundColor};
	font-size: ${baseSettings.fontLarge};
	font-weight: 600;
`;

const Description = styled.p`
	color: ${foregroundColor};
	font-weight: 300;
	font-size: ${baseSettings.fontLarge};
	text-align: left;
`;

const WarningText = styled.h3`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontMedium};
	${mediaQueries('md')`
        text-align: center;
    `};
`;

function ConfirmationPage({ company }) {
	return (
		<ColumnContainer>
			<HeaderText>Confirm New Customer</HeaderText>
			<Description>
				By hitting "submit" you are confirming that you wish to convert {company.companyname} in to a AES
				customer. By doing so an AES customer ID will be generated and sent to you and the customer service
				team.
			</Description>
			<WarningText>
				Only click submit if the customer is sending in work. Customer Service will send the primary contact a
				NCI packet.
			</WarningText>
		</ColumnContainer>
	);
}

export default ConfirmationPage;
