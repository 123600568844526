import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { ADD_CONTACT_LINK } from '../../api/contacts/addContactLink';
import { REMOVE_CONTACT_LINK } from '../../api/contacts/removeContactLink';
import { setContactLinks } from '../../redux/actions/contact';

export function useContactLink({ contid }) {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const dispatch = useDispatch();

	const [ add, { loading: addLoading, error: addError, data: addData } ] = useMutation(ADD_CONTACT_LINK);
	const [ remove, { loading: removeLoading, error: removeError, data: removeData } ] = useMutation(
		REMOVE_CONTACT_LINK
	);

	useEffect(
		() => {
			setLoading(addLoading);
		},
		[ addLoading ]
	);

	useEffect(
		() => {
			setLoading(removeLoading);
		},
		[ removeLoading ]
	);

	useEffect(
		() => {
			setError(addError);
		},
		[ addError ]
	);
	useEffect(
		() => {
			setError(removeError);
		},
		[ removeError ]
	);

	useEffect(
		() => {
			if (addData && addData.addContactLink) {
				dispatch(setContactLinks(addData.addContactLink));
			}
		},
		[ addData ]
	);

	useEffect(
		() => {
			if (removeData && removeData.removeContactLink) {
				dispatch(setContactLinks(removeData.removeContactLink));
			}
		},
		[ removeData ]
	);

	/**
     * Will add new link and update contact state with new array including link
     */
	const addNewLink = ({ site, url }) => {
		add({
			variables: {
				contid,
				site,
				url
			}
		});
	};

	/**
     * Will remove link and update the contact state with the new link array
     */
	const removeLink = ({ linkid }) => {
		remove({
			variables: {
				linkid
			}
		});
	};

	return { loading, error, addNewLink, removeLink };
}
