import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { CUSTOMER_METRICS } from '../../api/metrics/customerMetrics';

const salesYearFormat = (minusAmount = 0) => `${dayjs().subtract(minusAmount, 'year').format('YYYY')} Sales`;

export function useCustomerMetrics() {
	const { loading, error, data } = useQuery(CUSTOMER_METRICS);
	const [ metrics, setMetrics ] = useState([]);

	useEffect(
		() => {
			if (data && data.customerMetrics) {
				setMetrics(data.customerMetrics);
			}
		},
		[ data ]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'ID#',
				accessor: 'id_'
			},
			{
				Header: 'customerName',
				accessor: 'customerName'
			},
			{
				Header: 'lastShipped',
				accessor: (value) => (value && value.lastShipped ? dayjs(value.lastShipped).format('MM-DD-YY') : '')
			},
			{
				Header: salesYearFormat(4),
				accessor: 'year1Quote'
			},
			{
				Header: salesYearFormat(3),
				accessor: 'year2Quote'
			},
			{
				Header: salesYearFormat(2),
				accessor: 'year3Quote'
			},
			{
				Header: salesYearFormat(1),
				accessor: 'year4Quote'
			},
			{
				Header: salesYearFormat(),
				accessor: 'year5Quote'
			}
		],
		[]
	);

	return { loading, error, columns, metrics };
}
