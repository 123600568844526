import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { REASSIGN_CONTACT } from '../../api/contacts/reassignContact';
import { setContact } from '../../redux/actions/contact';
export function useContactReassign() {
	const [ reAssign, { loading, error, data } ] = useMutation(REASSIGN_CONTACT);
	const [ contact, setContactState ] = useState();
	const dispatch = useDispatch();

	useEffect(
		() => {
			if (data && data.changeContactAssignment) {
				dispatch(setContact(data.changeContactAssignment));
				setContactState(data.changeContactAssignment);
			}
		},
		[ data ]
	);

	const assignContact = ({ compid = 0, locid = 0, contid = 0 }) => {
		reAssign({
			variables: {
				compid,
				locid,
				contid
			}
		});
	};

	return { loading, error, assignContact, contact };
}
