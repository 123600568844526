export function contactReducer(state = {}, action) {
	switch (action.type) {
		case 'contact/set': {
			state = { ...action.payload };
			return state;
		}
		case 'contact/set-links': {
			return {
				...state,
				links: action.payload
			};
		}
		case 'contact/set-notes': {
			return {
				...state,
				notes: action.payload
			};
		}
		default:
			return state;
	}
}
