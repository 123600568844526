import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useDataState } from '../useDataState';
import { ADD_OR_UPDATE_NOTE } from '../../api/contactNotes/addOrUpdateNote';
import { REMOVE_CONTACT_NOTE } from '../../api/contactNotes/remoteNote';
import { setContactNotes } from '../../redux/actions/contact';

export function useContactNotes({ contid }) {
	const dispatch = useDispatch();
	const { loading, error, setLoading, setError } = useDataState({ defaultDataState: [] });
	const [ remove, { loading: removeLoading, error: removeError, data: removeData } ] = useMutation(
		REMOVE_CONTACT_NOTE
	);
	const [ add, { loading: addLoading, error: addError, data: addData } ] = useMutation(ADD_OR_UPDATE_NOTE);

	useEffect(() => setLoading(addLoading), [ addLoading ]);
	useEffect(() => setLoading(removeLoading), [ removeLoading ]);
	useEffect(() => setError(addError), [ addError ]);
	useEffect(() => setError(removeError), [ removeError ]);

	useEffect(
		() => {
			if (addData && addData.addOrUpdateContactNote) {
				dispatch(setContactNotes(addData.addOrUpdateContactNote));
			}
		},
		[ addData ]
	);

	useEffect(
		() => {
			if (removeData && removeData.removeContactNote) {
				dispatch(setContactNotes(removeData.removeContactNote));
			}
		},
		[ removeData ]
	);

	/**
     * Will update or add a note, if add omit cnoteid
     * props: cnoteid, note, typeid
     * Will update contact note state following operation
     */
	const addOrUpdateNote = (noteArgs) => {
		add({
			variables: {
				noteArgs: {
					...noteArgs,
					contid
				}
			}
		});
	};

	/**
     * Will remove the provided note, and update contact state
     * @cnoteid {*} The id of the note to remove 
     */
	const removeNote = ({ cnoteid }) => {
		remove({
			variables: {
				cnoteid
			}
		});
	};

	return { loading, error, addOrUpdateNote, removeNote };
}
