import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import SelectField from './SelectField';
import { baseSettings, foregroundColor, primaryLighterColor, secondaryAccentColor } from '../../styles/theme';
import { useCompanyNotes } from "../../hooks/companyNotes/useCompanyNotes"
import ThemeButton from './ThemeButton';
import SizedBox from './SizedBox';
import { useNoteTypes } from '../../hooks/noteTypes/useNoteTypes';
import { useSelector } from 'react-redux';

const NoteColumn = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: ${baseSettings.small};
	padding-top: 0px;
	padding-bottom: 0px;
	height: 450px;
`;

const NoteArea = styled.textarea`
	border: 1px solid ${foregroundColor};
	background: none;
	border-radius: 5px;
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	padding: ${baseSettings.xs};
	height: 65%;
	width: 100%;
`;

const ErrorMessage = styled.div`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
	text-align: center;
	margin: ${baseSettings.xs};

`

function NoteForm({locid, selectedNote = {type: null, note: "", cnoteid: 0}}) {

	const { register, handleSubmit, control, setValue } = useForm();
    const { loading: typeLoading, types, error: typeError } = useNoteTypes();
    const { loading: noteLoading, error: noteError, addNote, removeNote } = useCompanyNotes(locid)


    const submitNote = ({note, typeid}) => {
        addNote({
            typeid,
            note,
            cnoteid: selectedNote.cnoteid,
            locid
		})
		
	}
	


	useEffect(() => {
		Object.keys(selectedNote).forEach(key => setValue(key, selectedNote[key]))
	}, [selectedNote])


	return (
		<NoteColumn onSubmit={handleSubmit(submitNote)}>
			<NoteArea name={'note'} ref={register({ required: true, maxLength: 350 })} placeholder={'Type your note here'} />
			<Controller
				name="typeid"
				rules={{required: true}}
				control={control}
				render={(props) => {
					const selctedValue = types.find(({ typeid }) => typeid === props.value);


					return (
						<SelectField
							text={'Note Type'}
							
							loading={typeLoading}
							onChange={({ value }) => props.onChange(value)}
							value={{
								value: selctedValue?.typeid,
								label: selctedValue?.type
							}}
							options={types.map(({ typeid, type }) => ({ value: typeid, label: type }))}
						/>
					);
				}}
				loading={typeLoading}
			/>

			<SizedBox bottom={baseSettings.small} />
			{selectedNote.cnoteid !== 0 && <ThemeButton variant={'secondary'} type={'button'} scaleEffect={false} onClick={() => removeNote(selectedNote.cnoteid)} >
							Remove Note
			</ThemeButton>}
			<SizedBox bottom={baseSettings.small} />
			{noteError && <ErrorMessage>{noteError.message}</ErrorMessage>}
			<ThemeButton type={'submit'} scaleEffect={false}>
				Save Note
			</ThemeButton>
		</NoteColumn>
	);
}

export default NoteForm;
