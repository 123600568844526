export const setContact = (contact) => ({
	type: 'contact/set',
	payload: contact
});

export const setContactLinks = (links = []) => ({
	type: 'contact/set-links',
	payload: links
});

export const setContactNotes = (notes = []) => ({
	type: 'contact/set-notes',
	payload: notes
});

export const getContact = () => ({
	type: 'contact/get'
});
