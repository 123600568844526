import { decode } from 'jsonwebtoken';

import { store } from '../../index';
import { setUser, clearUser } from '../../redux/actions/user';

const tokenKey = 'aestoken';

export const setUserToken = (token) => {
	if (!token || typeof token !== 'string') {
		throw new Error('token must be string');
	}

	//validate token
	const user = decode(token);

	localStorage.setItem(tokenKey, token);

	//call action to clear user in reducer
	return store.dispatch(setUser(user));
};


export const getUserToken = () => localStorage.getItem(tokenKey);

export const clearUserToken = () => {
	localStorage.removeItem(tokenKey);
	//call action to remove user in reducer
	return store.dispatch(clearUser());
};

// checks if token is in storage, if so set user state
export const initUser = () => {
	const token = localStorage.getItem(tokenKey);
	if (token) {
		setUserToken(token);
	}


};



