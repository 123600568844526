import { gql } from '@apollo/client';

export const GET_NOTE_TYPES = gql`
	query {
		noteTypes {
			typeid
			type
		}
	}
`;
