import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Wave } from 'better-react-spinkit';
import styled from 'styled-components';
import theme from 'styled-theming';
import { mediaQueries } from '../../styles/media';
import { BiLeftArrow } from 'react-icons/bi';
import {
	foregroundColor,
	baseSettings,
	secondaryAccentColor,
	lightforegroundColor,
	accentColor
} from '../../styles/theme';
import Dots from 'react-carousel-dots';

const Container = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	padding: ${baseSettings.xs};
	height: 100%;
	width: 100%;
`;

const ChildContainer = styled.div`
	display: flex;
	flex: 6;
	width: 100%;
`;

const ButtonRow = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	flex: 1;
	width: 80%;
	${mediaQueries('md')`
		width: 100%;
	`};
`;

const ButtonContainer = styled.div`
	display: flex;
	padding: ${baseSettings.xs};
`;

const LeftArrow = styled(BiLeftArrow)`
    cursor: ${(props) => (props.isActive ? 'pointer' : 'not-allowed')};
    color: ${(props) => (props.isActive ? 'white' : lightforegroundColor)};
    font-size: 3.5em;

`;

const PageDots = styled(Dots)`
    justify-self: center;
`;

const ErrorMessage = styled.h3`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
	font-weight: 600;
	margin: 0;
`;

const ButtonContents = styled.div`display: flex;`;

const NextButton = styled.div`
	align-items: center;
	background: ${(props) => (props.loading ? secondaryAccentColor : foregroundColor)};
	border-radius: 5px;
	display: flex;
	color: ${theme('mode', {
		light: 'white',
		dark: 'black'
	})};
	cursor: pointer;
	font-size: ${baseSettings.fontMedium};
	font-weight: 600;
	justify-content: center;
	padding: ${baseSettings.xs};
	width: 15vw;
	height: 5vh;
	&:hover {
		background: ${theme('mode', {
			light: accentColor,
			dark: secondaryAccentColor
		})};
		color: ${theme('mode', {
			light: 'white',
			dark: 'white'
		})};
	}
	${mediaQueries('md')`
	width: 25vw;
	`};
`;

const defaultPages = [
	{
		validate: () => true,
		component: <div>add some pages!</div>
	}
];

function MultiPageContainer({
	pages = defaultPages,
	handleSubmit,
	initialPage = 0,
	loading = false,
	errorMessage = ''
}) {
	const [ pageIndex, setPageIndex ] = useState(initialPage);

	const handleClick = () => {
		if (pageIndex === pages.length - 1 && pages[pageIndex].validate()) {
			handleSubmit();
		} else if (pages[pageIndex].validate()) {
			setPageIndex(pageIndex + 1);
		}
	};

	const handleBack = () => {
		if (pageIndex > 0) {
			setPageIndex(pageIndex - 1);
		}
	};

	const Contents = () => {
		if (loading) {
			return <Wave color="white" size={25} />;
		}

		return pageIndex === pages.length - 1 ? 'Submit' : 'Next';
	};

	return (
		<Container>
			<ChildContainer>{pages[pageIndex].component}</ChildContainer>
			{errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
			<ButtonRow>
				<LeftArrow isActive={pageIndex > 0} onClick={handleBack} />

				<PageDots length={pages.length} active={pageIndex} />
				<ButtonContainer>
					<NextButton onClick={handleClick} loading={loading}>
						<Contents />
					</NextButton>
				</ButtonContainer>
			</ButtonRow>
		</Container>
	);
}

MultiPageContainer.propTypes = {
	pages: propTypes.arrayOf(propTypes.object),
	loading: propTypes.bool,
	handleSubmit: propTypes.func
};

export default MultiPageContainer;
