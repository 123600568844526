import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useNoteTypes } from '../../hooks/noteTypes/useNoteTypes';
import { baseSettings, foregroundColor } from '../../styles/theme';
import { mediaQueries } from '../../styles/media'
import SelectField from '../common/SelectField'
import ThemeButton from '../common/ThemeButton'
import SizedBox  from '../common/SizedBox'

const Form = styled.form`
	display: flex;
	flex-direction: column;
	${mediaQueries('md')`
		margin-top: ${baseSettings.small};
	`};
`;

const NoteArea = styled.textarea`
	border: 1px solid ${foregroundColor};
	background: none;
	border-radius: 5px;
	color: ${foregroundColor};
	font-size: ${baseSettings.fontMedium};
	padding: ${baseSettings.xs};
	height: 35%;
	width: 350px;
	${mediaQueries('md')`
		height: 250px;
		width: 100%;
	`};
`;


const Button = styled(ThemeButton)`
	margin: ${baseSettings.small} 0;

`


function ContactNoteForm({ note, onSubmit, onDelete }) {
	const { register, handleSubmit, control, setValue } = useForm({ defaultValues: { ...note } });
	const { loading, error, types } = useNoteTypes();

	useEffect(() => {
		Object.keys(note).forEach(key => {
			if(key === 'type.typeid') {
				setValue('typeid', note[key])
			}
			else
				setValue(key, note[key])
		})
	}, [note])

	const submit = (values) => {
		onSubmit({...values, cnoteid: note.cnoteid || 0})
	}

	return (
		<Form onSubmit={handleSubmit(submit)}>

			<NoteArea ref={register} name="note" />
            <Controller
				name="typeid"
				rules={{required: true}}
				control={control}
				render={(props) => {
					const selctedValue = types.find(({ typeid }) => typeid === props.value);
					return (
						<SelectField
							text={'Note Type'}
							loading={loading}
							onChange={({ value }) => props.onChange(value)}
							value={{
								value: selctedValue?.typeid,
								label: selctedValue?.type
							}}
							options={types.map(({ typeid, type }) => ({ value: typeid, label: type }))}
						/>
					);
				}}
				loading={loading}
			/>
			<SizedBox bottom={baseSettings.xs} />
			<Button variant={'secondary'} scaleEffect={false} onClick={() => onDelete(note)}>Remove</Button>
			<SizedBox bottom={baseSettings.xs} />

			<Button scaleEffect={false} type="submit" fontWeight={'500'}>Save Changes</Button>

		</Form>
	);
}

export default ContactNoteForm;
