import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { useIndustries } from '../../hooks/industries/useIndustries';

import Select from '../common/SelectField';

export function NoControllerIndustrySelect({ onChange, value }) {
	const { industires, loading, filterName } = useIndustries({ prefill: true });

	const options = useMemo(
		() => industires.map(({ industryid, industryName }) => ({ label: industryName, value: industryid })),
		[ industires ]
	);

	const onInputChange = (value) => {
		if (value) {
			filterName(value);
		}
	};

	return (
		<Select
			text={'Industry'}
			options={options}
			onInputChange={onInputChange}
			onChange={onChange}
			noOptionsMessage={'No industries found'}
			value={options.find((o) => o.value === value)}
			loading={loading}
		/>
	);
}

function IndustrySelect({ control = null }) {
	const { industires, loading, error, filterName } = useIndustries({ prefill: true });

	const onInputChange = (value) => {
		if (value) {
			filterName(value);
		}
	};

	const options = industires.map(({ industryid, industryName }) => ({ label: industryName, value: industryid }));

	return (
		<Controller
			control={control}
			name={'industry'}
			render={(props) => {
				const option = options.find(({ value }) => value === props.value) || { value: '', label: '' };

				return (
					<Select
						text={'Industry'}
						onInputChange={onInputChange}
						options={options}
						onChange={({ value }) => props.onChange(value)}
						noOptionsMessage={'No industries found'}
						value={{ label: `${option.label}`, value: `${option.value}` }}
						loading={loading}
					/>
				);
			}}
		/>
	);
}

export default IndustrySelect;
