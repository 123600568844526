import React from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import { useCities } from '../../hooks/cities/useCities';

import Select from './SelectField';
import CretableSelect from './CretableSelect';

function CitySelect({ control = null, creatable = false }) {
	const { cities, loading, error } = useCities();

	const onInputChange = (value) => {};

	let options = cities.map(({ cityid, cityName }) => ({ label: cityName, value: cityid }));

	return (
		<Controller
			control={control}
			name={'city'}
			render={(props) => {
				const option = options.find(option => option.value === (props.value?.value | props.value)) || {
					value: '',
					label: ''
				};

			

				return creatable ? (
					<CretableSelect
						text={'City Name'}
						onInputChange={onInputChange}
						onChange={({ value, label }) => {
							let chosenOption = { value, label };
							if (!options.find((item) => item.value === value)) {
								// add option, this is cretable
								options = options.filter((item) => item.value !== 0);
								options.push({
									value: 0,
									label: value
								});
								chosenOption.value = 0;
							}
							props.onChange(chosenOption);
						}}
						options={options}
						noOptionsMessage={'No Cities Found'}
						loading={loading}
						value={{ label: `${option.label}`, value: `${option.value}` }}
					/>
				) : (
					<Select
						text={'City Name'}
						onInputChange={onInputChange}
						onChange={({ value, label }) => {
							props.onChange({ value, label });
						}}
						options={options}
						noOptionsMessage={'No Cities Found'}
						loading={loading}
						value={{ label: `${option.label}`, value: `${option.value}` }}
					/>
				);
			}}
		/>
	);
}

export default CitySelect;
