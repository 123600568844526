const defaultState = { notes: [] };

export function locationReducer(state = defaultState, action) {
	switch (action.type) {
		case 'location/set': {
			return {
				...action.payload
			};
		}
		case 'location/initial': {
			if (action.payload.length > 0) {
				return action.payload[0];
			}
			return defaultState;
		}

		case 'location/note-set': {
			return {
				...state,
				notes: action.payload
			};
		}

		case 'location/clear': {
			return defaultState;
		}

		default:
			return state;
	}
}
