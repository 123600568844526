import React from 'react';

import Select from '../common/SelectField';

const typeValues = [
	{ value: 1, label: 'End User' },
	{ value: 2, label: 'Distributor' },
	{ value: 3, label: 'Government' },
	{ value: 4, label: 'OEM' },
	{ value: 5, label: 'Repair' }
];

function CompanyTypeSelect({ value, onChange, text = 'Type' }) {
	let resolveValue = null;
	if (typeof value === 'number') {
		resolveValue = typeValues.find((v) => v.value === value);
	} else {
		resolveValue = value;
	}
	return <Select onChange={onChange} options={typeValues} value={resolveValue} text={text} />;
}

export default CompanyTypeSelect;
