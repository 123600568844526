import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useDataState } from '../useDataState';
import { GET_ACTIVITY } from '../../api/activities/getActivity';
import { UPDATE_ACTIVITY } from '../../api/activities/updateActivity';
import { REMOVE_ACTIVITY } from '../../api/activities/removeActvity';

const isNumber = (value) => {
	if (value === undefined || isNaN(value)) {
		throw new Error('invalid activity Id');
	}
	return parseInt(value);
};

export function useActivity(actid) {
	const { loading: getLoading, error: getError, data: getData } = useQuery(GET_ACTIVITY, {
		variables: { actid: isNumber(actid) }
	});
	const history = useHistory();
	const [ update, { loading: updateLoading, error: updateError, data: updateData } ] = useMutation(UPDATE_ACTIVITY);
	const [ remove, { loading: removeLoading, error: removeError, data: removeData } ] = useMutation(REMOVE_ACTIVITY);
	const { setData: setActivity, loading, data: activity, error, setError, setLoading } = useDataState({
		defaultDataState: null
	});

	useEffect(
		() => {
			if (getData && getData.activity) {
				setActivity(getData.activity);
			}
		},
		[ getData ]
	);

	useEffect(
		() => {
			setLoading(getLoading);
		},
		[ getLoading ]
	);

	useEffect(
		() => {
			setError(getError);
		},
		[ getError ]
	);

	useEffect(
		() => {
			setLoading(updateLoading);
		},
		[ updateLoading ]
	);

	useEffect(
		() => {
			setError(updateError);
		},
		[ updateError ]
	);

	useEffect(
		() => {
			if (updateData && updateData.updateActivity) {
				setActivity(updateData.updateActivity);
			}
		},
		[ updateData ]
	);

	useEffect(
		() => {
			setLoading(removeLoading);
		},
		[ removeLoading ]
	);

	useEffect(
		() => {
			setError(removeError);
		},
		[ removeError ]
	);

	useEffect(
		() => {
			if (removeData && removeData.removeActivity) {
				// push to activity detail page since it was removed
				history.push('/activities');
			}
		},
		[ removeData ]
	);

	const updateActivity = ({ contid, date, participants, typeid, note, duration }) => {
		update({
			variables: {
				actid: parseInt(actid),
				updateData: {
					contid,
					date,
					participants,
					typeid,
					note,
					duration
				}
			}
		});
	};

	const removeActivity = ({ actid }) => {
		remove({ variables: { actid } });
	};

	return { activity, loading, error, updateActivity, removeActivity };
}
