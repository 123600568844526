import { gql } from '@apollo/client';

export const SEARCH_AES_COMPANIES = gql`
	query($id: Int, $customerName: String) {
		searchSystemCustomers(id: $id, customerName: $customerName) {
			id
			customerName
			salesPerson
			csRep
			programManager
			industry
			lastShipped
			mainLocation {
				id
				shipToCity
				shipToState
			}
		}
	}
`;
