import { gql } from '@apollo/client';

export const REMOVE_CONTACT_NOTE = gql`
	mutation($cnoteid: Int!) {
		removeContactNote(cnoteid: $cnoteid) {
			cnoteid
			createdDate
			note
			type {
				typeid
				type
			}
		}
	}
`;
