import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import GenericTable from '../common/genericTable';
import SizedBox from '../common/SizedBox';
import IndustrySelect from './industrySelect';
import { baseSettings } from '../../styles/theme';
import ThemeButton from '../common/ThemeButton';
import { useIndustries } from '../../hooks/industries/useIndustries';
import { secondaryAccentColor, foregroundColor } from '../../styles/theme';

// column to hard two rows
const AssignementContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

// row to hold two columns for assignment
const AssignementRow = styled.div`display: flex;`;

const TableContainer = styled.div`
	overflow-y: scroll;
	max-height: 375px;
`;

const AssignedTable = styled(GenericTable)`
    max-height: 375px;
    overflow-y: scroll;
    width: 50vw;
`;

const Header = styled.h4`
	color: ${secondaryAccentColor};
	font-size: ${baseSettings.fontSmall};
`;

const NoValueHolder = styled.h5`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontSmall};
	text-align: center;
`;

const AddIndustryButton = styled(ThemeButton)`



`;

function IndustryAssignment() {
	const company = useSelector((state) => state.company);
	const [ selectedIndustry, setSelectedIndustry ] = useState(null);
	const assignedIndustries = company.industry || [];

	const { loading, error, industires, assignIndustry, removeIndustryAssignment } = useIndustries();
	const { control, handleSubmit } = useForm();

	const assignedColumns = useMemo(
		() => [
			{
				Header: 'industryid',
				accessor: 'industryid'
			},
			{
				Header: 'NAICS#',
				accessor: 'naics'
			},
			{
				Header: 'Industry',
				accessor: 'industryName'
			}
		],
		[]
	);

	const addAssignment = ({ industry }) => {
		if (industry) {
			// assign to company here
			assignIndustry({ compid: company.compid, industryid: industry });
		}
	};

	const onAssignedIndustrySelect = (industry) => {
		setSelectedIndustry(industry);
	};

	return (
		<AssignementContainer>
			<Header>Assigned Industries</Header>
			<AssignementRow>
				{assignedIndustries.length > 0 ? (
					<TableContainer>
						{' '}
						<AssignedTable
							columns={assignedColumns}
							data={assignedIndustries}
							hiddenColumns={[ 'industryid' ]}
							width={'50vw'}
							onSelect={onAssignedIndustrySelect}
						/>
						{selectedIndustry && (
							<ThemeButton
								variant={'secondary'}
								scaleEffect={false}
								onClick={() => {
									setSelectedIndustry(null);
									removeIndustryAssignment({
										compid: company.compid,
										industryid: selectedIndustry.industryid
									});
								}}
							>
								Remove {selectedIndustry.naics} Assignment?
							</ThemeButton>
						)}
					</TableContainer>
				) : (
					<NoValueHolder>No industries assigned to this company.. Why not assign some? 👍</NoValueHolder>
				)}
			</AssignementRow>

			<Header>Assign Industries</Header>
			<form onSubmit={handleSubmit(addAssignment)}>
				<IndustrySelect control={control} />
				<SizedBox bottom={baseSettings.small} />
				<AddIndustryButton type={'submit'}>Add Assignment</AddIndustryButton>
				{error && <NoValueHolder>😫Error: {error.message}</NoValueHolder>}
			</form>
		</AssignementContainer>
	);
}

export default IndustryAssignment;
