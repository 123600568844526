import React, { useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { baseSettings, foregroundColor, lightforegroundColor } from "../../styles/theme";
import { mediaQueries } from "../../styles/media";
import TypeSelect from "../common/ActivityTypeSelect";
import DateTimePicker from "../common/DateTimePicker";
import { setActivityProps } from "../../redux/actions/newActivity";
import DurationSelect from "./DurationSelect";
import SizedBox from "../common/SizedBox";

const CompanyDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 75vw;
`;

const Header = styled.h2`
	color: ${foregroundColor};
	font-size: ${baseSettings.fontXL};
	font-weight: 600;
`;

const ToolTip = styled.h4`
	color: ${lightforegroundColor};
	font-size: ${baseSettings.fontMedium};
	font-weight: 300;
`;

const OptionContainer = styled.div`
	width: 25%;
	${mediaQueries("md")`
        width: 100%;
    `};
`;

const SyncCheckBox = styled.input``;

function CompanyPage() {
	const dispatch = useDispatch();
	const { typeid, date, duration, sync } = useSelector((state) => state.newActivity);

	return (
		<CompanyDiv>
			<Header>Schedule/Log Activity</Header>
			<ToolTip>Please be sure to complete all steps to add your activity</ToolTip>
			<OptionContainer>
				<TypeSelect
					selectionChanged={(value) => dispatch(setActivityProps({ typeid: value }))}
					selectedValue={typeid}
					width={"60%"}
				/>
			</OptionContainer>
			<SizedBox bottom={baseSettings.medium} />
			<OptionContainer>
				<DateTimePicker header="Date" setDate={(value) => dispatch(setActivityProps({ date: value }))} />
			</OptionContainer>
			<SizedBox bottom={baseSettings.medium} />

			<OptionContainer>
				<DurationSelect onChange={(value) => dispatch(setActivityProps({ duration: value }))} duration={duration} />
			</OptionContainer>
			<OptionContainer>
				<ToolTip>Sync with your outlook calendar?</ToolTip>
				<SyncCheckBox
					type="checkbox"
					onChange={(e) => dispatch(setActivityProps({ sync: e.target.checked }))}
					checked={sync}
				/>
			</OptionContainer>
		</CompanyDiv>
	);
}

export default CompanyPage;
